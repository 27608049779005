import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import AdminLayout from "../../layouts/AdminLayout";
import { API_BASE_URL, fetchData } from "../../api/api";
import { AuthContext } from "../../context/AuthContext";
import Spinner from "../../components/common/Spinner";


const AdminLeadsPage = () => {

	const { user } = useContext(AuthContext);

	const didFetch = useRef(false);
	const [isLoading, setIsLoading] = useState(false);

	const [currentPage, setCurrentPage] = useState(1);

	const [practicesList, setPracticesList] = useState([]);

	const getPractices = async () => {

		setIsLoading(true);
		try {
			const data = await fetchData('/get-practices', { fk_users_id: user?.users_id });
			setPracticesList(data);
		} catch (error) {
			console.error("Error fetching data:", error);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (!didFetch.current) {
			getPractices();
			didFetch.current = true;
		}
	}, []);


	const itemsPerPage = 10;

	const totalPages = Math.ceil(practicesList.length / itemsPerPage);

	const paginatedData = practicesList?.length > 0 ? practicesList.slice(
		(currentPage - 1) * itemsPerPage,
		currentPage * itemsPerPage
	) : [];

	const handlePageChange = (newPage) => {
		if (newPage > 0 && newPage <= totalPages) {
			setCurrentPage(newPage);
		}
	};

	if (isLoading) {
		return (<Spinner adminLayout={true} />);
	}

	return (
		<>
			<AdminLayout>
				<main>
					<div className="px-4 py-4 sm:px-8 sm:py-6 md:px-12 md:py-7 flex justify-between gap-4 items-center">
						<h1 className="font-semibold text-lg sm:text-2xl">Leads Page</h1>
					</div>
					<div className="overflow-x-auto">
						<table className="w-full">
							<thead className="bg-lightblue">
								<tr>
									<th className="py-2 px-1.5 sm:py-4 sm:px-3 text-base text-blue font-semibold text-start min-w-[67px] sm:min-w-[90px] w-[90px]">Photo</th>
									<th className="py-2 px-1.5 sm:py-4 sm:px-3 text-base text-blue font-semibold text-start min-w-48">Practice Name</th>
									<th className="py-2 px-1.5 sm:py-4 sm:px-3 text-base text-blue font-semibold text-start min-w-48">Category</th>
									<th className="py-2 px-1.5 sm:py-4 sm:px-3 text-base text-blue font-semibold text-start min-w-48">Address</th>
									<th className="py-2 px-1.5 sm:py-4 sm:px-3 text-base text-blue font-semibold text-start min-w-[90px] lg:min-w-[150px] ">Leads</th>
								</tr>
							</thead>
							<tbody>
								{paginatedData?.length > 0 ? paginatedData?.map((practice, index) => (
									<tr key={index} className="Practice-table-row">
										<td className="py-2 px-1.5 sm:py-4 sm:px-3 border-t-2 border-solid border-[#dfdfdf]">
											<img src={practice.image ? API_BASE_URL + practice.image : null} alt="" srcSet="" className="h-[50px] w-[50px] sm:h-[70px] sm:w-[70px] rounded" />
										</td>
										<td className="py-2 px-1.5 sm:py-4 sm:px-3 border-t-2 border-solid border-[#dfdfdf]">
											<a href="#" className="text-dark text-base font-medium">{practice?.practice_name}</a>
										</td>
										<td className="py-2 px-1.5 sm:py-4 sm:px-3 border-t-2 border-solid border-[#dfdfdf]">
											<a href="#" className="text-dark text-base font-medium">
												<span className="text-dark text-base font-medium">
													{(() => {
														const serviceNames = practice?.services?.map(service => service.name).join(", ");
														return serviceNames.length > 50 ? serviceNames.slice(0, 50) + "..." : serviceNames;
													})()}
												</span></a>
										</td>
										<td className="py-2 px-1.5 sm:py-4 sm:px-3 border-t-2 border-solid border-[#dfdfdf]">
											<Link
												// to={`https://www.google.com/maps?q=${practice?.suburb?.latitude},${practice?.suburb?.longitude}`} 
												to={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(practice?.address + ', ' + practice?.suburb?.name + ', ' + practice?.state?.name)}`}
												target="_blank" rel="noopener noreferrer"
												className="text-dark text-base font-medium underline underline-offset-2">
												{practice?.address}, {practice?.suburb?.name}, {practice?.state?.name}, {practice?.postcode?.postcode}
											</Link>
										</td>
										<td className="py-2 px-1.5 sm:py-4 sm:px-3 border-t-2 border-solid border-[#dfdfdf]">
											<a href="#" className="text-center text-base font-medium text-dark rounded-full block py-1 px-2 bg-[#FBFB87] max-w-[110px] w-full">0</a>
										</td>
									</tr>
								)) : (<tr className="Practice-table-row"><td colSpan={5} className="text-center py-2 px-1.5 sm:py-4 sm:px-3 border-y-2 border-solid border-[#dfdfdf]">No Practices Found</td></tr>)}
							</tbody>
						</table>
					</div>

					{paginatedData?.length > 0 && (
						<div className="px-3 py-3 border-t-2 border-solid border-[#dfdfdf]">
							<ul className="flex items-center -space-x-px h-10 text-base justify-end">
								{/* Previous Button */}
								<li>
									<button
										onClick={() => handlePageChange(currentPage - 1)}
										disabled={currentPage === 1}
										className={`flex items-center justify-center px-4 h-10 ms-0 leading-tight border border-e-0 rounded-s-lg border-[#73aeff] 
${currentPage === 1 ? "text-gray-400 bg-gray-200 cursor-not-allowed" : "text-blue bg-gray-200 hover:bg-gray-100"}`}
									>
										<span className="sr-only">Previous</span>
										<svg className="w-3 h-3 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
											<path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 1 1 5l4 4"></path>
										</svg>
									</button>
								</li>

								{/* Dynamic Pagination Logic */}
								{Array.from({ length: totalPages }, (_, i) => i + 1)
									.filter((page) => {
										return (
											page === 1 ||
											page === totalPages ||
											(page >= currentPage - 1 && page <= currentPage + 1)
										);
									})
									.reduce((acc, page, index, arr) => {
										if (index > 0 && page !== arr[index - 1] + 1) {
											acc.push("...");
										}
										acc.push(page);
										return acc;
									}, [])
									.map((page, index) => (
										<li key={index}>
											{page === "..." ? (
												<span className={`flex items-center justify-center px-4 h-10 leading-tight border border-[#73aeff] bg-gray-200 text-blue`}>...</span>
											) : (
												<button
													onClick={() => handlePageChange(page)}
													className={`flex items-center justify-center px-4 h-10 leading-tight border border-[#73aeff] 
${currentPage === page ? "bg-white text-blue" : "bg-gray-200 text-blue hover:bg-gray-100"}`}
												>
													{page}
												</button>
											)}
										</li>
									))}

								{/* Next Button */}
								<li>
									<button
										onClick={() => handlePageChange(currentPage + 1)}
										disabled={currentPage === totalPages}
										className={`flex items-center justify-center px-4 h-10 leading-tight border rounded-e-lg border-[#73aeff] 
${currentPage === totalPages ? "text-gray-400 bg-gray-200 cursor-not-allowed" : "text-blue bg-gray-200 hover:bg-gray-100"}`}
									>
										<span className="sr-only">Next</span>
										<svg className="w-3 h-3 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
											<path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4"></path>
										</svg>
									</button>
								</li>
							</ul>
						</div>
					)}
				</main>
			</AdminLayout>
		</>)
}

export default AdminLeadsPage