import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import AdminLayout from "../../layouts/AdminLayout";
import { API_BASE_URL, fetchData } from "../../api/api";
import Select from "react-select";
import { toast } from "react-toastify";
import { CONSTANT_CURRENCY, CONSTANT_REDIRECT_TIME, getGender } from "../../constant/common";
import api from "../../api/api";
import { decryptData } from "../../utils/encryptDecrypt";
import Spinner from "../../components/common/Spinner";
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
import { formatDateForView } from "../../utils/formateDate";
import PractitionerDetailsCard from "../../components/admin/practices/PractitionerDetailsCard";

const images = [
    "/assets/image/physiotherapist-gallery-1.png",
    "/assets/image/physiotherapist-gallery-2.png",
    "/assets/image/physiotherapist-gallery-3.png",
    "/assets/image/physiotherapist-gallery-4.png",
    "/assets/image/physiotherapist-gallery-5.png",
    "/assets/image/physiotherapist-gallery-6.png",
    "/assets/image/physiotherapist-gallery-7.png",
    "/assets/image/physiotherapist-gallery-8.png",
    "/assets/image/physiotherapist-gallery-9.png",
];

const AdminPractitionersDetails = () => {
    const navigate = useNavigate();

    const { practices_id } = useParams();
    const decrypt_practices_id = decryptData(practices_id);

    const didFetch = useRef(false);

    const [isLoading, setIsLoading] = useState(false);

    const [practiceDetails, setPracticeDetails] = useState([]);

    const [practiceactiveTab, setPracticeActiveTab] = useState("overview");

    const getPracticeDetails = async () => {
        setIsLoading(true);
        try {
            const data = await fetchData('/detail-practices', { practices_id: decrypt_practices_id });
            setPracticeDetails(data);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (!didFetch.current) {
            getPracticeDetails();
            didFetch.current = true;
        }
    }, []);

    if (isLoading) {
        return (<Spinner adminLayout={true} />);
    }

    const handleImageChange = async (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];

            const payload = {
                practices_id: practiceDetails?.practices_id,
                image: file,
            };

            try {
                const response = await api.post("update-practices-image", payload, {
                    headers: { "Content-Type": "multipart/form-data" },
                });

                if (response?.data?.status === true) {
                    toast.success(response.data?.message);

                    setTimeout(() => {
                        getPracticeDetails();
                    }, CONSTANT_REDIRECT_TIME);

                } else {
                    toast.error(response?.data?.message || "Failed ");
                    return null;
                }
            } catch (error) {
                console.error("Error :", error);
                toast.error("Something went wrong. Please try again.");
                return null;
            }
        } else {
            console.log("No file selected.");
        }
    };

    return (
        <>
            <AdminLayout>
                <div className="bg-[#EDF4FF]">
                    <section className="py-10 lg:py-[50px]">
                        <div className="container px-3 mx-auto">
                            <h1 className="text-dark text-2xl font-semibold mb-6">Practitioner Details</h1>

                            <PractitionerDetailsCard practitionerDetails={practiceDetails} getPractitionersList={getPracticeDetails} />
                            
                        </div>
                    </section>
                </div>
            </AdminLayout>
        </>)
}

export default AdminPractitionersDetails