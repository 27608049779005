import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import AdminLayout from "../../layouts/AdminLayout";
import api, { API_BASE_URL, fetchData } from "../../api/api";
import Spinner from "../../components/common/Spinner";
import { AuthContext } from "../../context/AuthContext";
import { encryptData } from "../../utils/encryptDecrypt";
import { toast } from "react-toastify";
import { CONSTANT_REDIRECT_TIME } from "../../constant/common";
import MasterAdminLayout from "../../layouts/MasterAdminLayout";


const MasterAdminClientsList = () => {

    const didFetch = useRef(false);
    const [isLoading, setIsLoading] = useState(false);

    const [users, setUsersList] = useState([]);

    const [isDelete, setIsDelete] = useState(false);

    const [selectedUsers, setSelectedUsers] = useState(null);

    const [openIndex, setOpenIndex] = useState(null);

    const [currentPage, setCurrentPage] = useState(1);

    const getUsers = async () => {

        setIsLoading(true);
        try {
            const data = await fetchData('/get-users', {
                fk_roles_id: 2
            });
            setUsersList(data);
            setCurrentPage(1);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (!didFetch.current) {
            getUsers();
            didFetch.current = true;
        }
    }, []);


    const itemsPerPage = 10;

    const totalPages = Math.ceil(users.length / itemsPerPage);

    const paginatedData = users?.length > 0 ? users.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    ) : [];

    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    const handleDelete = async () => {

        const payload = {
            users_id: selectedUsers?.users_id
        };

        try {
            const response = await api.post("delete-users", payload);

            if (response?.data?.status === true) {
                toast.success(response.data?.message);

                setTimeout(() => {
                    getUsers();
                }, CONSTANT_REDIRECT_TIME);


            } else {
                toast.error(response?.data?.message || "Failed to delete.");
                return null;
            }
        } catch (error) {
            console.error("Error delete :", error);
            toast.error("Something went wrong. Please try again.");
            return null;
        }

        setSelectedUsers(null);
        setIsDelete(false);
    }

    if (isLoading) {
        return (<Spinner masterAdminLayout={true} />);
    }


    return (
        <>
            <MasterAdminLayout>
                <main>
                    <div className="px-4 py-4 sm:px-8 sm:py-6 md:px-12 md:py-7 flex justify-between gap-4 items-center">
                        <h1 className="font-semibold text-lg sm:text-2xl">Clients</h1>
                    </div>

                    <div className="overflow-x-auto">
                        <table className="w-full">
                            <thead className="bg-lightblue">
                                <tr>
                                    <th className="py-2 px-1.5 sm:py-4 sm:px-3 text-base text-blue font-semibold text-center w-[180px]">Number</th>
                                    <th className="py-2 px-1.5 sm:py-4 sm:px-3 text-base text-blue font-semibold text-start w-[300px]">Practice Name</th>
                                    <th className="py-2 px-1.5 sm:py-4 sm:px-3 text-base text-blue font-semibold text-start w-[300px]">First Name</th>
                                    <th className="py-2 px-1.5 sm:py-4 sm:px-3 text-base text-blue font-semibold text-start w-[300px]">Last Name</th>
                                    <th className="py-2 px-1.5 sm:py-4 sm:px-3 text-base text-blue font-semibold text-start w-[300px]">Email</th>
                                    <th className="py-2 px-1.5 sm:py-4 sm:px-3 text-base text-blue font-semibold text-start w-[300px]">Links</th>
                                    <th className="py-2 px-1.5 sm:py-4 sm:px-3 text-base text-blue font-semibold text-start w-[300px]">Billing Plan</th>
                                    <th className="py-2 px-1.5 sm:py-4 sm:px-3 text-base text-blue font-semibold text-start w-[200px]">Action</th>
                                </tr>
                            </thead>
                            <tbody>

                                {paginatedData?.length > 0 ? paginatedData?.map((item, index) => (
                                    <tr
                                        key={index}
                                        className="border-b-2 border-solid border-[#dfdfdf]">
                                        <td className="py-2 px-1.5 sm:py-4 sm:px-3 text-center">
                                            {((currentPage - 1) * itemsPerPage) + index + 1}
                                        </td>
                                        <td className="py-2 px-1.5 sm:py-4 sm:px-3 text-start">
                                            {item?.practice_name}
                                        </td>
                                        <td className="py-2 px-1.5 sm:py-4 sm:px-3 text-start">
                                            {item?.firstname}
                                        </td>
                                        <td className="py-2 px-1.5 sm:py-4 sm:px-3 text-start">
                                            {item?.lastname}
                                        </td>
                                        <td className="py-2 px-1.5 sm:py-4 sm:px-3 text-start">
                                            {item?.email}
                                        </td>
                                        <td className="py-2 px-1.5 sm:py-4 sm:px-3 text-start">
                                        </td>
                                        <td className="py-2 px-1.5 sm:py-4 sm:px-3 text-start">
                                        </td>
                                        <td className="py-2 px-1.5 sm:py-4 sm:px-3">
                                            <div className="rounded-full shadow-xs bg-blue max-w-[100px] w-full" role="group">
                                                <button
                                                    onClick={() => {
                                                        setIsDelete(true)
                                                        setSelectedUsers(item)
                                                    }}
                                                    type="button" className="py-2 text-sm font-medium bg-transparent rounded-e-full w-full text-center flex items-center justify-center text-white">
                                                    <svg
                                                        width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M7 4V2H17V4H22V6H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V6H2V4H7ZM6 6V20H18V6H6ZM9 9H11V17H9V9ZM13 9H15V17H13V9Z" />
                                                    </svg>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                )) : (
                                    <tr>
                                        <td colSpan={8} className="text-center py-2 px-1.5 sm:py-4 sm:px-3 border-y-2 border-solid border-[#dfdfdf]">No Clients Found</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>

                    </div>

                    {paginatedData?.length > 0 && (
                        <div className="px-3 py-3">
                            <ul className="flex items-center -space-x-px h-10 text-base justify-end">
                                {/* Previous Button */}
                                <li>
                                    <button
                                        onClick={() => handlePageChange(currentPage - 1)}
                                        disabled={currentPage === 1}
                                        className={`flex items-center justify-center px-4 h-10 ms-0 leading-tight border border-e-0 rounded-s-lg border-[#73aeff] 
          ${currentPage === 1 ? "text-gray-400 bg-gray-200 cursor-not-allowed" : "text-blue bg-gray-200 hover:bg-gray-100"}`}
                                    >
                                        <span className="sr-only">Previous</span>
                                        <svg className="w-3 h-3 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 1 1 5l4 4"></path>
                                        </svg>
                                    </button>
                                </li>

                                {/* Dynamic Pagination Logic */}
                                {Array.from({ length: totalPages }, (_, i) => i + 1)
                                    .filter((page) => {
                                        return (
                                            page === 1 ||
                                            page === totalPages ||
                                            (page >= currentPage - 1 && page <= currentPage + 1)
                                        );
                                    })
                                    .reduce((acc, page, index, arr) => {
                                        if (index > 0 && page !== arr[index - 1] + 1) {
                                            acc.push("...");
                                        }
                                        acc.push(page);
                                        return acc;
                                    }, [])
                                    .map((page, index) => (
                                        <li key={index}>
                                            {page === "..." ? (
                                                <span className={`flex items-center justify-center px-4 h-10 leading-tight border border-[#73aeff] bg-gray-200 text-blue`}>...</span>
                                            ) : (
                                                <button
                                                    onClick={() => handlePageChange(page)}
                                                    className={`flex items-center justify-center px-4 h-10 leading-tight border border-[#73aeff] 
                ${currentPage === page ? "bg-white text-blue" : "bg-gray-200 text-blue hover:bg-gray-100"}`}
                                                >
                                                    {page}
                                                </button>
                                            )}
                                        </li>
                                    ))}

                                {/* Next Button */}
                                <li>
                                    <button
                                        onClick={() => handlePageChange(currentPage + 1)}
                                        disabled={currentPage === totalPages}
                                        className={`flex items-center justify-center px-4 h-10 leading-tight border rounded-e-lg border-[#73aeff] 
          ${currentPage === totalPages ? "text-gray-400 bg-gray-200 cursor-not-allowed" : "text-blue bg-gray-200 hover:bg-gray-100"}`}
                                    >
                                        <span className="sr-only">Next</span>
                                        <svg className="w-3 h-3 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4"></path>
                                        </svg>
                                    </button>
                                </li>
                            </ul>
                        </div>
                    )}


<div id="deletepopup" className={`fixed bg-[#0000007A] top-0 left-0 h-screen w-full z-[999] justify-center items-center px-3 ${isDelete ? 'flex' : 'hidden'}`}>
                        <div className="max-w-[630px] w-full mx-auto bg-white rounded-xl sm:rounded-2xl px-3 py-6 sm:py-10 sm:px-10 lg:px-20 innercard">
                            <div className="max-w-[312px] w-full flex justify-center items-center gap-6 flex-col mx-auto">
                                <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M36 66C19.4314 66 6 52.5684 6 36C6 19.4314 19.4314 6 36 6C52.5684 6 66 19.4314 66 36C66 52.5684 52.5684 66 36 66ZM36 60C49.2549 60 60 49.2549 60 36C60 22.7452 49.2549 12 36 12C22.7452 12 12 22.7452 12 36C12 49.2549 22.7452 60 36 60ZM36 31.7574L44.4852 23.2721L48.7278 27.5147L40.2426 36L48.7278 44.4852L44.4852 48.7278L36 40.2426L27.5147 48.7278L23.2721 44.4852L31.7574 36L23.2721 27.5147L27.5147 23.2721L36 31.7574Z" fill="#FF5252" />
                                </svg>
                                <h4 className="text-center font-bold text-xl mg:text-2xl text-blue">Are you sure! <br />you want to Delete the Listing?</h4>
                                <div className="flex justify-between gap-3 w-full">
                                    <button
                                        type="button"
                                        onClick={() => {
                                            setIsDelete(false)
                                            setSelectedUsers(null)
                                        }}
                                        className="bg-transparent text-center text-dark text-sm sm:text-base font-semibold sm:w-full sm:max-w-[154px] rounded-lg py-2 px-3 sm:py-3 sm:px-0 flex items-center justify-center border border-dark cancelbtn">Cancel</button>
                                    <button
                                        type="button"
                                        onClick={handleDelete}
                                        className="bg-blue text-center text-white text-sm sm:text-base font-semibold sm:w-full sm:max-w-[154px] rounded-lg py-2 px-3 sm:py-3 sm:px-0 flex items-center justify-center border border-blue deletebtn">Delete</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </main>

            </MasterAdminLayout>
        </>)
}

export default MasterAdminClientsList