import React, { useState } from "react";
import { Link } from "react-router-dom";
import api, { API_BASE_URL } from "../../../api/api";
import { toast } from "react-toastify";
import { CONSTANT_REDIRECT_TIME, getGender } from "../../../constant/common";
import { formatDateForView } from "../../../utils/formateDate";

const PractitionerDetailsCard = ({ practitionerDetails, getPractitionersList }) => {
    const [practiceactiveTab, setPracticeActiveTab] = useState("overview");

    const handleImageChange = async (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];

            const payload = {
                practices_id: practitionerDetails?.practices_id,
                image: file,
            };

            try {
                const response = await api.post("update-practices-image", payload, {
                    headers: { "Content-Type": "multipart/form-data" },
                });

                if (response?.data?.status === true) {
                    toast.success(response.data?.message);

                    if (getPractitionersList) {
                        setTimeout(() => {
                            getPractitionersList();
                        }, CONSTANT_REDIRECT_TIME);
                    }

                } else {
                    toast.error(response?.data?.message || "Failed ");
                    return null;
                }
            } catch (error) {
                console.error("Error :", error);
                toast.error("Something went wrong. Please try again.");
                return null;
            }
        } else {
            console.log("No file selected.");
        }
    };

    return (
        <>

            <div className="px-4 py-4 sm:px-[30px] sm:py-[30px] md:px-[60px] md:py-[60px] bg-white rounded-[18px]">
                <div className="mb-4 md:mb-6 lg:mb-10 flex flex-wrap gap-y-3">
                    <div className="w-full md:w-[calc(100%-170px)] flex flex-wrap gap-y-3 listcardwrapper">
                        <div className="w-[100px] sm:w-[170px] w-100 imgcard">
                            <div>
                                <img src={practitionerDetails?.image ? API_BASE_URL + practitionerDetails?.image : null} alt="" className="w-full rounded-lg aspect-square object-cover mb-1.5" />
                            </div>
                            <label htmlFor={`practitioner_${practitionerDetails?.practices_id}`} className=" py-2 bg-transparent text-dark border border-dark text-xs sm:text-base w-full flex items-center justify-center font-semibold rounded border-solid max-w-[130px] mx-auto cursor-pointer">Add Logo</label>
                            <input type="file" name="" id={`practitioner_${practitionerDetails?.practices_id}`} className="hidden" onChange={handleImageChange} />
                        </div>
                        <div className="w-[calc(100%-100px)] sm:w-[calc(100%-170px)] px-3 sm:px-6 contentcard">
                            <div className="sm:max-w-[406px] w-100">
                                <div className="text-lg sm:text-xl md:text-2xl block w-fit font-semibold text-gray-800 mb-1 sm:mb-2 ">
                                    <span>{practitionerDetails?.practice_name}</span>
                                    {practitionerDetails?.is_verified == 1
                                        &&
                                        <span className="float-right mt-1 pl-2">
                                            <img src="/assets/image/verifiedicon.svg" alt="" srcSet="" />
                                        </span>
                                    }

                                </div>
                                {/* <div className="flex items-center flex-wrap gap-x-3 gap-y-1 mb-2 sm:mb-4">
                                                    <span className="flex items-center text-green text-xs font-medium">
                                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M9.00045 13.695L3.71036 16.6562L4.89186 10.7099L0.440918 6.59385L6.4612 5.88005L9.00045 0.375L11.5396 5.88005L17.5599 6.59385L13.109 10.7099L14.2905 16.6562L9.00045 13.695Z" fill="#FFC116" />
                                                        </svg>
                                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M9.00045 13.695L3.71036 16.6562L4.89186 10.7099L0.440918 6.59385L6.4612 5.88005L9.00045 0.375L11.5396 5.88005L17.5599 6.59385L13.109 10.7099L14.2905 16.6562L9.00045 13.695Z" fill="#FFC116" />
                                                        </svg>
                                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M9.00045 13.695L3.71036 16.6562L4.89186 10.7099L0.440918 6.59385L6.4612 5.88005L9.00045 0.375L11.5396 5.88005L17.5599 6.59385L13.109 10.7099L14.2905 16.6562L9.00045 13.695Z" fill="#FFC116" />
                                                        </svg>
                                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M9.00045 13.695L3.71036 16.6562L4.89186 10.7099L0.440918 6.59385L6.4612 5.88005L9.00045 0.375L11.5396 5.88005L17.5599 6.59385L13.109 10.7099L14.2905 16.6562L9.00045 13.695Z" fill="#FFC116" />
                                                        </svg>
                                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M9.00045 11.976L12.1855 13.7588L11.4741 10.1788L14.1539 7.70055L10.5292 7.27075L9.00045 3.95627V11.976ZM9.00045 13.695L3.71036 16.6562L4.89186 10.7099L0.440918 6.59385L6.4612 5.88005L9.00045 0.375L11.5396 5.88005L17.5599 6.59385L13.109 10.7099L14.2905 16.6562L9.00045 13.695Z" fill="#FFC116" />
                                                        </svg>
                                                        <span className="mx-1 text-dark">(4.5)</span>
                                                        79 Reviews
                                                    </span>
                                                </div> */}
                                <Link
                                    // to={`https://www.google.com/maps?q=${practitionerDetails?.suburb?.latitude},${practitionerDetails?.suburb?.longitude}`}
                                    to={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(practitionerDetails?.address + ', ' + practitionerDetails?.suburb?.name + ', ' + practitionerDetails?.state?.name)}`}
                                    target="_blank" rel="noopener noreferrer" className="flex gap-x-2 sm:gap-x-3 gap-y-1 sm:items-center mb-2 sm:mb-3">
                                    <svg width="24" className="w-4 h-4 sm:w-6 sm:h-6 mt-1 sm:mt-0" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M18.364 17.364L12 23.7279L5.63604 17.364C2.12132 13.8492 2.12132 8.15076 5.63604 4.63604C9.15076 1.12132 14.8492 1.12132 18.364 4.63604C21.8787 8.15076 21.8787 13.8492 18.364 17.364ZM12 13C13.1046 13 14 12.1046 14 11C14 9.89543 13.1046 9 12 9C10.8954 9 10 9.89543 10 11C10 12.1046 10.8954 13 12 13Z" fill="#0052CC" />
                                    </svg>
                                    <span className="text-dark text-sm sm:text-base font-medium underline underline-offset-[2px] w-[calc(100%-24px)] sm:w-[calc(100%-36px)]">{practitionerDetails?.address}, {practitionerDetails?.suburb?.name}, {practitionerDetails?.state?.name}, {practitionerDetails?.postcode?.postcode}</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="w-full md:w-[170px] flex flex-wrap lg:flex-col lg:items-start lg:justify-center gap-2 sm:gap-3">
                        <Link to={`tel:${practitionerDetails?.phone}`} className="bg-green text-center text-white text-sm sm:text-base font-semibold md:w-full  rounded-lg py-2 px-3 sm:py-3  sm:px-2 flex items-center justify-center border border-green">Call {practitionerDetails?.practice_name}</Link>
                        <Link to={`mailto:${practitionerDetails?.email}`} className="bg-transparent text-center text-dark text-sm sm:text-base font-semibold md:w-full  rounded-lg py-2 px-3 sm:py-3 sm:px-2 flex items-center justify-center border border-dark emailButton">Email {practitionerDetails?.practice_name}</Link>
                        {/* <button className="bg-transparent text-center text-dark text-sm sm:text-base font-semibold md:w-full  rounded-lg py-2 px-3 sm:py-3  sm:px-2 flex items-center justify-center border border-dark">Book Appointment</button>
                                        <button className="bg-transparent text-center text-dark text-sm sm:text-base font-semibold md:w-full  rounded-lg py-2 px-3 sm:py-3  sm:px-2 flex items-center justify-center border border-dark">View Website</button> */}
                    </div>
                </div>
                <div className="tab-container">
                    <div className="tab-btn p-1.5 flex flex-wrap lg:justify-between bg-[#f5f5f5] rounded-lg gap-3 w-full max-w-[850px]">
                        <button
                            onClick={() => setPracticeActiveTab('overview')}
                            className={`px-5 py-2 text-base font-semibold rounded ${practiceactiveTab === "overview" ? "bg-blue text-white" : "bg-transparent text-dark"}`}>Overview</button>
                        <button
                            onClick={() => setPracticeActiveTab('services_fees')}
                            className={`px-5 py-2 text-base font-semibold rounded ${practiceactiveTab === "services_fees" ? "bg-blue text-white" : "bg-transparent text-dark"}`}>Fees</button>
                        <button
                            onClick={() => setPracticeActiveTab('photos')}
                            className={`px-5 py-2 text-base font-semibold rounded ${practiceactiveTab === "photos" ? "bg-blue text-white" : "bg-transparent text-dark"}`}>Photos</button>
                        <button
                            onClick={() => setPracticeActiveTab('faqs')}
                            className={`px-5 py-2 text-base font-semibold rounded ${practiceactiveTab === "faqs" ? "bg-blue text-white" : "bg-transparent text-dark"}`}>FAQs</button>
                        <button
                            onClick={() => setPracticeActiveTab('more_details')}
                            className={`px-5 py-2 text-base font-semibold rounded ${practiceactiveTab === "more_details" ? "bg-blue text-white" : "bg-transparent text-dark"}`}>More Details</button>
                    </div>

                    <div className="tab-content rounded-lg pt-6">
                        {practiceactiveTab === "overview" &&
                            <div className="">
                                <p className="text-dark text-sm font-medium mb-3 last:mb-0 text-justify">{practitionerDetails?.full_description}</p>
                            </div>
                        }

                        {practiceactiveTab === "services_fees" &&
                            <div className="">
                                <h3 className="text-dark text-lg font-semibold mb-4">Services & Fees</h3>
                                <ul className="space-y-3">
                                    <li className="text-dark flex flex-wrap items-center"><span className="font-semibold text-base min-w-56">Services :</span>
                                        <span>
                                            {practitionerDetails?.services?.map(service => service.name).join(", ")}
                                        </span>
                                    </li>
                                    <li className="text-dark flex flex-wrap items-center"><span className="font-semibold text-base min-w-56">Servicing Areas :</span>
                                        <span> {practitionerDetails?.services_areas?.map(services_area => services_area.postcode).join(", ")}</span>
                                    </li>
                                    <li className="text-dark flex flex-wrap items-start">
                                        <span className="font-semibold text-base min-w-56">Add Service & Fees :</span>
                                        <div className="space-y-2">
                                            {practitionerDetails?.services_fees.length > 0 ?
                                                <>
                                                    {practitionerDetails?.services_fees.map((item, index) => <p key={index}>{item?.name} - {item?.fees}</p>)}
                                                </>
                                                : null
                                            }
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        }

                        {/* {practiceactiveTab === "photos" &&
                                            <div className="">
                                                <PhotoProvider>
                                                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3 photoGallery">
                                                        {images.map((src, index) => (
                                                            <PhotoView key={index} src={src}>
                                                                <img src={src} alt="physiotherapist gallery" className="w-full object-cover aspect-[175/111] rounded-lg cursor-pointer" />
                                                            </PhotoView>
                                                        ))}
                                                    </div>
                                                </PhotoProvider>
                                            </div>
                                        } */}


                        {/* {practiceactiveTab === "faqs" &&
                                            <div className="">
                                                <div className="mb-6 relative w-full max-w-[630px] mx-auto">
                                                    <div className="absolute left-[30px] top-1/2 -translate-y-1/2 w-6 h-6 z-10">
                                                        <img src="data:image/svg+xml,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M23.6625%2022.0027L16.8%2015.1402C18.0797%2013.4996%2018.7688%2011.4746%2018.7688%209.39805C18.7688%204.22305%2014.5594%200.0136719%209.38438%200.0136719C4.20937%200.0136719%200%204.22305%200%209.39805C0%2014.573%204.20937%2018.7824%209.38438%2018.7824C11.4797%2018.7824%2013.5141%2018.084%2015.1594%2016.7902L22.0172%2023.648C22.4906%2024.084%2023.2219%2024.0512%2023.6578%2023.5777C24.0656%2023.1324%2024.0656%2022.448%2023.6625%2022.0027ZM2.32031%209.39805C2.32031%205.50273%205.48906%202.33867%209.37969%202.33867C13.2703%202.33867%2016.4391%205.50742%2016.4391%209.39805C16.4391%2013.2887%2013.2703%2016.4574%209.37969%2016.4574C5.48906%2016.4574%202.32031%2013.2887%202.32031%209.39805Z%22%20fill%3D%22%230052CC%22%2F%3E%3C%2Fsvg%3E" alt="Search Icon" />
                                                    </div>
                                                    <input type="search" name="" id="" className="py-[14px] w-full text-dark text-lg pl-[68px] pr-[30px] bg-[#f5f5f5] rounded-lg focus:border-blue outline-none relative " placeholder="search for a question" />
                                                </div>
                                                <div className="dropdownfilter w-full bg-[#f5f5f5] py-6 px-8 rounded-lg mb-3 last:mb-0">
                                                    <button className=" w-full flex justify-between items-center" aria-controls="faq-1" aria-expanded="true">
                                                        <span className="text-xl text-start font-semibold text-dark w-[calc(100%-30px)]">How do i get started?</span>
                                                        <svg className="dropdown-arrow transition-transform duration-300 rotate-180" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M11.636 13.828L19.1308 6.33337L21.2721 8.47471L11.636 18.1108L2 8.47471L4.14134 6.33337L11.636 13.828Z" fill="black" />
                                                        </svg>
                                                    </button>
                                                    <div className="mt-4 space-y-2 dropdownContentfilter" id="faq-1">
                                                        <p className="text-justify font-medium text-dark text-base">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries.</p>
                                                    </div>
                                                </div>
                                                <div className="dropdownfilter w-full bg-[#f5f5f5] py-6 px-8 rounded-lg mb-3 last:mb-0">
                                                    <button className="text-xl font-semibold text-dark w-full flex justify-between items-center" aria-controls="faq-2" aria-expanded="false">
                                                        <span className="text-xl text-start font-semibold text-dark w-[calc(100%-30px)]">Which is included in the free plan?</span>
                                                        <svg className="dropdown-arrow transition-transform duration-300" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M11.636 13.828L19.1308 6.33337L21.2721 8.47471L11.636 18.1108L2 8.47471L4.14134 6.33337L11.636 13.828Z" fill="black" />
                                                        </svg>
                                                    </button>
                                                    <div className="mt-4 space-y-2 hidden dropdownContentfilter" id="faq-2">
                                                        <p className="text-justify font-medium text-dark text-base">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries.</p>
                                                    </div>
                                                </div>
                                                <div className="dropdownfilter w-full bg-[#f5f5f5] py-6 px-8 rounded-lg mb-3 last:mb-0">
                                                    <button className="text-xl font-semibold text-dark w-full flex justify-between items-center" aria-controls="faq-3" aria-expanded="false">
                                                        <span className="text-xl text-start font-semibold text-dark w-[calc(100%-30px)]">How do i cancel my membership?</span>
                                                        <svg className="dropdown-arrow transition-transform duration-300" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M11.636 13.828L19.1308 6.33337L21.2721 8.47471L11.636 18.1108L2 8.47471L4.14134 6.33337L11.636 13.828Z" fill="black" />
                                                        </svg>
                                                    </button>
                                                    <div className="mt-4 space-y-2 hidden dropdownContentfilter" id="faq-3">
                                                        <p className="text-justify font-medium text-dark text-base">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries.</p>
                                                    </div>
                                                </div>
                                                <div className="dropdownfilter w-full bg-[#f5f5f5] py-6 px-8 rounded-lg mb-3 last:mb-0">
                                                    <button className="text-xl font-semibold text-dark w-full flex justify-between items-center" aria-controls="faq-4" aria-expanded="false">
                                                        <span className="text-xl text-start font-semibold text-dark w-[calc(100%-30px)]">How do i transfer my membership to a different account?</span>
                                                        <svg className="dropdown-arrow transition-transform duration-300" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M11.636 13.828L19.1308 6.33337L21.2721 8.47471L11.636 18.1108L2 8.47471L4.14134 6.33337L11.636 13.828Z" fill="black" />
                                                        </svg>
                                                    </button>
                                                    <div className="mt-4 space-y-2 hidden dropdownContentfilter" id="faq-4">
                                                        <p className="text-justify font-medium text-dark text-base">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries.</p>
                                                    </div>
                                                </div>
                                                <div className="dropdownfilter w-full bg-[#f5f5f5] py-6 px-8 rounded-lg mb-3 last:mb-0">
                                                    <button className="text-xl font-semibold text-dark w-full flex justify-between items-center" aria-controls="faq-5" aria-expanded="false">
                                                        <span className="text-xl text-start font-semibold text-dark w-[calc(100%-30px)]">What is the refund policy?</span>
                                                        <svg className="dropdown-arrow transition-transform duration-300" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M11.636 13.828L19.1308 6.33337L21.2721 8.47471L11.636 18.1108L2 8.47471L4.14134 6.33337L11.636 13.828Z" fill="black" />
                                                        </svg>
                                                    </button>
                                                    <div className="mt-4 space-y-2 hidden dropdownContentfilter" id="faq-5">
                                                        <p className="text-justify font-medium text-dark text-base">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        } */}

                        {practiceactiveTab === "more_details" &&
                            <div className="">
                                <ul className="space-y-3">
                                    <li className="text-dark flex flex-wrap items-center"><span className="font-semibold text-base min-w-56">AHPRA Number :</span><span>{practitionerDetails?.ahpra_number}</span></li>
                                    <li className="text-dark flex flex-wrap items-center"><span className="font-semibold text-base min-w-56">AHPRA Registration Date :</span><span>{formatDateForView(practitionerDetails?.ahpra_registration_date)}</span></li>
                                    <li className="text-dark flex flex-wrap items-center"><span className="font-semibold text-base min-w-56">AHPRA Expiry Date :</span><span>{formatDateForView(practitionerDetails?.ahpra_expiry_date)}</span></li>
                                    <li className="text-dark flex flex-wrap items-center"><span className="font-semibold text-base min-w-56">Gender :</span><span>{getGender(practitionerDetails?.gender)}</span></li>
                                    <li className="text-dark flex flex-wrap items-center"><span className="font-semibold text-base min-w-56">Languages  :</span><span>{practitionerDetails?.languages?.map(language => language.name).join(", ")}</span></li>
                                    <li className="text-dark flex flex-wrap items-center"><span className="font-semibold text-base min-w-56">Type of Practice :</span><span>{practitionerDetails?.practices_types?.map(practices_type => practices_type.name).join(", ")}</span></li>
                                    <li className="text-dark flex flex-wrap items-center"><span className="font-semibold text-base min-w-56">Servicing Areas :</span> <span>{practitionerDetails?.services_areas?.map(services_area => services_area.postcode).join(", ")}</span></li>
                                    {/* <li className="text-dark flex flex-wrap items-center"><span className="font-semibold text-base min-w-56">Custom URL :</span> <a href="#">https:www.apexclinic.com</a></li> */}
                                </ul>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>)
}

export default PractitionerDetailsCard;