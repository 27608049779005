import React, { useEffect, useRef, useState } from "react";
import FrontendLayout from "../../layouts/FrontendLayout";
import { Link } from "react-router-dom";
import { API_BASE_URL, fetchData } from "../../api/api";

const AboutPage = () => {


    return (
        <>
            <FrontendLayout>
                <section className="pt-6 sm:pt-8 md:pt-12  pb-[60px] md:pb-[100px]">
                    <div className="container mx-auto px-4 sm:px-3">
                        <h1 className="text-center text-[#222] text-[24px] sm:text-[34px] leading-relaxed font-semibold mb-6 sm:mb-8 lg:mb-12">About Us</h1>
                        <div className="grid grid-cols-2 gap-[24px] lg:gap-[30px] mb-5 md:mb-10 lg:mb-16">
                            <div className="flex flex-col justify-center items-start">
                                <h2 className="text-dark text-xl sm:text-2xl mb-3 font-semibold">Connecting You to Trusted Allied Health Practitioners </h2>
                                <p className="text-base text-dark">Welcome to Allied Health.com.au, your trusted online platform dedicated to connecting patients with qualified allied health practitioners. Whether you're seeking assistance with physiotherapist, Occupational Therapist, podiatrist, or other specialized services, we’re here to simplify your path to better health. Our mission is to help you easily find and connect with experienced Allied Health Practitioners who are ready to support your well-being. </p>
                            </div>
                            <div className="flex justify-center items-center">
                                <img src="/assets/image/about-us-1.png" alt=""/>
                            </div>
                        </div>
                        <div className="grid grid-cols-2 gap-[24px] lg:gap-[30px] mb-5 md:mb-10 lg:mb-16">
                            <div className="flex justify-center items-center">
                                <img src="/assets/image/about-us-2.png" alt=""/>
                            </div>
                            <div className="flex flex-col justify-center items-start">
                                <h2 className="text-dark text-xl sm:text-2xl mb-3 font-semibold">A Wide Network of Experienced Professionals</h2>
                                <p className="text-base text-dark">We understand that finding the right allied health practitioners can be overwhelming. That’s why we’ve created a platform that makes it easier for you to access a wide range of allied health services, from physiotherapists and occupational therapists to dietitians and psychologists. All of our practitioners are carefully vetted to ensure you receive the highest quality care, personalized to meet your unique needs and goals.</p>
                            </div>
                        </div>
                        <div className="grid grid-cols-2 gap-[24px] lg:gap-[30px]">
                            <div className="flex flex-col justify-center items-start">
                                <h2 className="text-dark text-xl sm:text-2xl mb-3 font-semibold">Empowering Your Health Journey</h2>
                                <p className="text-base text-dark">At Allied Health.com.au, we believe in a holistic approach to health and wellness. Our platform is designed to empower you to take control of your healthcare decisions, providing convenient access to the right professionals, at the right time. Whether you’re looking for support in recovery or striving for optimal health, we’re here to guide you every step of the way. Your health is our priority, and we’re committed to supporting you on your journey to well-being. </p>
                            </div>
                            <div className="flex justify-center items-center">
                                <img src="/assets/image/about-us-3.png" alt=""/>
                            </div>
                        </div>
                    </div>
                </section>
            </FrontendLayout>
        </>)
}

export default AboutPage