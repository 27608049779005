import React, { useEffect, useRef, useState } from "react";
import FrontendLayout from "../../layouts/FrontendLayout";
import { Link, useNavigate } from "react-router-dom";
import { API_BASE_URL, fetchData } from "../../api/api";
import FAQ from "../../components/frontend/home/FAQ";
import Spinner from "../../components/common/Spinner";
import Select from "react-select";
import { toast } from "react-toastify";

const HomePage = () => {
    const navigate = useNavigate();

    const didFetch = useRef(false);

    const [isLoading, setIsLoading] = useState(false);

    const [servicesList, setServicesList] = useState([]);
    const [selectedServices, setSelectedServices] = useState({
        value: null,
        label: null,
        slug: null,
    });

    const [suburbList, setSuburbList] = useState([]);
    const [selectedSuburb, setSelectedSuburb] = useState({
        value: null,
        label: null,
        slug: null,
    });

    const [filteredSuburbs, setFilteredSuburbs] = useState([]);
    const [inputValue, setInputValue] = useState("");
    const [typingTimeout, setTypingTimeout] = useState(null);

    const handleInputChange = (newValue) => {
        setInputValue(newValue);

        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }

        const timeout = setTimeout(() => {
            filterSuburbs(newValue);
        }, 300);

        setTypingTimeout(timeout);
    };

    const filterSuburbs = (searchText) => {
        if (searchText.length >= 2) {
            const filtered = suburbList
                .filter(item =>
                    item.name.toLowerCase().includes(searchText.toLowerCase()) ||
                    item.postcode.toString().includes(searchText)
                )
                .slice(0, 50);
            setFilteredSuburbs(filtered);
        } else {
            setFilteredSuburbs([]);
        }
    };
    const [isViewAll, setIsViewAll] = useState(false);

    const getServices = async () => {
        setIsLoading(true);
        try {
            const data = await fetchData('/get-services', { status: 1 });
            setServicesList(data);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const getSuburb = async (fk_postcodes_id = null) => {
        try {
            const data = await fetchData('/get-suburbs', {
                status: 1,
                fk_postcodes_id: fk_postcodes_id
            });
            setSuburbList(data);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {

        }
    };

    const toggleView = () => {
        setIsViewAll(!isViewAll);
    };

    useEffect(() => {
        if (!didFetch.current) {
            getServices();
            getSuburb();
            didFetch.current = true;
        }
    }, []);

    const handleOnClickSearch = (e) => {
        e.preventDefault();

        if (!selectedServices?.value) {
            toast.error("Please select a practitioner.");
            return;
        }

        if (!selectedSuburb?.value) {
            toast.error("Please enter post code.");
            return;
        }

        const navigateURL = "/" + selectedSuburb?.postcode + "/" + selectedServices?.slug + "-in-" + selectedSuburb?.slug;
        navigate(navigateURL);
    }

    if (isLoading) {
        return (<Spinner />);
    }

    return (
        <>
            <FrontendLayout>

                <section>
                    <div className="container px-3 mx-auto flex flex-col items-center justify-center pt-[40px]  md:pt-[80px] pb-[60px] md:pb-[100px]">
                        <h1 className="text-[28px] leading-relaxed sm:text-[39px] sm:leading-[59px] text-dark font-bold text-center mb-2.5">Compare Allied Health Practitioners</h1>
                        <p className="text-dark text-lg text-center mb-6 md:mb-[50px]">Easily compare practitioners based on services, location, client ratings and more!</p>
                        <form className="flex flex-wrap max-w-[1072px] w-full mx-auto mb-10 md:mb-20">
                            <div className="basis-full sm:basis-3/6 md:basis-2/5 p-2.5">
                                <div className="relative">

                                    <div className="pl-[75px] text-dark text-lg bg-white rounded-xl w-full outline-none">
                                        <Select
                                            required
                                            options={servicesList.map((item) => ({
                                                value: item.services_id,
                                                label: item.name,
                                                slug: item.slug,
                                            }))}
                                            className="w-full text-black custom-select no-border no-icon"
                                            placeholder="Select Practitioner"
                                            onChange={(selectedOption) => {
                                                setSelectedServices(selectedOption);
                                            }}
                                            value={selectedServices?.value ? selectedServices : null}

                                        />
                                    </div>
                                    <img src="/assets/image/svg/compare-input-a.svg" alt="" srcSet="" className="absolute top-1/2 left-6 transform -translate-y-1/2" />
                                </div>
                            </div>
                            <div className="basis-full sm:basis-3/6 md:basis-2/5 p-2.5">
                                <div className="relative">

                                    <div className="pl-[75px] text-dark text-lg bg-white rounded-xl w-full outline-none">
                                        <Select
                                            required
                                            options={filteredSuburbs.map((item) => ({
                                                value: item.suburbs_id,
                                                label: `${item.name} (${item.postcode})`,
                                                slug: item.slug,
                                                fk_postcodes_id: item.fk_postcodes_id,
                                                postcode: item.postcode,
                                            }))}
                                            className="w-full text-black custom-select no-border  no-icon"
                                            placeholder="Enter Postcode"
                                            onChange={(selectedOption) => {
                                                setSelectedSuburb(selectedOption);
                                            }}
                                            value={selectedSuburb?.value ? selectedSuburb : null}
                                            onInputChange={handleInputChange} 
                                            isClearable
                                            isSearchable
                                            noOptionsMessage={() => inputValue.length <= 2 ? "Enter Postcode" : "No options available"}
                                        />

                                    </div>
                                    <img src="/assets/image/svg/compare-input-b.svg" alt="" srcSet="" className="absolute top-1/2 left-6 transform -translate-y-1/2" />
                                </div>
                            </div>
                            <div className="basis-full md:basis-1/5 p-2.5">
                                <button
                                    onClick={handleOnClickSearch}
                                    type="submit" className="block px-3 py-[18px] h-[60px] text-white transition duration-100 ease-in-out bg-blue-500  rounded-md shadow-sm  bg-green text-base font-semibold w-full text-center">Search</button>
                            </div>
                        </form>

                        <div id="gridContainer" className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-x-[15px] sm:gap-x-[24px] lg:gap-x-[30px] gap-y-[15px] sm:gap-y-[24px] w-full">
                            {servicesList?.length > 0 &&
                                servicesList
                                    .slice(0, isViewAll ? servicesList.length : 8)
                                    .map((service, index) => (
                                        <button
                                            onClick={() => setSelectedServices({
                                                value: service?.services_id,
                                                label: service?.name,
                                                slug: service?.slug,
                                            })}
                                            key={index} className="p-3 sm:p-5 bg-white rounded-xl flex flex-col items-center justify-center gap-y-3 hover:shadow-card service-box">
                                            <img src={`${service?.image != null ? API_BASE_URL + service?.image : ''}`} alt={service?.name} />
                                            <span className="text-base lg:text-xl sm:leading-9 text-dark font-medium text-center">{service?.name}</span>
                                        </button>
                                    ))
                            }
                            {servicesList.length > 8 && (
                                <div className="col-span-2 md:col-span-3 xl:col-span-4">
                                    <button
                                        type="button"
                                        id="toggleButton"
                                        onClick={toggleView}
                                        className="px-11 py-2 bg-blue text-white rounded-[8px] font-bold font-poppins text-lg border-2 border-solid border-blue hover:scale-[1.04] transition ease-in-out flex max-w-[240px] w-full justify-center mx-auto items-center gap-2 mt-5"
                                    >
                                        {isViewAll ? 'View Less' : 'View All'}
                                        <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg"
                                            className={`transition-transform duration-300 ${isViewAll ? 'rotate-180' : 'rotate-0'}`}>
                                            <path d="M14 1C14.0008 1.13161 13.9756 1.26207 13.9258 1.38391C13.876 1.50574 13.8027 1.61656 13.71 1.71L7.71003 7.71C7.52267 7.89625 7.26921 8.00079 7.00503 8.00079C6.74084 8.00079 6.48739 7.89625 6.30003 7.71L0.300027 1.71C0.136201 1.5187 0.0505946 1.27262 0.0603158 1.02095C0.0700369 0.769271 0.174369 0.530532 0.352463 0.352437C0.530558 0.174342 0.769297 0.0700096 1.02097 0.0602884C1.27265 0.0505672 1.51873 0.136174 1.71003 0.3L7.00003 5.59L12.29 0.3C12.4293 0.159578 12.6071 0.063607 12.8009 0.0242218C12.9947 -0.0151634 13.1958 0.00380633 13.3789 0.0787319C13.5619 0.153658 13.7186 0.281174 13.8292 0.445157C13.9397 0.609141 13.9992 0.802227 14 1Z" fill="white" />
                                        </svg>
                                    </button>
                                </div>
                            )}
                        </div>

                    </div>
                </section>
                <section className="bg-white">
                    <div className="container mx-auto px-3 pt-[40px] md:pt-[80px] pb-[60px] md:pb-[110px]">
                        <div className="flex flex-wrap mb-[40px] md:mb-[80px] justify-center">
                            <div className="p-2 basis-[100%] sm:basis-1/2 lg:basis-1/3">
                                <div className="border border-solid border-green rounded-xl py-6 px-4 flex items-center justify-center gap-4">
                                    <img src="/assets/image/svg/obligationfree.svg" alt="" />
                                    <span className="text-xl text-dark font-semibold text-center">No Obligation</span>
                                </div>
                            </div>
                            <div className="p-2 basis-[100%] sm:basis-1/2 lg:basis-1/3">
                                <div className="border border-solid border-green rounded-xl py-6 px-4 flex items-center justify-center gap-4">
                                    <img src="/assets/image/svg/nofee.svg" alt="" />
                                    <span className="text-xl text-dark font-semibold text-center">100% Free for Families</span>
                                </div>
                            </div>
                            <div className="p-2 basis-[100%] sm:basis-1/2 lg:basis-1/3">
                                <div className="border border-solid border-green rounded-xl py-6 px-4 flex items-center justify-center gap-4">
                                    <img src="/assets/image/svg/savetime.svg" alt="" />
                                    <span className="text-xl text-dark font-semibold text-center">Save Hours of Time</span>
                                </div>
                            </div>
                        </div>
                        <div className="grid grid-cols-12 gap-[24px] lg:gap-[30px]">
                            <div className="col-span-12 md:col-span-5">
                                <h2 className="text-[24px] sm:text-[34px] leading-normal text-blue font-bold mb-[16px] sm:mb-[24px] lg:mb-[30px]">
                                    <span className="font-normal text-dark block">Looking for a</span>
                                    Allied Health Practitioners?
                                </h2>
                                <Link to="/find" className="px-6 py-2 bg-green text-white rounded-[8px] font-bold font-poppins text-lg border-2 border-solid border-green hover:scale-[1.04] transition ease-in-out  max-w-[240px] w-full mx-auto mt-5 block ml-0 text-center">Let’s connect you</Link>
                            </div>
                            <div className="col-span-12 md:col-span-7">
                                <p className="text-base text-dark mb-3">Connecting you with Trusted Allied Health Practitioners: We understand the importance of holistic care, which is why we connect you with a wide range of experienced allied health practitioners. Whether you're seeking physiotherapy, speech therapy, occupational therapy, dietetics, or other health services, we are here to help you find the right professionals to meet your needs. </p>
                                <p className="text-base text-dark">We’re here to support you every step of the way to find Trusted Allied Health Practitioners. Click on Let’s connect you button to get started and we will connect you with an allied health practitioner today! </p>
                            </div>

                        </div>
                    </div>
                </section>
                <section className="py-[40px] md:py-[80px]" id="Howitworks">
                    <div className="container mx-auto px-3">
                        <h2 className="text-center text-[#222] text-[24px] sm:text-[34px] leading-relaxed font-semibold mb-[24px] sm:mb-[40px] lg:mb-[60px]">How it Works : 3 Easy Steps</h2>
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-[24px] lg:gap-[30px]">
                            <div className="flex flex-col items-center justify-start px-3">
                                <div className="max-w-[240px] rounded-lg border-[3px] border-green text-green w-full py-4 text-center font-bold text-xl sm:text-3xl mb-8">STEP 1</div>
                                <p className="text-black font-semibold text-lg mb-2 text-center max-w-[341px] w-100">Tell us about you</p>
                                <p className="text-black font-normal text-base mb-2 text-center max-w-[341px] w-100">Answer a few questions about your requirement, & preferences to find the best suitable Allied Health Practitioner. </p>
                            </div>
                            <div className="flex flex-col items-center justify-start px-3">
                                <div className="max-w-[240px] rounded-lg border-[3px] border-green text-green w-full py-4 text-center font-bold text-xl sm:text-3xl mb-8">STEP 2</div>
                                <p className="text-black font-semibold text-lg mb-2 text-center max-w-[341px] w-100">Find the Right Practitioner </p>
                                <p className="text-black font-normal text-base mb-2 text-center max-w-[341px] w-100">We’ll match you with suitable Practitioner or you can browse our comprehensive directory of skilled allied health professionals. Filter by specialty, location, and service to find the perfect match for your needs.</p>
                            </div>
                            <div className="flex flex-col items-center justify-start px-3">
                                <div className="max-w-[240px] rounded-lg border-[3px] border-green text-green w-full py-4 text-center font-bold text-xl sm:text-3xl mb-8">STEP 3</div>
                                <p className="text-black font-semibold text-lg mb-2 text-center max-w-[341px] w-100">We will connect you </p>
                                <p className="text-black font-normal text-base mb-2 text-center max-w-[341px] w-100">We’ll connect you with your chosen Practitioner, so you can start receiving Allied Health services with Trusted Practitioner.</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="pt-[40px] md:pt-[70px] pb-[60px] md:pb-[100px]" id="faqs">
                    <div className="container px-3 mx-auto">
                        <div className="flex flex-wrap justify-between mb-[20px] md:mb-[30px] lg:mb-[40px] gap-3 fade-up show" style={{ "--delay": "0s" }}>
                            <div className="w-fit">
                                <h3 className="text-dark text-[24px] sm:text-[34px]  leading-normal font-semibold">Frequently Asked Questions</h3>
                            </div>
                            <div className="max-w-[630px] w-full">
                                <p className="text-dark text-lg"></p>
                            </div>
                        </div>

                        <FAQ />


                    </div>
                </section>
            </FrontendLayout >
        </>)
}

export default HomePage