import React, { useEffect, useRef, useState } from "react";
import FrontendLayout from "../../layouts/FrontendLayout";
import { Link } from "react-router-dom";
import { API_BASE_URL, fetchData } from "../../api/api";
import PracticeCardMap from "../../components/frontend/practices/PracticeCardMap";
import Spinner from "../../components/common/Spinner";
import Select from "react-select";


const MapPage = () => {
    const didFetch = useRef(false);

    const [isFilterTab, setIsFilterTab] = useState(false);

    const [searchList, setSearchList] = useState([]);

    const [selectedLanguages, setSelectedLanguages] = useState([]);
    const [selectedPaymentMethods, setSelectedPaymentMethods] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);

    const itemsPerPage = 10;

    const totalPages = Math.ceil(searchList.length / itemsPerPage);

    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    const paginatedData = searchList?.length > 0 ? searchList.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    ) : [];

    const [isLoading, setIsLoading] = useState(false);

    const [selectedFilter, setSelectedFilter] = useState("All");

    const [paymentMethodsList, setPaymentMethodsList] = useState([]);

    const [languagesList, setLanguagesList] = useState([]);

    const getSearchList = async (tab) => {
        setIsLoading(true);
        let is_practitioner = null;
        if (tab == "Practitioners") {
            is_practitioner = 1;
        }
        else if (tab == "Practices") {
            is_practitioner = 0;
        }
        else {
            is_practitioner = null;
        }

        try {
            const data = await fetchData('/get-frontend-practices', {
                status: 1,
                is_practitioner: is_practitioner,
                languages_ids: selectedLanguages.join(","),
                payment_methods_ids: selectedPaymentMethods.join(",")
            });
            setSearchList(data?.practices ? data?.practices : []);

        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const getPaymentMethods = async () => {
        try {
            const data = await fetchData('/get-master-practice-payment-methods', { status: 1 });
            setPaymentMethodsList(data);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {

        }
    };

    const getLanguages = async () => {
        try {
            const data = await fetchData('/get-languages', { status: 1 });
            setLanguagesList(data);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {

        }
    };

    useEffect(() => {
        if (!didFetch.current) {
            getSearchList(null);
            // getPaymentMethods();
            // getLanguages();
            didFetch.current = true;
        }
    }, []);

    // useEffect(() => {
    //     getSearchList();
    // }, [selectedLanguages, selectedPaymentMethods]);

    const handleTabChange = (tab) => {
        setSelectedFilter(tab)
        setCurrentPage(1);
        getSearchList(tab);
    }

    const handleOnClickClearAll = () => {
        setSelectedLanguages([]);
        setSelectedPaymentMethods([]);
    };


    if (isLoading) {
        return (<Spinner />);
    }


    return (
        <>
            <FrontendLayout>
                <section>
                    <div className="lg:h-[calc(100vh-96px)] flex flex-wrap">

                        <div className="w-full lg:w-[615px] h-full bg-white sm:px-10 px-4 py-6 order-2 lg:order-1 -mt-4 rounded-t-xl">
                            <div>
                                <div className="flex flex-row justify-between items-center gap-0 mb-4 md:mb-6 bg-white rounded">
                                    <button
                                        onClick={() => handleTabChange("All")}
                                        class={`text-base sm:text-xl py-2  ${selectedFilter === "All" ? "text-blue font-bold" : "text-dark font-medium"
                                            }`}>
                                        All
                                    </button>
                                    <button
                                        onClick={() => handleTabChange("Practitioners")}
                                        class={`text-base sm:text-xl py-2  ${selectedFilter === "Practitioners" ? "text-blue font-bold" : "text-dark font-medium"
                                            }`}>
                                        Practitioners
                                    </button>
                                    <button
                                        onClick={() => handleTabChange("Practices")}
                                        class={`text-base sm:text-xl py-2  ${selectedFilter === "Practices" ? "text-blue font-bold" : "text-dark font-medium"
                                            }`}>
                                        Practices
                                    </button>
                                </div>
                                <div className="flex gap-4 flex-wrap">
                                    {/* <div className="dropdown">
                                        <button type="button" className="px-5 lg:px-7 py-1.5 bg-transparent text-dark border border-dark text-xs font-bold rounded border-solid flex gap-2 items-center" aria-controls="disclosure-1" aria-expanded="true">
                                            Sort By
                                            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9.10974 10.7388L14.3684 5.48022L15.8709 6.98269L9.10974 13.7439L2.34866 6.98269L3.85113 5.48022L9.10974 10.7388Z" fill="#393939" />
                                            </svg>
                                        </button>
                                        <div className="dropdownContent hidden absolute bg-white shadow-md rounded-lg z-10 py-2" id="disclosure-1">
                                            <a href="index.html" className="block rounded-lg py-2 px-3 font-normal text-sm text-gray-900 hover:bg-gray-50">Physiotherapist </a>
                                            <a href="#" className="block rounded-lg py-2 px-3 font-normal text-sm text-gray-900 hover:bg-gray-50">Clinic</a>
                                            <a href="#" className="block rounded-lg py-2 px-3 font-normal text-sm text-gray-900 hover:bg-gray-50">low experience</a>
                                            <a href="#" className="block rounded-lg py-2 px-3 font-normal text-sm text-gray-900 hover:bg-gray-50">High experience</a>
                                        </div>
                                    </div> */}
                                    <Link to={'/list'} className="px-5 lg:px-7 py-1.5 bg-transparent text-dark border border-dark text-xs font-bold rounded border-solid flex items-center justify-center">List
                                    </Link>
                                    <Link to={'/map'} className="px-5 lg:px-7 py-1.5 bg-blue text-white border border-blue text-xs font-bold rounded border-solid flex items-center justify-center">Map
                                    </Link>

                                </div>
                            </div>
                            <div className="h-[calc(100%-85px)] overflow-y-auto -mx-4 sm:-mx-10 mt-4 no-scrollbar">
                                <div>
                                    {paginatedData?.length > 0 ? paginatedData?.map((item, index) => (
                                        <PracticeCardMap key={index} item={item} />
                                    ))
                                        :
                                        <p className="text-center my-2">No practices found.</p>}
                                </div>

                                {paginatedData?.length > 0 && (
                                    <div className="px-3 py-3 border-t-0 border-solid border-[#dfdfdf]">
                                        <ul className="flex items-center -space-x-px h-10 text-base justify-end">
                                            {/* Previous Button */}
                                            <li>
                                                <button
                                                    onClick={() => handlePageChange(currentPage - 1)}
                                                    disabled={currentPage === 1}
                                                    className={`flex items-center justify-center px-4 h-10 ms-0 leading-tight border border-e-0 rounded-s-lg border-[#73aeff] 
          ${currentPage === 1 ? "text-gray-400 bg-gray-200 cursor-not-allowed" : "text-blue bg-gray-200 hover:bg-gray-100"}`}
                                                >
                                                    <span className="sr-only">Previous</span>
                                                    <svg className="w-3 h-3 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 1 1 5l4 4"></path>
                                                    </svg>
                                                </button>
                                            </li>

                                            {/* Dynamic Pagination Logic */}
                                            {Array.from({ length: totalPages }, (_, i) => i + 1)
                                                .filter((page) => {
                                                    return (
                                                        page === 1 ||
                                                        page === totalPages ||
                                                        (page >= currentPage - 1 && page <= currentPage + 1)
                                                    );
                                                })
                                                .reduce((acc, page, index, arr) => {
                                                    if (index > 0 && page !== arr[index - 1] + 1) {
                                                        acc.push("...");
                                                    }
                                                    acc.push(page);
                                                    return acc;
                                                }, [])
                                                .map((page, index) => (
                                                    <li key={index}>
                                                        {page === "..." ? (
                                                            <span className={`flex items-center justify-center px-4 h-10 leading-tight border border-[#73aeff] bg-gray-200 text-blue`}>...</span>
                                                        ) : (
                                                            <button
                                                                onClick={() => handlePageChange(page)}
                                                                className={`flex items-center justify-center px-4 h-10 leading-tight border border-[#73aeff] 
                ${currentPage === page ? "bg-white text-blue" : "bg-gray-200 text-blue hover:bg-gray-100"}`}
                                                            >
                                                                {page}
                                                            </button>
                                                        )}
                                                    </li>
                                                ))}

                                            {/* Next Button */}
                                            <li>
                                                <button
                                                    onClick={() => handlePageChange(currentPage + 1)}
                                                    disabled={currentPage === totalPages}
                                                    className={`flex items-center justify-center px-4 h-10 leading-tight border rounded-e-lg border-[#73aeff] 
          ${currentPage === totalPages ? "text-gray-400 bg-gray-200 cursor-not-allowed" : "text-blue bg-gray-200 hover:bg-gray-100"}`}
                                                >
                                                    <span className="sr-only">Next</span>
                                                    <svg className="w-3 h-3 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4"></path>
                                                    </svg>
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                )}

                            </div>


                        </div>
                        <div className="w-full h-[60vh] lg:h-full lg:w-[calc(100%-615px)] order-1 lg:order-2">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d35665.059808627455!2d144.9779938282856!3d-37.80466795319804!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad642eec9b6b09f%3A0x32a024e3e4920223!2s32%20George%20St%2C%20East%20Melbourne%20VIC%203002%2C%20Australia!5e0!3m2!1sen!2sin!4v1737115625558!5m2!1sen!2sin" width="100%" height="100%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </section>
            </FrontendLayout>
        </>)
}

export default MapPage