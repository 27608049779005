import React, { useState } from "react";
import { API_BASE_URL } from "../../../../api/api";
import { Link } from "react-router-dom";

const PracticeCardMap = ({ item }) => {

    return (
        <>
            <div className="card-practitioners sm:px-10 px-4 py-6">
                <div className="w-full flex flex-wrap gap-y-3 listcardwrapper">
                    <div className="w-[100px] sm:w-[140px] imgcard">
                        <a href="#">
                            <img src={item.image ? API_BASE_URL + item.image : null} alt="" className="w-full rounded-lg aspect-square object-cover mb-1.5" />
                        </a>
                        {/* <button className=" py-2 bg-transparent text-dark border border-dark text-xs sm:text-base w-full flex items-center justify-center font-semibold rounded border-solid max-w-[130px] mx-auto">Add Logo</button> */}
                    </div>
                    <div className="w-[calc(100%-100px)] sm:w-[calc(100%-140px)] pl-3 sm:pl-6 contentcard">
                        <div className="">
                            <a href="#" className="text-base sm:text-lg block w-fit font-semibold text-gray-800 mb-1 sm:mb-2 ">
                                <span>{item?.practice_name}</span>
                                {item?.is_verified == 1
                                    &&
                                    <span className="float-right mt-1 pl-2">
                                        <img src="/assets/image/verifiedicon.svg" alt="" srcSet="" />
                                    </span>
                                }
                            </a>


                            {item?.is_practitioner == 1 &&
                                <div className="flex items-center flex-wrap gap-x-3 gap-y-1 mb-2 sm:mb-3">
                                    <span className="text-green font-medium text-xs">{item?.services?.map(service => service.name).join(", ")}</span>
                                </div>
                            }

                            {/*  <div className="flex items-center flex-wrap gap-x-3 gap-y-1 mb-2 sm:mb-3">
                                 <span className="flex items-center text-green text-xs font-medium">
                                    <img src="/assets/image/star.svg" alt="" srcSet="" className="h-[18px] w-[18px]" />
                                    <img src="/assets/image/star.svg" alt="" srcSet="" className="h-[18px] w-[18px]" />
                                    <img src="/assets/image/star.svg" alt="" srcSet="" className="h-[18px] w-[18px]" />
                                    <img src="/assets/image/star.svg" alt="" srcSet="" className="h-[18px] w-[18px]" />
                                    <img src="/assets/image/halfstar.svg" alt="" srcSet="" className="h-[18px] w-[18px]" />
                                    <span className="mx-1 text-dark">(4.5)</span>
                                    21 Reviews
                                </span> 
                            </div>*/}
                            
                            <Link
                                to={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(item?.address + ', ' + item?.suburb?.name + ', ' + item?.state?.name)}`}
                                target="_blank" rel="noopener noreferrer"
                                className="flex gap-x-2  gap-y-1 sm:items-center mb-2 sm:mb-3">
                                <svg width="24" className="w-5 h-5 mt-1 sm:mt-0" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18.364 17.364L12 23.7279L5.63604 17.364C2.12132 13.8492 2.12132 8.15076 5.63604 4.63604C9.15076 1.12132 14.8492 1.12132 18.364 4.63604C21.8787 8.15076 21.8787 13.8492 18.364 17.364ZM12 13C13.1046 13 14 12.1046 14 11C14 9.89543 13.1046 9 12 9C10.8954 9 10 9.89543 10 11C10 12.1046 10.8954 13 12 13Z" fill="#0052CC" />
                                </svg>
                                <span className="text-dark text-xs font-medium underline underline-offset-[2px] w-[calc(100%-24px)] sm:w-[calc(100%-36px)]">32 George Street, Melbourne, VIC 3004</span>
                            </Link>
                            <p className="text-xs text-dark font-medium mb-2">
                                {item?.short_description?.length > 100
                                    ? `${item.short_description.slice(0, 100)}...`
                                    : item.short_description}
                            </p>
                            {/* <div className="flex flex-wrap gap-3">
                                <a href="#">
                                    <img src="/assets/image/Image-placeholder.png" className="h-12 w-12 object-cover object-center rounded-lg" alt="" />
                                </a>
                                <a href="#">
                                    <img src="/assets/image/Image-placeholder.png" className="h-12 w-12 object-cover object-center rounded-lg" alt="" />
                                </a>
                                <a href="#">
                                    <img src="/assets/image/Image-placeholder.png" className="h-12 w-12 object-cover object-center rounded-lg" alt="" />
                                </a>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <hr />
        </>
    )
};

export default PracticeCardMap;
