import CryptoJS from "crypto-js";

const SECRET_KEY = "F31E1A58F527FBDF6A65779BED1EF"; 

export const encryptData = (data) => {
    try {
      const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString();
      
      return urlSafeBase64Encode(encrypted);
    } catch (error) {
      console.error("Error encrypting data:", error);
      return null;
    }
  };
  
  export const decryptData = (encodedData) => {
    try {
      const decoded = urlSafeBase64Decode(encodedData);
      
      const bytes = CryptoJS.AES.decrypt(decoded, SECRET_KEY);
      const decrypted = JSON.parse(bytes.toString(CryptoJS.enc.Utf8)); 
      return decrypted;
    } catch (error) {
      console.error("Error decrypting data:", error);
      return null;
    }
  };
  
  const urlSafeBase64Encode = (str) => {
    return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(str))
      .replace(/\+/g, '-') 
      .replace(/\//g, '_') 
      .replace(/=+$/, ''); 
  };
  
  const urlSafeBase64Decode = (str) => {
    const padding = '='.repeat((4 - str.length % 4) % 4); 
    const base64Str = str.replace(/-/g, '+').replace(/_/g, '/') + padding;
    return CryptoJS.enc.Base64.parse(base64Str).toString(CryptoJS.enc.Utf8);
  };