import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import AdminLayout from "../../layouts/AdminLayout";
import api, { API_BASE_URL, fetchData } from "../../api/api";
import Spinner from "../../components/common/Spinner";
import { AuthContext } from "../../context/AuthContext";
import { encryptData } from "../../utils/encryptDecrypt";
import { toast } from "react-toastify";
import { CONSTANT_REDIRECT_TIME } from "../../constant/common";


const AdminHomePage = () => {

    const { user } = useContext(AuthContext);

    const didFetch = useRef(false);
    const [isLoading, setIsLoading] = useState(false);

    const [practicesList, setPracticesList] = useState([]);

    const [isDelete, setIsDelete] = useState(false);

    const [selectedPractices, setSelectedPractices] = useState(null);

    const [openIndex, setOpenIndex] = useState(null);

    const [currentPage, setCurrentPage] = useState(1);

    const toggleRow = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    const getPractices = async () => {

        setIsLoading(true);
        try {
            const data = await fetchData('/get-practices', { fk_users_id: user?.users_id });
            setPracticesList(data);
            setCurrentPage(1);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (!didFetch.current) {
            getPractices();
            didFetch.current = true;
        }
    }, []);

    if (isLoading) {
        return (<Spinner adminLayout={true} />);
    }

    const itemsPerPage = 10;

    const totalPages = Math.ceil(practicesList.length / itemsPerPage);

    const paginatedData = practicesList?.length > 0 ? practicesList.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    ) : [];

    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    const handleDelete = async () => {

        const payload = {
            practices_id: selectedPractices?.practices_id
        };

        try {
            const response = await api.post("delete-practices", payload);

            if (response?.data?.status === true) {
                toast.success(response.data?.message);

                setTimeout(() => {
                    getPractices();
                }, CONSTANT_REDIRECT_TIME);


            } else {
                toast.error(response?.data?.message || "Failed to delete practice.");
                return null;
            }
        } catch (error) {
            console.error("Error delete practice:", error);
            toast.error("Something went wrong. Please try again.");
            return null;
        }

        setSelectedPractices(null);
        setIsDelete(false);
    }

    return (
        <>
            <AdminLayout>
                <main>
                    <div className="px-4 py-4 sm:px-8 sm:py-6 md:px-12 md:py-7 flex justify-between gap-4 items-center">
                        <h1 className="font-semibold text-lg sm:text-2xl">Practice & Practitioners Listing</h1>
                        <Link to={'/admin/practices-add'} className="py-3 px-4 sm:px-8 border-green  border-2 border-solid bg-green text-white rounded-[8px] font-medium sm:font-semibold text-sm sm:text-base leading-none text-center text-nowrap">+ Add Listing</Link>
                    </div>
                    <div className="overflow-x-auto">
                        <table className="w-full">
                            <thead className="bg-lightblue">
                                <tr>
                                    <th className="py-2 px-1.5 sm:py-4 sm:px-3 text-base text-blue font-semibold text-start w-[54px]"></th>
                                    <th className="py-2 px-1.5 sm:py-4 sm:px-3 text-base text-blue font-semibold text-start min-w-[67px] sm:min-w-[90px] w-[90px]">Photo</th>
                                    <th className="py-2 px-1.5 sm:py-4 sm:px-3 text-base text-blue font-semibold text-start min-w-48">Practice Name</th>
                                    <th className="py-2 px-1.5 sm:py-4 sm:px-3 text-base text-blue font-semibold text-start min-w-48">Category</th>
                                    <th className="py-2 px-1.5 sm:py-4 sm:px-3 text-base text-blue font-semibold text-start min-w-48">Address</th>
                                    <th className="py-2 px-1.5 sm:py-4 sm:px-3 text-base text-blue font-semibold text-start min-w-[90px] sm:w-[134px]">Listing Plan</th>
                                    <th className="py-2 px-1.5 sm:py-4 sm:px-3 text-base text-blue font-semibold text-start min-w-[90px] sm:w-[134px]">Reviews</th>
                                    <th className="py-2 px-1.5 sm:py-4 sm:px-3 text-base text-blue font-semibold text-start min-w-[90px] sm:w-[134px]">Enquiries</th>
                                    <th className="py-2 px-1.5 sm:py-4 sm:px-3 text-base text-blue font-semibold text-start ">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {paginatedData?.length > 0 ? paginatedData?.map((practice, index) => (
                                    <React.Fragment key={index}>
                                        <tr key={index * 2} className="Practice-table-row">
                                            <td className="py-2 px-1.5 sm:py-4 sm:px-3 border-t-2 border-solid border-[#dfdfdf]">
                                                <button onClick={() => toggleRow(index)}
                                                    className={`flex items-center justify-center w-full practice-table-btn ${openIndex === index ? 'active' : ''}`}>
                                                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" className="dropicon">
                                                        <path d="M15.3136 17.744L26.4952 6.5625L29.6899 9.75722L15.3136 24.1335L0.9375 9.75722L4.1322 6.5625L15.3136 17.744Z" fill="#0052CC" />
                                                    </svg>
                                                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" className="plus">
                                                        <path d="M1.66667 0H28.3333C29.2538 0 30 0.7462 30 1.66667V28.3333C30 29.2538 29.2538 30 28.3333 30H1.66667C0.7462 30 0 29.2538 0 28.3333V1.66667C0 0.7462 0.7462 0 1.66667 0ZM13.3333 13.3333H6.66667V16.6667H13.3333V23.3333H16.6667V16.6667H23.3333V13.3333H16.6667V6.66667H13.3333V13.3333Z" fill="#0052CC" />
                                                    </svg>
                                                </button>
                                            </td>
                                            <td className="py-2 px-1.5 sm:py-4 sm:px-3 border-t-2 border-solid border-[#dfdfdf]">
                                                <img src={practice.image ? API_BASE_URL + practice.image : null} alt="" srcSet="" className="h-[50px] w-[50px] sm:h-[70px] sm:w-[70px] rounded" />
                                            </td>
                                            <td className="py-2 px-1.5 sm:py-4 sm:px-3 border-t-2 border-solid border-[#dfdfdf]">
                                                <Link to={`/admin/practices-details/${encryptData(practice?.practices_id)}`} className="text-blue text-base font-medium">{practice?.practice_name}</Link>
                                            </td>
                                            <td className="py-2 px-1.5 sm:py-4 sm:px-3 border-t-2 border-solid border-[#dfdfdf]">
                                                <span className="text-dark text-base font-medium">
                                                    {(() => {
                                                        const serviceNames = practice?.services?.map(service => service.name).join(", ");
                                                        return serviceNames.length > 50 ? serviceNames.slice(0, 50) + "..." : serviceNames;
                                                    })()}
                                                </span>
                                            </td>
                                            <td className="py-2 px-1.5 sm:py-4 sm:px-3 border-t-2 border-solid border-[#dfdfdf]">
                                                <Link
                                                    // to={`https://www.google.com/maps?q=${practice?.suburb?.latitude},${practice?.suburb?.longitude}`} 
                                                    to={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(practice?.address + ', ' + practice?.suburb?.name + ', ' + practice?.state?.name)}`}
                                                    target="_blank" rel="noopener noreferrer"
                                                    className="text-dark text-base font-medium underline underline-offset-2">
                                                    {practice?.address}, {practice?.suburb?.name}, {practice?.state?.name}, {practice?.postcode?.postcode}
                                                </Link>
                                            </td>
                                            <td className="py-2 px-1.5 sm:py-4 sm:px-3 border-t-2 border-solid border-[#dfdfdf]">
                                                <span className="text-center text-base font-medium text-dark rounded-full block p-1 bg-[#FBFB87] max-w-[110px] w-full">{practice?.pricing_plan?.name}</span>
                                            </td>
                                            <td className="py-2 px-1.5 sm:py-4 sm:px-3 border-t-2 border-solid border-[#dfdfdf]">
                                                <a href="#" className="text-center text-base font-medium text-dark rounded-full block p-1 bg-[#FBFB87] max-w-[110px] w-full">0</a>
                                            </td>
                                            <td className="py-2 px-1.5 sm:py-4 sm:px-3 border-t-2 border-solid border-[#dfdfdf]">
                                                <a href="#" className="text-center text-base font-medium text-dark rounded-full block p-1 bg-[#FBFB87] max-w-[110px] w-full">0</a>
                                            </td>
                                            <td className="py-2 px-1.5 sm:py-4 sm:px-3 border-t-2 border-solid border-[#dfdfdf]">
                                                <div className="flex rounded-full shadow-xs bg-blue max-w-[144px] w-full" role="group">
                                                    <Link
                                                        to={`/admin/practices-practitioners-add/${encryptData(practice?.practices_id)}`}
                                                        className="pl-5 pr-2 py-2 text-sm font-medium  bg-transparent rounded-s-full" title="Add Practitioner">
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M16.794 12.0391C16.3712 11.8381 15.9399 11.665 15.5009 11.52C16.9225 10.453 17.8437 8.75393 17.8437 6.84375C17.8437 3.62152 15.2223 1 12 1C8.77771 1 6.15623 3.62152 6.15623 6.84375C6.15623 8.75603 7.07955 10.4567 8.50375 11.5235C7.19887 11.9531 5.97448 12.6216 4.90284 13.503C2.93779 15.1194 1.57151 17.3739 1.05576 19.8514C0.894108 20.6277 1.08777 21.425 1.58698 22.0388C2.08374 22.6497 2.82035 23 3.60789 23H14.1914C14.666 23 15.0508 22.6153 15.0508 22.1406C15.0508 21.666 14.666 21.2812 14.1914 21.2812H3.60789C3.24102 21.2812 3.01969 21.0765 2.92043 20.9544C2.74903 20.7437 2.68268 20.4693 2.73841 20.2017C3.63023 15.9179 7.40873 12.7886 11.7722 12.6831C11.8477 12.686 11.9237 12.6875 12 12.6875C12.077 12.6875 12.1537 12.686 12.23 12.683C13.5672 12.7144 14.8533 13.0196 16.0561 13.5914C16.4848 13.7951 16.9975 13.6128 17.2012 13.1841C17.405 12.7555 17.2227 12.2428 16.794 12.0391ZM12.2096 10.9635C12.1398 10.9622 12.07 10.9616 12 10.9616C11.9306 10.9616 11.8613 10.9623 11.792 10.9636C9.61371 10.8549 7.87498 9.04856 7.87498 6.84375C7.87498 4.5692 9.72543 2.71875 12 2.71875C14.2745 2.71875 16.125 4.5692 16.125 6.84375C16.125 9.048 14.3871 10.854 12.2096 10.9635Z" fill="white" />
                                                            <path d="M22.1406 18.0586H19.7773V15.6953C19.7773 15.2207 19.3926 14.8359 18.918 14.8359C18.4433 14.8359 18.0586 15.2207 18.0586 15.6953V18.0586H15.6953C15.2207 18.0586 14.8359 18.4433 14.8359 18.918C14.8359 19.3926 15.2207 19.7773 15.6953 19.7773H18.0586V22.1406C18.0586 22.6153 18.4433 23 18.918 23C19.3926 23 19.7773 22.6153 19.7773 22.1406V19.7773H22.1406C22.6153 19.7773 23 19.3926 23 18.918C23 18.4433 22.6153 18.0586 22.1406 18.0586Z" fill="white" />
                                                        </svg>
                                                    </Link>
                                                    <Link to={`/admin/practices-edit/${encryptData(practice?.practices_id)}`} className="px-2 py-2 text-sm font-medium  bg-transparent rounded-s-full">
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M5 18.89H6.41421L15.7279 9.57628L14.3137 8.16207L5 17.4758V18.89ZM21 20.89H3V16.6473L16.435 3.21232C16.8256 2.8218 17.4587 2.8218 17.8492 3.21232L20.6777 6.04075C21.0682 6.43127 21.0682 7.06444 20.6777 7.45496L9.24264 18.89H21V20.89ZM15.7279 6.74786L17.1421 8.16207L18.5563 6.74786L17.1421 5.33364L15.7279 6.74786Z" fill="white" />
                                                        </svg>
                                                    </Link>
                                                    <button
                                                        onClick={() => {
                                                            setIsDelete(true)
                                                            setSelectedPractices(practice)
                                                        }}
                                                        type="button" className="pl-2 pr-5 py-2 text-sm font-medium bg-transparent rounded-e-full deletebtn">
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M7 4V2H17V4H22V6H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V6H2V4H7ZM6 6V20H18V6H6ZM9 9H11V17H9V9ZM13 9H15V17H13V9Z" fill="white" />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr key={(index * 2) + 1} className={`practitioner-table-row ${openIndex === index ? "" : "hidden"}`}>
                                            <td className="px-3"></td>
                                            <td className="px-3" colSpan="8">
                                                <div>
                                                    <table className="w-full">
                                                        <thead className="bg-[#f8f8f8] border-b-2 border-green border-solid">
                                                            <tr>
                                                                <th className="py-2 px-1.5 sm:py-4 sm:px-3 text-base text-dark font-semibold text-start w-[90px]">Photo</th>
                                                                <th className="py-2 px-1.5 sm:py-4 sm:px-3 text-base text-dark font-semibold text-start ">Practitioner Name</th>
                                                                <th className="py-2 px-1.5 sm:py-4 sm:px-3 text-base text-dark font-semibold text-start">Category</th>
                                                                <th className="py-2 px-1.5 sm:py-4 sm:px-3 text-base text-dark font-semibold text-start min-w-[90px] sm:w-[134px]">Reviews</th>
                                                                <th className="py-2 px-1.5 sm:py-4 sm:px-3 text-base text-dark font-semibold text-start min-w-[90px] sm:w-[134px]">Enquiries</th>
                                                                <th className="py-2 px-1.5 sm:py-4 sm:px-3 text-base text-dark font-semibold text-start ">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {practice?.practitioners?.length > 0 ?
                                                                <>
                                                                    {practice?.practitioners?.map((practitioner, practitionerIndex) => (
                                                                        <tr key={practitionerIndex}>
                                                                            <td className="py-2 px-1.5 sm:py-4 sm:px-3">
                                                                                <img src={practitioner.image ? API_BASE_URL + practitioner.image : null} alt="" srcSet="" className="h-[50px] w-[50px] sm:h-[70px] sm:w-[70px] rounded" />
                                                                            </td>
                                                                            <td className="py-2 px-1.5 sm:py-4 sm:px-3">
                                                                                <Link to={`/admin/practices-practitioners-details/${encryptData(practitioner?.practices_id)}`} className="text-blue text-base font-medium">{practitioner?.practice_name}</Link>
                                                                            </td>
                                                                            <td className="py-2 px-1.5 sm:py-4 sm:px-3">
                                                                                <span className="text-dark text-base font-medium">
                                                                                    {(() => {
                                                                                        const serviceNames = practitioner?.services?.map(service => service.name).join(", ");
                                                                                        return serviceNames.length > 50 ? serviceNames.slice(0, 50) + "..." : serviceNames;
                                                                                    })()}
                                                                                </span>
                                                                            </td>
                                                                            <td className="py-2 px-1.5 sm:py-4 sm:px-3">
                                                                                <a href="#" className="text-center text-base font-medium text-dark rounded-full block p-1 bg-[#FBFB87] max-w-[110px] w-full">0</a>
                                                                            </td>
                                                                            <td className="py-2 px-1.5 sm:py-4 sm:px-3">
                                                                                <a href="#" className="text-center text-base font-medium text-dark rounded-full block p-1 bg-[#FBFB87] max-w-[110px] w-full">0</a>
                                                                            </td>
                                                                            <td className="py-2 px-1.5 sm:py-4 sm:px-3">
                                                                                <div className="flex rounded-full shadow-xs bg-lightblue max-w-[120px] w-full" role="group">
                                                                                    <Link to={`/admin/practices-practitioners-edit/${encryptData(practitioner?.practices_id)}`} className="pl-6 pr-3 py-2 text-sm font-medium  bg-transparent rounded-s-full">
                                                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                            <path d="M5 18.89H6.41421L15.7279 9.57628L14.3137 8.16207L5 17.4758V18.89ZM21 20.89H3V16.6473L16.435 3.21232C16.8256 2.8218 17.4587 2.8218 17.8492 3.21232L20.6777 6.04075C21.0682 6.43127 21.0682 7.06444 20.6777 7.45496L9.24264 18.89H21V20.89ZM15.7279 6.74786L17.1421 8.16207L18.5563 6.74786L17.1421 5.33364L15.7279 6.74786Z" fill="#393939" />
                                                                                        </svg>
                                                                                    </Link>
                                                                                    <button
                                                                                        onClick={() => {
                                                                                            setIsDelete(true)
                                                                                            setSelectedPractices(practitioner)
                                                                                        }}
                                                                                        type="button" className="pl-3 pr-6 py-2 text-sm font-medium  bg-transparent rounded-e-full deletebtn">
                                                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                            <path d="M7 4V2H17V4H22V6H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V6H2V4H7ZM6 6V20H18V6H6ZM9 9H11V17H9V9ZM13 9H15V17H13V9Z" fill="#393939" />
                                                                                        </svg>
                                                                                    </button>
                                                                                </div>
                                                                            </td>
                                                                        </tr>

                                                                    ))}
                                                                </>
                                                                :
                                                                (<tr className="Practice-table-row"><td colSpan={6} className="py-2 px-1.5 sm:py-4 sm:px-3 text-center">No Practitioners Found</td></tr>)
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                )) : (<tr className="Practice-table-row"><td colSpan={9} className="text-center py-2 px-1.5 sm:py-4 sm:px-3 border-y-2 border-solid border-[#dfdfdf]">No Practices Found</td></tr>)}
                            </tbody>
                        </table>

                    </div>

                    {paginatedData?.length > 0 && (
                        <div className="px-3 py-3 border-t-2 border-solid border-[#dfdfdf]">
                            <ul className="flex items-center -space-x-px h-10 text-base justify-end">
                                {/* Previous Button */}
                                <li>
                                    <button
                                        onClick={() => handlePageChange(currentPage - 1)}
                                        disabled={currentPage === 1}
                                        className={`flex items-center justify-center px-4 h-10 ms-0 leading-tight border border-e-0 rounded-s-lg border-[#73aeff] 
          ${currentPage === 1 ? "text-gray-400 bg-gray-200 cursor-not-allowed" : "text-blue bg-gray-200 hover:bg-gray-100"}`}
                                    >
                                        <span className="sr-only">Previous</span>
                                        <svg className="w-3 h-3 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 1 1 5l4 4"></path>
                                        </svg>
                                    </button>
                                </li>

                                {/* Dynamic Pagination Logic */}
                                {Array.from({ length: totalPages }, (_, i) => i + 1)
                                    .filter((page) => {
                                        return (
                                            page === 1 ||
                                            page === totalPages ||
                                            (page >= currentPage - 1 && page <= currentPage + 1)
                                        );
                                    })
                                    .reduce((acc, page, index, arr) => {
                                        if (index > 0 && page !== arr[index - 1] + 1) {
                                            acc.push("...");
                                        }
                                        acc.push(page);
                                        return acc;
                                    }, [])
                                    .map((page, index) => (
                                        <li key={index}>
                                            {page === "..." ? (
                                                <span className={`flex items-center justify-center px-4 h-10 leading-tight border border-[#73aeff] bg-gray-200 text-blue`}>...</span>
                                            ) : (
                                                <button
                                                    onClick={() => handlePageChange(page)}
                                                    className={`flex items-center justify-center px-4 h-10 leading-tight border border-[#73aeff] 
                ${currentPage === page ? "bg-white text-blue" : "bg-gray-200 text-blue hover:bg-gray-100"}`}
                                                >
                                                    {page}
                                                </button>
                                            )}
                                        </li>
                                    ))}

                                {/* Next Button */}
                                <li>
                                    <button
                                        onClick={() => handlePageChange(currentPage + 1)}
                                        disabled={currentPage === totalPages}
                                        className={`flex items-center justify-center px-4 h-10 leading-tight border rounded-e-lg border-[#73aeff] 
          ${currentPage === totalPages ? "text-gray-400 bg-gray-200 cursor-not-allowed" : "text-blue bg-gray-200 hover:bg-gray-100"}`}
                                    >
                                        <span className="sr-only">Next</span>
                                        <svg className="w-3 h-3 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4"></path>
                                        </svg>
                                    </button>
                                </li>
                            </ul>
                        </div>
                    )}

                    <div id="deletepopup" className={`fixed bg-[#0000007A] top-0 left-0 h-screen w-full z-[999] justify-center items-center px-3 ${isDelete ? 'flex' : 'hidden'}`}>
                        <div className="max-w-[630px] w-full mx-auto bg-white rounded-xl sm:rounded-2xl px-3 py-6 sm:py-10 sm:px-10 lg:px-20 innercard">
                            <div className="max-w-[312px] w-full flex justify-center items-center gap-6 flex-col mx-auto">
                                <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M36 66C19.4314 66 6 52.5684 6 36C6 19.4314 19.4314 6 36 6C52.5684 6 66 19.4314 66 36C66 52.5684 52.5684 66 36 66ZM36 60C49.2549 60 60 49.2549 60 36C60 22.7452 49.2549 12 36 12C22.7452 12 12 22.7452 12 36C12 49.2549 22.7452 60 36 60ZM36 31.7574L44.4852 23.2721L48.7278 27.5147L40.2426 36L48.7278 44.4852L44.4852 48.7278L36 40.2426L27.5147 48.7278L23.2721 44.4852L31.7574 36L23.2721 27.5147L27.5147 23.2721L36 31.7574Z" fill="#FF5252" />
                                </svg>
                                <h4 className="text-center font-bold text-xl mg:text-2xl text-blue">Are you sure! <br />you want to Delete the Listing?</h4>
                                <div className="flex justify-between gap-3 w-full">
                                    <button
                                        type="button"
                                        onClick={() => {
                                            setIsDelete(false)
                                            setSelectedPractices(null)
                                        }}
                                        className="bg-transparent text-center text-dark text-sm sm:text-base font-semibold sm:w-full sm:max-w-[154px] rounded-lg py-2 px-3 sm:py-3 sm:px-0 flex items-center justify-center border border-dark cancelbtn">Cancel</button>
                                    <button
                                        type="button"
                                        onClick={handleDelete}
                                        className="bg-blue text-center text-white text-sm sm:text-base font-semibold sm:w-full sm:max-w-[154px] rounded-lg py-2 px-3 sm:py-3 sm:px-0 flex items-center justify-center border border-blue deletebtn">Delete</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </AdminLayout>
        </>)
}

export default AdminHomePage