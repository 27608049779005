import React from "react";
import FrontendLayout from "../../layouts/FrontendLayout";
const TermConditionsPage = () => {

  return (
    <>
      <FrontendLayout>
        <section>
          <div className="container mx-auto px-3">
            <h1 className="text-[28px] leading-relaxed sm:text-[39px] sm:leading-[59px] text-dark font-bold text-center my-6 sm:my-8 md:my-12 lg:my-16 xl:my-20">Terms & Conditions</h1>
            <div className="grid grid-cols-12 gap-[30px] mb-[60px] md:mb-[100px]">
              <div className="col-span-12">
                <h2 className="text-dark text-[22px] sm:text-2xl leading-relaxed font-semibold max-w-[587px] w-full mb-3">Overview</h2>
                <p className="font-medium text-dark text-base text-justify mb-0">By using our website (AlliedHealth.com.au) to connect with allied health practitioners, you agree to the following terms and conditions: </p>
              </div>
              <div className="col-span-12 md:col-span-8">
                <ul className="list-disc pl-4">
                  <li className="text-dark text-xl leading-relaxed font-semibold mb-5" id="userOfService">Use of Service
                    <dd className="font-medium text-dark text-base text-justify md:mb-6 -ml-5 mt-3">Our platform provides a directory of allied health professionals for informational purposes only. We do not guarantee the availability, qualifications, or treatment outcomes of the practitioners listed.</dd>
                  </li>
                  <li className="text-dark text-xl leading-relaxed font-semibold mb-5" id="bookingAppointments">Booking Appointments
                    <dd className="font-medium text-dark text-base text-justify md:mb-6 -ml-5 mt-3">Appointments with allied health practitioners are booked directly through their profiles or via our booking system. You are responsible for ensuring that the information provided during the booking process is accurate and up-to-date.</dd>
                  </li>
                  <li className="text-dark text-xl leading-relaxed font-semibold mb-5" id="noMedicalAdvice">No Medical Advice
                    <dd className="font-medium text-dark text-base text-justify md:mb-6 -ml-5 mt-3">The information available on AlliedHealth.com.au is not intended to replace professional medical advice, diagnosis, or treatment. Always consult with a qualified healthcare provider for advice regarding your health conditions.</dd>
                  </li>
                  <li className="text-dark text-xl leading-relaxed font-semibold mb-5" id="privacyAndDataSecurity">Privacy and Data Security
                    <dd className="font-medium text-dark text-base text-justify md:mb-6 -ml-5 mt-3">Your privacy is important to us. We collect and use your personal information as outlined in our Privacy Policy. By using our platform, you consent to the collection and use of your information in accordance with our policy.</dd>
                  </li>
                  <li className="text-dark text-xl leading-relaxed font-semibold mb-5" id="liability">Liability
                    <dd className="font-medium text-dark text-base text-justify md:mb-6 -ml-5 mt-3">AlliedHealth.com.au is not responsible for any actions, treatment, or outcomes provided by the allied health practitioners listed on the platform. You agree to use the services at your own risk and to hold AlliedHealth.com.au harmless from any liability arising from your interactions with the practitioners.</dd>
                  </li>
                  <li className="text-dark text-xl leading-relaxed font-semibold mb-5" id="changesToTerms">Changes to Terms
                    <dd className="font-medium text-dark text-base text-justify md:mb-6 -ml-5 mt-3">We may update these terms and conditions from time to time. Any changes will be posted on this page, and it is your responsibility to review them periodically.</dd>
                  </li>
                  <li className="text-dark text-xl leading-relaxed font-semibold mb-5" id="governingLaw">Governing Law
                    <dd className="font-medium text-dark text-base text-justify md:mb-6 -ml-5 mt-3">These terms are governed by the laws of NSW, Australia, and any disputes will be resolved in the appropriate legal forum.</dd>
                  </li>
                  <li className="text-dark text-base text-justify leading-relaxed font-medium  mb-5 ">Allied Health.com.au is not any AHPRA Registerested service provider. We are helping patients to find and compare the best Allied Health Practioners.
                  </li>
                  <li className="text-dark text-base text-justify leading-relaxed font-medium  mb-5">Practioners Details are provided by the Practices & Practioners. Allied Health.com.au has not verified these Details.
                  </li>
                </ul>
              </div>
              <div className="col-span-12 md:col-span-4">
                <div className="max-w-[367px] w-full ml-auto ">
                  <h2 className="text-dark text-[22px] sm:text-2xl leading-relaxed font-semibold max-w-[587px] w-full mb-6 md:mb-8 lg:mb-10">Table of Contents</h2>
                  <ul className="list-disc pl-4">
                    <li className="mb-5 md:mb-7">
                      <a href="#userOfService" className="text-dark text-lg font-medium underline">Use of Service</a>
                    </li>
                    <li className="mb-5 md:mb-7">
                      <a href="#bookingAppointments" className="text-dark text-lg font-medium underline">Booking Appointments</a>
                    </li>
                    <li className="mb-5 md:mb-7">
                      <a href="#noMedicalAdvice" className="text-dark text-lg font-medium underline">No Medical Advice</a>
                    </li>
                    <li className="mb-5 md:mb-7">
                      <a href="#privacyAndDataSecurity" className="text-dark text-lg font-medium underline">Privacy and Data Security</a>
                    </li>
                    <li className="mb-5 md:mb-7">
                      <a href="#liability" className="text-dark text-lg font-medium underline">Liability</a>
                    </li>
                    <li className="mb-5 md:mb-7">
                      <a href="#changesToTerms" className="text-dark text-lg font-medium underline">Changes to Terms</a>
                    </li>
                    <li className="mb-5 md:mb-7">
                      <a href="#governingLaw" className="text-dark text-lg font-medium underline">Governing Law</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

        </section>
      </FrontendLayout>
    </>
  );
};

export default TermConditionsPage;
