import React, { useContext, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";

const MasterAdminPrivateRoute = ({ children }) => {
  const { checkMasterAdminLogin } = useContext(AuthContext);

  useEffect(() => {
    checkMasterAdminLogin();
  }, [checkMasterAdminLogin]);


  return children;
};

export default MasterAdminPrivateRoute;
