// export const extractSlugDetails = (slug) => {

//     const parts = slug.split("-"); 

//     const postcode = parts[0];

//     const inIndex = parts.indexOf("in");

//     const services = parts.slice(1, inIndex).join("-"); 

//     const suburb = parts.slice(inIndex + 1).join("-");

//     return { postcode, services, suburb };
// };

export const extractSlugDetails = (slug) => {
    const parts = slug.split("-");

    const inIndex = parts.indexOf("in");

    if (inIndex === -1) {
        return { services: "", suburb: "" }; 
    }

    const services = parts.slice(0, inIndex).join("-");
    const suburb = parts.slice(inIndex + 1).join("-"); 

    return { services, suburb };
};
