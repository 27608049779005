import React, { useContext, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";

const PrivateRoute = ({ children }) => {
  const { checkUserLogin } = useContext(AuthContext);

  useEffect(() => {
    checkUserLogin();
  }, [checkUserLogin]);


  return children;
};

export default PrivateRoute;
