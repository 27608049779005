import React from "react";
import FrontendLayout from "../../layouts/FrontendLayout";
import AdminLayout from "../../layouts/AdminLayout";
import MasterAdminLayout from "../../layouts/MasterAdminLayout";

const Spinner = ({ adminLayout = false, masterAdminLayout = false }) => {
  if (masterAdminLayout) {
    return (
      <MasterAdminLayout>
        <div className="spinner-container">
          <div className="spinner"></div>
        </div>
      </MasterAdminLayout>
    );
  } else if (adminLayout) {
    return (
      <AdminLayout>
        <div className="spinner-container">
          <div className="spinner"></div>
        </div>
      </AdminLayout>
    );
  } else {
    return (
    <FrontendLayout>
      <div className="spinner-container">
        <div className="spinner"></div>
      </div>
    </FrontendLayout>
    );
  }
};

export default Spinner;
