import React, { useEffect, useRef, useState } from "react";
import FrontendLayout from "../../layouts/FrontendLayout";
import api, { API_BASE_URL, fetchData } from "../../api/api";
import Spinner from "../../components/common/Spinner";
import Select from "react-select";
import { toast } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";


const FindFormPage = () => {
    const navigate = useNavigate();

    const { service_slug } = useParams();

    const didFetch = useRef(false);

    const [isLoading, setIsLoading] = useState(false);

    const [services, setServices] = useState(false);

    const [suburbList, setSuburbList] = useState([]);
    const [selectedSuburb, setSelectedSuburb] = useState({
        value: null,
        label: null,
        slug: null,
        postcode: null,
        name: null,
    });
    const [filteredSuburbs, setFilteredSuburbs] = useState([]);
    const [inputValue, setInputValue] = useState("");
    const [typingTimeout, setTypingTimeout] = useState(null);

    const handleInputChange = (newValue) => {
        setInputValue(newValue);

        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }

        const timeout = setTimeout(() => {
            filterSuburbs(newValue);
        }, 300);

        setTypingTimeout(timeout);
    };

    const filterSuburbs = (searchText) => {
        if (searchText.length >= 2) {
            const filtered = suburbList
                .filter(item =>
                    item.name.toLowerCase().includes(searchText.toLowerCase()) ||
                    item.postcode.toString().includes(searchText)
                )
                .slice(0, 50);
            setFilteredSuburbs(filtered);
        } else {
            setFilteredSuburbs([]);
        }
    };

    const [formData, setFormData] = useState({
        fk_services_id: "",
        fk_practices_types_master_id: "",
        fk_postcodes_id: "",
        fk_suburbs_id: "",
        fk_practices_payment_methods_master_id: "",
        looking_gender: "",
        looking_for: "",
        name: "",
        email: "",
        phone: "",
        message: "",
        payment_methods_value: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const [currentPage, setCurrentPage] = useState(1);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await api.post("add-leads", formData);

            if (response?.data?.status === true) {
                toast.success(response.data?.message);
                changePage(7);

            } else {
                toast.error(response?.data?.message || "Failed ");
                return null;
            }
        } catch (error) {
            console.error("Error :", error);
            toast.error("Something went wrong. Please try again.");
            return null;
        }

    }

    const changePage = (nextPage) => {

        if (nextPage > 1) {
            if (!formData?.fk_practices_types_master_id) {
                toast.error("Please select practitioners type.")
                return '';
            }
        }

        if (nextPage > 2) {
            if (!formData?.fk_postcodes_id || !formData?.fk_suburbs_id) {
                toast.error("Please select postcode.")
                return '';
            }
        }

        if (nextPage > 3) {
            if (!formData?.looking_gender) {
                toast.error("Please select gender.")
                return '';
            }
        }

        if (nextPage > 4) {
            if (!formData?.fk_practices_payment_methods_master_id) {
                toast.error("Please select payment method.")
                return '';
            }
        }

        if (nextPage > 5) {
            if (!formData?.looking_for) {
                toast.error("Please select looking for.")
                return '';
            }
        }

        setCurrentPage(nextPage);

        window.scrollTo({ top: 0, behavior: "smooth" });

    }

    const getServices = async () => {
        try {
            const data = await fetchData('/get-services-by-slug', { slug: service_slug });
            if (data?.status === false && data?.error === true) {
                navigate('/find');
            }
            else {
                setServices(data);
                setFormData((prevState) => ({
                    ...prevState,
                    fk_services_id: data?.services_id
                }));
            }

        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {

        }
    };

    const getSuburb = async (fk_postcodes_id = null) => {
        try {
            const data = await fetchData('/get-suburbs', {
                status: 1,
                fk_postcodes_id: fk_postcodes_id
            });
            setSuburbList(data);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {

        }
    };

    useEffect(() => {
        if (!didFetch.current) {
            getServices();
            getSuburb();
            didFetch.current = true;
        }
    }, []);

    const handleOnClickGoToList = (e) => {
        const urlSlug = '/' + selectedSuburb?.postcode + '/' + services?.slug + '-in-' + selectedSuburb?.slug;
        navigate(urlSlug);
    }

    if (isLoading) {
        return (<Spinner />);
    }

    return (
        <>
            <FrontendLayout>
                {
                    currentPage <= 6 ?
                        <section className="pb-[60px]">
                            <div className="container mx-auto px-4 sm:px-3 py-8 sm:py-10 flex flex-wrap sm:flex-nowrap justify-center items-center gap-2">
                                <ol className="flex items-center w-[280px] sm:w-[400px] md:w-[576px]">
                                    <li className="flex w-full items-center after:content-[''] after:w-full after:h-1 after:bg-blue">
                                        <button
                                            onClick={() => changePage(1)}
                                            type="button" className="flex items-center justify-center w-7 h-7 sm:w-10 sm:h-10 bg-blue text-white rounded-full lg:h-12 lg:w-12 shrink-0">
                                            1
                                        </button>
                                    </li>
                                    <li class={`flex w-full items-center after:content-[''] after:w-full after:h-1 ${currentPage >= 2 ? 'after:bg-blue' : 'after:bg-lightblue'}`}>
                                        <button
                                            onClick={() => changePage(2)}
                                            type="button" class={`flex items-center justify-center w-7 h-7 sm:w-10 sm:h-10 rounded-full lg:h-12 lg:w-12 shrink-0 ${currentPage >= 2 ? 'bg-blue text-white' : 'bg-lightblue'}`}>
                                            2
                                        </button>
                                    </li>
                                    <li class={`flex w-full items-center after:content-[''] after:w-full after:h-1 ${currentPage >= 3 ? 'after:bg-blue' : 'after:bg-lightblue'}`}>
                                        <button
                                            onClick={() => changePage(3)}
                                            type="button" class={`flex items-center justify-center w-7 h-7 sm:w-10 sm:h-10 rounded-full lg:h-12 lg:w-12 shrink-0 ${currentPage >= 3 ? 'bg-blue text-white' : 'bg-lightblue'}`}>
                                            3
                                        </button>
                                    </li>
                                    <li class={`flex w-full items-center after:content-[''] after:w-full after:h-1 ${currentPage >= 4 ? 'after:bg-blue' : 'after:bg-lightblue'}`}>
                                        <button
                                            onClick={() => changePage(4)}
                                            type="button" class={`flex items-center justify-center w-7 h-7 sm:w-10 sm:h-10 rounded-full lg:h-12 lg:w-12 shrink-0 ${currentPage >= 4 ? 'bg-blue text-white' : 'bg-lightblue'}`}>
                                            4
                                        </button>
                                    </li>
                                    <li class={`flex w-full items-center after:content-[''] after:w-full after:h-1 ${currentPage >= 5 ? 'after:bg-blue' : 'after:bg-lightblue'}`}>
                                        <button
                                            onClick={() => changePage(5)}
                                            type="button" class={`flex items-center justify-center w-7 h-7 sm:w-10 sm:h-10 rounded-full lg:h-12 lg:w-12 shrink-0 ${currentPage >= 5 ? 'bg-blue text-white' : 'bg-lightblue'}`}>
                                            5
                                        </button>
                                    </li>
                                    <li className="flex items-center">
                                        <button
                                            onClick={() => changePage(6)}
                                            type="button" class={`flex items-center justify-center w-7 h-7 sm:w-10 sm:h-10 rounded-full lg:h-12 lg:w-12 shrink-0 ${currentPage >= 6 ? 'bg-blue text-white' : 'bg-lightblue'}`}>
                                            6
                                        </button>
                                    </li>
                                </ol>
                            </div>
                            {
                                currentPage === 1 &&
                                <div className="container mx-auto px-4 sm:px-3">
                                    <div className="bg-white shadow-card rounded-[18px] grid grid-cols-12 mt-[90px] ">
                                        <div className="col-span-12 lg:col-span-5">
                                            <img src="/assets/image/drvector.png" alt="" className="-mt-24 mx-auto" />
                                        </div>
                                        <div className="col-span-12 lg:col-span-7 py-8 flex justify-center lg:justify-start p-3">
                                            <form className="max-w-[570px] w-full">
                                                <h2 className="text-blue font-semibold text-xl sm:text-2xl mb-5 lg:mb-[34px]">I am looking for Allied Health..</h2>
                                                <p className="text-dark text-xl font-semibold mb-5 lg:mb-[34px] hidden">I’m Here For...</p>
                                                <div className="grid grid-cols-2 gap-[24px] lg:gap-[30px]">
                                                    <div className="col-span-1">
                                                        <input type="radio" name="Practitioners1" value="1" className="stap hidden" id="practitionersclinic1" onChange={(e) =>
                                                            setFormData((prevState) => ({
                                                                ...prevState,
                                                                fk_practices_types_master_id: e.target.value
                                                            }))} />
                                                        <label for="practitionersclinic1" className={`px-5 py-7 sm:px-7 sm:py-9 rounded-xl flex flex-col items-center justify-center ${formData.fk_practices_types_master_id == "1" ? 'bg-blue' : 'bg-lightblue'}`}>
                                                            <img src={`/assets/image/svg/${formData.fk_practices_types_master_id == "1" ? 'practitionersclinic.svg' : 'practitionersclinic-dark.svg'}`} alt="" className="mb-4 check" />

                                                            <span className={`font-semibold text-xl text-center ${formData.fk_practices_types_master_id == "1" ? 'text-white' : 'text-blue'}`}>Practitioners Clinic</span>
                                                        </label>
                                                    </div>
                                                    <div className="col-span-1">
                                                        <input type="radio" name="Practitioners1" value="2" className="stap hidden " id="mobilepractitioners1" onChange={(e) =>
                                                            setFormData((prevState) => ({
                                                                ...prevState,
                                                                fk_practices_types_master_id: e.target.value
                                                            }))} />
                                                        <label className={`px-5 py-7 sm:px-7 sm:py-9 rounded-xl flex flex-col items-center justify-center ${formData.fk_practices_types_master_id == "2" ? 'bg-blue' : 'bg-lightblue'}`} for="mobilepractitioners1">
                                                            {/* <img src="/assets/image/svg/mobilepractitioners.svg" alt="" srcset="" className="mb-4 check hidden" />
                                                            <img src="/assets/image/svg/mobilepractitioners-dark.svg" alt="" className="mb-4 " />
                                                             */}
                                                            <img src={`/assets/image/svg/${formData.fk_practices_types_master_id == "2" ? 'mobilepractitioners.svg' : 'mobilepractitioners-dark.svg'}`} alt="" srcset="" className="mb-4 check" />

                                                            <span className={`font-semibold text-xl text-center ${formData.fk_practices_types_master_id == "2" ? 'text-white' : 'text-blue'}`}>Mobile Practitioners</span>
                                                        </label>
                                                    </div>
                                                    <div className="col-span-2">
                                                        <button
                                                            onClick={() => changePage(2)}
                                                            type="button" className="h-auto py-3 px-3 sm:h-14 md:h-[70px] bg-green w-full text-center text-white font-medium text-lg sm:text-xl rounded-xl flex gap-4 items-center justify-center">Continue <svg width="25" height="20" viewBox="0 0 25 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-[18px]"><path d="M15 0L13.25 1.75L20.25 8.75H0V11.25H20.25L13.25 18.25L15 20L25 10L15 0Z" fill="white" /></svg>
                                                        </button>
                                                    </div>
                                                </div>

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                currentPage === 2 &&
                                <div className="container px-4 mx-auto sm:px-3">
                                    <div className="shadow-card w-100 max-w-[850px] py-5 px-6 sm:py-12 sm:px-14 md:py-24 md:px-28 bg-white rounded-[18px] mx-auto">
                                        <h2 className="text-blue font-semibold text-xl sm:text-2xl md:text-3xl mb-5 md:mb-7">I am looking for a Practitioner in.. </h2>
                                        <div>
                                            {/* <input type="text" name="postcode" id="postcode" className="border-[1.2px] border-dark w-full h-12 sm:h-14 md:h-[70px] rounded-xl px-4 sm:px-7 focus:border-blue outline-none text-lg sm:text-xl" placeholder="Start typing postcode and select it from the dropdown" /> */}
                                            <div className="border-[1.2px] border-dark w-full h-12 sm:h-14 md:h-[70px] rounded-xl px-4 sm:px-7 focus:border-blue outline-none text-lg sm:text-xl flex items-center" >
                                                <Select
                                                    required
                                                    options={filteredSuburbs.map((item) => ({
                                                        value: item.suburbs_id,
                                                        label: `${item.name} (${item.postcode})`,
                                                        slug: item.slug,
                                                        fk_postcodes_id: item.fk_postcodes_id,
                                                        postcode: item.postcode,
                                                        name: item?.name,
                                                    }))}
                                                    className="w-full text-black custom-select no-border no-icon"
                                                    placeholder="Start typing postcode..."
                                                    onChange={(selectedOption) => {
                                                        setSelectedSuburb(selectedOption);
                                                        setFormData((prevState) => ({
                                                            ...prevState,
                                                            fk_postcodes_id: selectedOption?.fk_postcodes_id,
                                                            fk_suburbs_id: selectedOption?.value
                                                        }));
                                                    }}
                                                    value={selectedSuburb?.value ? selectedSuburb : null}
                                                    onInputChange={handleInputChange}
                                                    isClearable
                                                    isSearchable
                                                    noOptionsMessage={() => inputValue.length <= 2 ? "Start typing postcode..." : "No options available"}
                                                />
                                            </div>
                                            <span className="text-end font-medium text-sm text-dark float-left mt-4 w-full justify-end">
                                                <img src="/assets/image/svg/info.svg" alt="" srcset="" className="inline" />
                                                Why we need your postcode/suburb
                                            </span>
                                            <div className="mt-14 md:mt-20 flex gap-3 w-full">
                                                <button
                                                    onClick={() => changePage(1)}
                                                    type="button" className="h-12 sm:h-14 md:h-[70px] bg-lightblue px-4 sm:px-8 text-center text-blue font-medium text-lg sm:text-xl  rounded-xl flex items-center justify-center gap-3"><svg width="25" height="20" viewBox="0 0 25 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-[18px]">
                                                        <path d="M10 20L11.75 18.25L4.75 11.25L25 11.25V8.75L4.75 8.75L11.75 1.75L10 0L0 10L10 20Z" fill="#0052cc" />
                                                    </svg> <span className="hidden sm:block">Back</span>
                                                </button>
                                                <button
                                                    onClick={() => changePage(3)}
                                                    type="button" className="h-12 sm:h-14 md:h-[70px] bg-green w-[calc(100%-48px)] sm:w-[calc(100%-70px)] text-center text-white font-medium text-lg sm:text-xl  rounded-xl flex items-center justify-center gap-3">Continue <svg width="25" height="20" viewBox="0 0 25 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-[18px]"><path d="M15 0L13.25 1.75L20.25 8.75H0V11.25H20.25L13.25 18.25L15 20L25 10L15 0Z" fill="white"></path></svg>
                                                </button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            }
                            {
                                currentPage === 3 &&
                                <div className="container px-4 mx-auto sm:px-3">
                                    <div className="shadow-card max-w-[1070px] w-100 py-5 px-6 sm:py-12 sm:px-14 md:py-16 md:px-28 bg-white rounded-[18px] mx-auto">
                                        <h2 className="text-blue font-semibold text-xl sm:text-2xl md:text-3xl mb-5 lg:mb-[40px]">I’m Looking For... </h2>
                                        <p className="text-dark text-xl font-semibold mb-5 lg:mb-[40px] hidden">I’m Looking For...</p>
                                        <form>
                                            <div className="flex flex-wrap justify-center">
                                                <div className="w-full sm:basis-1/2 lg:basis-1/3 p-2">
                                                    <input type="radio" name="Practitioners" value="1" className="stap hidden" id="Male"
                                                        onChange={(e) =>
                                                            setFormData((prevState) => ({
                                                                ...prevState,
                                                                looking_gender: e.target.value
                                                            }))} />
                                                    <label for="Male" className={`p-6 rounded-xl flex flex-col items-center justify-center h-full ${formData.looking_gender == "1" ? 'bg-blue' : 'bg-lightblue'}`}>
                                                        <img src={`/assets/image/svg/${formData.looking_gender == "1" ? 'malepractitioners.svg' : 'malepractitioners-dark.svg'}`} alt="" className="mb-4 " />
                                                        <span className={`font-semibold text-lg md:text-xl text-center ${formData.looking_gender == "1" ? 'text-white' : 'text-blue'}`}>Male Practitioners</span>
                                                    </label>
                                                </div>
                                                <div className="w-full sm:basis-1/2 lg:basis-1/3 p-2">
                                                    <input type="radio" name="Practitioners" value="0" className="stap hidden" id="Female"
                                                        onChange={(e) =>
                                                            setFormData((prevState) => ({
                                                                ...prevState,
                                                                looking_gender: e.target.value
                                                            }))} />
                                                    <label for="Female" className={`p-6 rounded-xl flex flex-col items-center justify-center h-full ${formData.looking_gender == "0" ? 'bg-blue' : 'bg-lightblue'}`}>
                                                        <img src={`/assets/image/svg/${formData.looking_gender == "0" ? 'femalepractitioners.svg' : 'femalepractitioners-dark.svg'}`} alt="" className="mb-4 " />
                                                        <span className={`font-semibold text-lg md:text-xl text-center  ${formData.looking_gender == "0" ? 'text-white' : 'text-blue'}`}>Female Practitioners</span>
                                                    </label>
                                                </div>
                                                <div className="w-full sm:basis-1/2 lg:basis-1/3 p-2">
                                                    <input type="radio" name="Practitioners" value="2" className="stap hidden" id="eithermaleorfemale"
                                                        onChange={(e) =>
                                                            setFormData((prevState) => ({
                                                                ...prevState,
                                                                looking_gender: e.target.value
                                                            }))} />
                                                    <label for="eithermaleorfemale" className={`p-6 rounded-xl flex flex-col items-center justify-center h-full ${formData.looking_gender == "2" ? 'bg-blue' : 'bg-lightblue'}`}>
                                                        <img src={`/assets/image/svg/${formData.looking_gender == "2" ? 'eithermaleorfemale.svg' : 'eithermaleorfemale-dark.svg'}`} alt="" className="mb-4 " />
                                                        <span className={`font-semibold text-lg md:text-xl text-center  ${formData.looking_gender == "2" ? 'text-white' : 'text-blue'}`}>Either Male or Female</span>
                                                    </label>
                                                </div>
                                                <div className="p-2 pt-8 flex gap-3 w-full">
                                                    <button
                                                        onClick={() => changePage(2)}
                                                        type="button" className="h-12 sm:h-14 md:h-[70px] bg-lightblue px-4 sm:px-8 text-center text-blue font-medium text-lg sm:text-xl  rounded-xl flex items-center justify-center gap-3"><svg width="25" height="20" viewBox="0 0 25 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-[18px]">
                                                            <path d="M10 20L11.75 18.25L4.75 11.25L25 11.25V8.75L4.75 8.75L11.75 1.75L10 0L0 10L10 20Z" fill="#0052cc" />
                                                        </svg> <span className="hidden sm:block">Back</span>
                                                    </button>
                                                    <button
                                                        onClick={() => changePage(4)}
                                                        type="button" className="h-12 sm:h-14 md:h-[70px] bg-green w-[calc(100%-48px)] sm:w-[calc(100%-70px)] text-center text-white font-medium text-lg sm:text-xl  rounded-xl flex items-center justify-center gap-3">Continue <svg width="25" height="20" viewBox="0 0 25 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-[18px]"><path d="M15 0L13.25 1.75L20.25 8.75H0V11.25H20.25L13.25 18.25L15 20L25 10L15 0Z" fill="white"></path></svg>
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            }
                            {
                                currentPage === 4 &&
                                <div className="container px-4 mx-auto sm:px-3">
                                    <div className="shadow-card  py-8 px-6 sm:py-12  md:py-16  bg-white rounded-[18px] mx-auto">
                                        <h2 className="text-blue font-semibold text-center text-xl sm:text-2xl md:text-3xl mb-5 sm:mb-7 lg:mb-10">I will pay for services through</h2>
                                        <div className="flex flex-wrap justify-center">
                                            <div className="basis-full py-2 sm:px-3 sm:py-3">
                                                <div className="w-full sm:w-1/2 lg:w-1/3 flex mx-auto">
                                                    <input type="radio" name="Forwhom" value="1" className="stap hidden" id="Medicare"
                                                        onChange={(e) =>
                                                            setFormData((prevState) => ({
                                                                ...prevState,
                                                                fk_practices_payment_methods_master_id: e.target.value,
                                                                payment_methods_value: "Medicare (Bulk Billing)"
                                                            }))} />
                                                    <label className={`py-3 px-4 rounded-xl flex gap-4 items-center justify-center h-full w-full ${formData.fk_practices_payment_methods_master_id == "1" ? 'bg-blue' : 'bg-lightblue'}`} for="Medicare">
                                                        <img src={`/assets/image/svg/bulkbilling${formData.fk_practices_payment_methods_master_id == "1" ? '.svg' : '-dark.svg'}`} alt="" srcset="" />
                                                        <span className={`font-medium text-lg md:text-xl text-center ${formData.fk_practices_payment_methods_master_id == "1" ? 'text-white' : 'text-blue'}`}>Medicare (Bulk Billing)</span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="basis-full sm:basis-1/2 xl:basis-5/12  py-2 sm:px-3 sm:py-3">
                                                <input type="radio" name="Forwhom" value="3" className="stap hidden" id="AgedCare"
                                                    onChange={(e) =>
                                                        setFormData((prevState) => ({
                                                            ...prevState,
                                                            fk_practices_payment_methods_master_id: e.target.value,
                                                            payment_methods_value: "Aged Care (Residential & Home Care)"
                                                        }))} />
                                                <label className={`py-3 px-4 rounded-xl flex gap-4 items-center justify-center h-full w-full ${formData.fk_practices_payment_methods_master_id == "3" ? 'bg-blue' : 'bg-lightblue'}`} for="AgedCare">

                                                    <img src={`/assets/image/svg/agedcare${formData.fk_practices_payment_methods_master_id == "3" ? '.svg' : '-dark.svg'}`} alt="" srcset="" />
                                                    <span className={`font-medium text-lg md:text-xl text-center ${formData.fk_practices_payment_methods_master_id == "3" ? 'text-white' : 'text-blue'}`}>Aged Care (Residential & Home Care)</span>
                                                </label>
                                            </div>
                                            <div className="basis-full sm:basis-1/2 xl:basis-5/12  py-2 sm:px-3 sm:py-3">
                                                <input type="radio" name="Forwhom" value="2" className="stap hidden" id="MedicarePrivate"
                                                    onChange={(e) =>
                                                        setFormData((prevState) => ({
                                                            ...prevState,
                                                            fk_practices_payment_methods_master_id: e.target.value,
                                                            payment_methods_value: "Medicare & Private (Mixed Billing)"
                                                        }))} />
                                                <label className={`py-3 px-4 rounded-xl flex gap-4 items-center justify-center h-full w-full ${formData.fk_practices_payment_methods_master_id == "2" ? 'bg-blue' : 'bg-lightblue'}`} for="MedicarePrivate">

                                                    <img src={`/assets/image/svg/mixedbilling${formData.fk_practices_payment_methods_master_id == "2" ? '.svg' : '-dark.svg'}`} alt="" srcset="" />
                                                    <span className={`font-medium text-lg md:text-xl text-center ${formData.fk_practices_payment_methods_master_id == "2" ? 'text-white' : 'text-blue'}`}>Medicare & Private (Mixed Billing)</span>
                                                </label>
                                            </div>
                                            <div className="basis-full sm:basis-1/2 lg:basis-1/3 py-2 sm:px-3 sm:py-3">
                                                <input type="radio" name="Forwhom" value="4" className="stap hidden" id="NDIS"
                                                    onChange={(e) =>
                                                        setFormData((prevState) => ({
                                                            ...prevState,
                                                            fk_practices_payment_methods_master_id: e.target.value,
                                                            payment_methods_value: "NDIS"
                                                        }))} />
                                                <label className={`py-3 px-4 rounded-xl flex gap-4 items-center justify-center h-full w-full ${formData.fk_practices_payment_methods_master_id == "4" ? 'bg-blue' : 'bg-lightblue'}`} for="NDIS">

                                                    <img src={`/assets/image/svg/ndis${formData.fk_practices_payment_methods_master_id == "4" ? '.svg' : '-dark.svg'}`} alt="" srcset="" />
                                                    <span className={`font-medium text-lg md:text-xl text-center ${formData.fk_practices_payment_methods_master_id == "4" ? 'text-white' : 'text-blue'}`}>NDIS</span>
                                                </label>
                                            </div>
                                            <div className="basis-full sm:basis-1/2 lg:basis-1/3 py-2 sm:px-3 sm:py-3">
                                                <input type="radio" name="Forwhom" value="5" className="stap hidden" id="PrivatePay"
                                                    onChange={(e) =>
                                                        setFormData((prevState) => ({
                                                            ...prevState,
                                                            fk_practices_payment_methods_master_id: e.target.value,
                                                            payment_methods_value: "Private Pay"
                                                        }))} />
                                                <label className={`py-3 px-4 rounded-xl flex gap-4 items-center justify-center h-full w-full ${formData.fk_practices_payment_methods_master_id == "5" ? 'bg-blue' : 'bg-lightblue'}`} for="PrivatePay">

                                                    <img src={`/assets/image/svg/privatepay${formData.fk_practices_payment_methods_master_id == "5" ? '.svg' : '-dark.svg'}`} alt="" srcset="" />
                                                    <span className={`font-medium text-lg md:text-xl text-center ${formData.fk_practices_payment_methods_master_id == "5" ? 'text-white' : 'text-blue'}`}>Private Pay</span>
                                                </label>
                                            </div>
                                            <div className="basis-full sm:basis-1/2 lg:basis-1/3 py-2 sm:px-3 sm:py-3">
                                                <input type="radio" name="Forwhom" value="6" className="stap hidden" id="Insurance"
                                                    onChange={(e) =>
                                                        setFormData((prevState) => ({
                                                            ...prevState,
                                                            fk_practices_payment_methods_master_id: e.target.value,
                                                            payment_methods_value: "Insurance"
                                                        }))} />
                                                <label className={`py-3 px-4 rounded-xl flex gap-4 items-center justify-center h-full w-full ${formData.fk_practices_payment_methods_master_id == "6" ? 'bg-blue' : 'bg-lightblue'}`} for="Insurance">

                                                    <img src={`/assets/image/svg/insurance${formData.fk_practices_payment_methods_master_id == "6" ? '.svg' : '-dark.svg'}`} alt="" srcset="" />
                                                    <span className={`font-medium text-lg md:text-xl text-center ${formData.fk_practices_payment_methods_master_id == "6" ? 'text-white' : 'text-blue'}`}>Insurance</span>
                                                </label>
                                            </div>
                                            <div className="basis-full sm:basis-1/2 lg:basis-1/3 py-2 sm:px-3 sm:py-3">
                                                <input type="radio" name="Forwhom" value="7" className="stap hidden" id="Other"
                                                    onChange={(e) =>
                                                        setFormData((prevState) => ({
                                                            ...prevState,
                                                            fk_practices_payment_methods_master_id: e.target.value,
                                                            payment_methods_value: "Other"
                                                        }))} />
                                                <label className={`py-3 px-4 rounded-xl flex gap-4 items-center justify-center h-full w-full ${formData.fk_practices_payment_methods_master_id == "7" ? 'bg-blue' : 'bg-lightblue'}`} for="Other">

                                                    <img src={`/assets/image/svg/otherbill${formData.fk_practices_payment_methods_master_id == "7" ? '.svg' : '-dark.svg'}`} alt="" srcset="" />
                                                    <span className={`font-medium text-lg md:text-xl text-center ${formData.fk_practices_payment_methods_master_id == "7" ? 'text-white' : 'text-blue'}`}>Other</span>
                                                </label>
                                            </div>
                                            <div className="basis-full pt-7 flex gap-3">
                                                <button
                                                    onClick={() => changePage(3)}
                                                    type="button" className="h-12 sm:h-14 md:h-[70px] bg-lightblue px-4 sm:px-8 text-center text-blue font-medium text-lg sm:text-xl  rounded-xl flex items-center justify-center gap-3"><svg width="25" height="20" viewBox="0 0 25 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-[18px]">
                                                        <path d="M10 20L11.75 18.25L4.75 11.25L25 11.25V8.75L4.75 8.75L11.75 1.75L10 0L0 10L10 20Z" fill="#0052cc" />
                                                    </svg> <span className="hidden sm:block">Back</span>
                                                </button>
                                                <button
                                                    onClick={() => changePage(5)}
                                                    type="button" className="h-12 sm:h-14 md:h-[70px] bg-green w-[calc(100%-48px)] sm:w-[calc(100%-70px)] text-center text-white font-medium text-lg sm:text-xl  rounded-xl flex items-center justify-center gap-3">Continue <svg width="25" height="20" viewBox="0 0 25 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-[18px]"><path d="M15 0L13.25 1.75L20.25 8.75H0V11.25H20.25L13.25 18.25L15 20L25 10L15 0Z" fill="white"></path></svg>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                currentPage === 5 &&
                                <div className="container px-4 mx-auto sm:px-3">
                                    <div className="shadow-card  py-8 px-6 sm:py-12 sm:px-14 md:py-16 xl:px-40 bg-white rounded-[18px] mx-auto">
                                        <h2 className="text-blue font-semibold text-center text-xl sm:text-2xl md:text-3xl mb-5 sm:mb-7 lg:mb-10">I am looking for..</h2>
                                        <div className="flex flex-wrap justify-center">
                                            <div className="basis-full sm:basis-1/2 lg:basis-1/3 py-2 sm:px-3 sm:py-3">
                                                <input type="radio" name="Forwhom" value="Myself" className="stap hidden" id="myself"
                                                    onChange={(e) =>
                                                        setFormData((prevState) => ({
                                                            ...prevState,
                                                            looking_for: e.target.value
                                                        }))} />
                                                <label className={`py-3 px-4 rounded-xl flex gap-4 items-center justify-center h-full ${formData.looking_for == "Myself" ? 'bg-blue' : 'bg-lightblue'}`} for="myself">
                                                    <img src={`/assets/image/svg/myself${formData.looking_for == "Myself" ? '.svg' : '-dark.svg'}`} alt="" srcset="" />
                                                    <span className={`font-medium text-lg md:text-xl text-center ${formData.looking_for == "Myself" ? 'text-white' : 'text-blue'}`}>Myself</span>
                                                </label>
                                            </div>
                                            <div className="basis-full sm:basis-1/2 lg:basis-1/3 py-2 sm:px-3 sm:py-3">
                                                <input type="radio" name="Forwhom" value="My Mother" className="stap hidden" id="mymother"
                                                    onChange={(e) =>
                                                        setFormData((prevState) => ({
                                                            ...prevState,
                                                            looking_for: e.target.value
                                                        }))} />
                                                <label className={`py-3 px-4 rounded-xl flex gap-4 items-center justify-center h-full ${formData.looking_for == "My Mother" ? 'bg-blue' : 'bg-lightblue'}`} for="mymother">
                                                    <img src={`/assets/image/svg/mymother${formData.looking_for == "My Mother" ? '.svg' : '-dark.svg'}`} alt="" srcset="" />

                                                    <span className={`font-medium text-lg md:text-xl text-center ${formData.looking_for == "My Mother" ? 'text-white' : 'text-blue'}`}>My Mother</span>
                                                </label>
                                            </div>
                                            <div className="basis-full sm:basis-1/2 lg:basis-1/3 py-2 sm:px-3 sm:py-3">
                                                <input type="radio" name="Forwhom" value="My Father" className="stap hidden" id="myfather"
                                                    onChange={(e) =>
                                                        setFormData((prevState) => ({
                                                            ...prevState,
                                                            looking_for: e.target.value
                                                        }))} />
                                                <label className={`py-3 px-4 rounded-xl flex gap-4 items-center justify-center h-full ${formData.looking_for == "My Father" ? 'bg-blue' : 'bg-lightblue'}`} for="myfather">
                                                    <img src={`/assets/image/svg/myfather${formData.looking_for == "My Father" ? '.svg' : '-dark.svg'}`} alt="" srcset="" />
                                                    <span className={`font-medium text-lg md:text-xl text-center ${formData.looking_for == "My Father" ? 'text-white' : 'text-blue'}`}>My Father</span>
                                                </label>
                                            </div>
                                            <div className="basis-full sm:basis-1/2 lg:basis-1/3 py-2 sm:px-3 sm:py-3">
                                                <input type="radio" name="Forwhom" value="My Partner" className="stap hidden" id="mypartner"
                                                    onChange={(e) =>
                                                        setFormData((prevState) => ({
                                                            ...prevState,
                                                            looking_for: e.target.value
                                                        }))} />
                                                <label className={`py-3 px-4 rounded-xl flex gap-4 items-center justify-center h-full ${formData.looking_for == "My Partner" ? 'bg-blue' : 'bg-lightblue'}`} for="mypartner">
                                                    <img src={`/assets/image/svg/mypartner${formData.looking_for == "My Partner" ? '.svg' : '-dark.svg'}`} alt="" srcset="" />
                                                    <span className={`font-medium text-lg md:text-xl text-center ${formData.looking_for == "My Partner" ? 'text-white' : 'text-blue'}`}>My Partner</span>
                                                </label>
                                            </div>
                                            <div className="basis-full sm:basis-1/2 lg:basis-1/3 py-2 sm:px-3 sm:py-3">
                                                <input type="radio" name="Forwhom" value="Other" className="stap hidden" id="other"
                                                    onChange={(e) =>
                                                        setFormData((prevState) => ({
                                                            ...prevState,
                                                            looking_for: e.target.value
                                                        }))} />
                                                <label className={`py-3 px-4 rounded-xl flex gap-4 items-center justify-center h-full ${formData.looking_for == "Other" ? 'bg-blue' : 'bg-lightblue'}`} for="other">
                                                    <img src={`/assets/image/svg/other${formData.looking_for == "Other" ? '.svg' : '-dark.svg'}`} alt="" srcset="" />
                                                    <span className={`font-medium text-lg md:text-xl text-center ${formData.looking_for == "Other" ? 'text-white' : 'text-blue'}`}>Other</span>
                                                </label>
                                            </div>
                                            <div className="basis-full pt-7 flex gap-3">
                                                <button
                                                    onClick={() => changePage(4)}
                                                    type="button" className="h-12 sm:h-14 md:h-[70px] bg-lightblue px-4 sm:px-8 text-center text-blue font-medium text-lg sm:text-xl  rounded-xl flex items-center justify-center gap-3"><svg width="25" height="20" viewBox="0 0 25 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-[18px]">
                                                        <path d="M10 20L11.75 18.25L4.75 11.25L25 11.25V8.75L4.75 8.75L11.75 1.75L10 0L0 10L10 20Z" fill="#0052cc" />
                                                    </svg> <span className="hidden sm:block">Back</span>
                                                </button>
                                                <button
                                                    onClick={() => changePage(6)}
                                                    type="button" className="h-12 sm:h-14 md:h-[70px] bg-green w-[calc(100%-48px)] sm:w-[calc(100%-70px)] text-center text-white font-medium text-lg sm:text-xl  rounded-xl flex items-center justify-center gap-3">Continue <svg width="25" height="20" viewBox="0 0 25 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-[18px]"><path d="M15 0L13.25 1.75L20.25 8.75H0V11.25H20.25L13.25 18.25L15 20L25 10L15 0Z" fill="white"></path></svg>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                currentPage === 6 &&
                                <div className="container mx-auto px-4 sm:px-3 pb-8 sm:pb-12 md:pb-20 flex flex-wrap lg:flex-nowrap justify-center items-start gap-2">
                                    <div className="w-full lg:max-w-[850px] rounded-[18px] overflow-hidden shadow-card bg-white">
                                        <div className="bg-dark px-6 py-8">
                                            <p className="text-white underline font-semibold text-2xl mb-4">Your Answers</p>
                                            <p className="text-white w-full max-w-[565px] font-normal text-xl">“I am looking for a clinic {/*{formData?.fk_practices_types_master_id == 1 ? "clinic practitioners" : "mobile practitioners"}*/} in <span className="font-semibold underline">{selectedSuburb?.name}, postcode {selectedSuburb?.postcode},</span> with <span className="font-semibold underlin">{formData?.looking_gender == "1" ? "male" : (formData?.looking_gender == "0" ? "female" : "male or female")} practitioners</span> who offer <span className="font-semibold underline">{formData?.payment_methods_value}</span> for <span className="font-semibold underline">{formData?.looking_for}.</span>”</p>
                                        </div>
                                        <div className="py-8 sm:py-20 px-6 border-t-8 border-blue ">
                                            <h2 className="mb-4 text-blue font-semibold text-xl sm:text-2xl">Thank you! You're nearly finished</h2>
                                            <p className="text-dark font-light text-lg sm:text-2xl w-full max-w-[593px] mb-[20px] md:mb-[30px] lg:mb-[40px]">Please provide your details below to find the ideal Practitioners Clinic for you.</p>
                                            <form onSubmit={handleSubmit}>
                                                <input type="text" name="name"
                                                    value={formData.name}
                                                    onChange={handleChange} id="" className="border-[1.2px] border-dark w-full h-12 sm:h-14 md:h-[70px] rounded-xl px-4 sm:px-7 focus:border-blue outline-none text-lg sm:text-xl" placeholder="Full name*" required />
                                                <input type="email" name="email"
                                                    value={formData.email}
                                                    onChange={handleChange} id="" className="border-[1.2px] border-dark w-full h-12 sm:h-14 md:h-[70px] rounded-xl px-4 sm:px-7 focus:border-blue outline-none text-lg sm:text-xl mt-[16px] md:mt-[24px] lg:mt-[34px]" placeholder="Email*" required />
                                                <input type="text" name="phone"
                                                    value={formData.phone}
                                                    onChange={(e) => {
                                                        const value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                                                        if (value.length <= 10) {
                                                            setFormData((prevData) => ({
                                                                ...prevData,
                                                                phone: value,
                                                            }));
                                                        }
                                                    }}
                                                    id="" className="border-[1.2px] border-dark w-full h-12 sm:h-14 md:h-[70px] rounded-xl px-4 sm:px-7 focus:border-blue outline-none text-lg sm:text-xl mt-[16px] md:mt-[24px] lg:mt-[34px]" placeholder="Phone*" required />
                                                <textarea name="message" value={formData.message}
                                                    onChange={handleChange} id="description" placeholder="Message*" className="border-[1.2px] border-dark w-full h-12 sm:h-14 md:h-[70px] rounded-xl px-4 sm:px-7 focus:border-blue outline-none text-lg sm:text-xl mt-[16px] md:mt-[24px] lg:mt-[34px] py-4 min-h-36" required></textarea>
                                                <div className="flex items-start mt-[16px] md:mt-[24px] lg:mt-[34px] mb-5 md:mb-10">
                                                    <input id="default-checkbox" type="checkbox" value="" className="w-4 sm:w-8 h-5 sm:h-5 text-blue-600 bg-gray-100 border-gray-300 rounded  dark:bg-gray-700 dark:border-gray-600 mt-1.5" required />
                                                    <label for="default-checkbox" className="ms-2 text-base sm:text-xl font-normal text-black w-[calc(100%-20px)]">By submitting this form, I agree to the <Link to={'/terms-conditions'} className="text-blue font-semibold">Terms and condition</Link> and <Link to={'/privacy-policy'} className="text-blue font-semibold">Privacy Policy</Link>, and I understand that I can opt out at any time.</label>
                                                </div>
                                                <div className="flex gap-3">
                                                    <button
                                                        onClick={() => changePage(5)}
                                                        type="button" className="h-12 sm:h-14 md:h-[70px] bg-lightblue px-4 sm:px-8 text-center text-blue font-medium text-lg sm:text-xl  rounded-xl flex items-center justify-center gap-3"><svg width="25" height="20" viewBox="0 0 25 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-[18px]">
                                                            <path d="M10 20L11.75 18.25L4.75 11.25L25 11.25V8.75L4.75 8.75L11.75 1.75L10 0L0 10L10 20Z" fill="#0052cc"></path>
                                                        </svg> <span className="hidden sm:block">Back</span>
                                                    </button>
                                                    <button type="submit" className="h-auto py-3 px-3 sm:h-14 md:h-[70px] bg-green w-[calc(100%-48px)] sm:w-[calc(100%-70px)] text-center text-white font-medium text-lg sm:text-xl rounded-xl flex items-center justify-center">Submit to find practitioners</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            }
                        </section>
                        :
                        <section className="min-h-[calc(100vh-96px)] bg-[url(../image/thankyou-bg.png)] bg-cover bg-center">
                            <div className="container mx-auto px-3 flex flex-col items-center justify-center min-h-[calc(100vh-96px)] py-6">
                                <img src="/assets/image/svg/thankyou.svg" alt="" srcset="" className="mb-8 md:mb-12" />
                                <h1 className="font-bold text-blue text-[34px] md:text-[44px] leading-normal mb-4 text-center">Thank you for your submission!</h1>
                                <h3 className="font-semibold text-blue text-center text-xl sm:text-2xl mb-6 sm:mb-8">Your message has been successfully sent.</h3>
                                <p className="text-center text-dark text-xl sm:text-2xl font-light mb-6 sm:mb-8">Our team will be in touch with you shortly</p>
                                <button type="button" onClick={handleOnClickGoToList}
                                    className="px-11 py-5 bg-blue text-white rounded-[8px] font-medium text-xl sm:text-2xl border-2 border-solid border-blue hover:scale-[1.04] inline-block transition ease-in-out">Go to list</button>
                            </div>
                        </section>
                }
            </FrontendLayout>

        </>)
}

export default FindFormPage