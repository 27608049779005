import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import AdminLayout from "../../layouts/AdminLayout";
import { API_BASE_URL, fetchData } from "../../api/api";
import Select from "react-select";
import { toast } from "react-toastify";
import { CONSTANT_CURRENCY, CONSTANT_GENDER, CONSTANT_REDIRECT_TIME } from "../../constant/common";
import api from "../../api/api";
import { decryptData } from "../../utils/encryptDecrypt";
import { formatDateForInput } from "../../utils/formateDate";


const AdminPractitionersEdit = () => {
    const { practices_id } = useParams();

    const decrypt_practices_id = decryptData(practices_id);

    const [practiceDetails, setPracticeDetails] = useState([]);

    const getPracticeDetails = async () => {
        setIsLoading(true);
        try {
            const data = await fetchData('/detail-practices', { practices_id: decrypt_practices_id });
            
            setPracticeDetails(data);
            
            getPostCodes(data?.fk_states_id);
            getSuburb(data?.fk_postcodes_id);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const navigate = useNavigate();

    const didFetch = useRef(false);

    const [isLoading, setIsLoading] = useState(false);

    const [stateList, setStateList] = useState([]);
    const [servicesList, setServicesList] = useState([]);
    const [paymentMethodsList, setPaymentMethodsList] = useState([]);
    const [typeList, setTypeList] = useState([]);
    const [postCodesList, setPostCodesList] = useState([]);
    const [suburbList, setSuburbList] = useState([]);
    const [languagesList, setLanguagesList] = useState([]);

    const [isServicesList, setIsServicesList] = useState(false);
    const [isPaymentMethodsList, setIsPaymentMethodsList] = useState(false);
    const [isTypeList, setIsTypeList] = useState(false);

    const getState = async () => {
        try {
            const data = await fetchData('/get-states', { status: 1 });
            setStateList(data);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {

        }
    };

    const getServices = async () => {
        try {
            const data = await fetchData('/get-services', { status: 1 });
            setServicesList(data);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {

        }
    };

    const getPaymentMethods = async () => {
        try {
            const data = await fetchData('/get-master-practice-payment-methods', { status: 1 });
            setPaymentMethodsList(data);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {

        }
    };

    const getType = async () => {
        try {
            const data = await fetchData('/get-master-practice-types', { status: 1 });
            setTypeList(data);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {

        }
    };

    const getPostCodes = async (fk_states_id = null) => {
        try {
            const data = await fetchData('/get-postcodes', {
                status: 1,
                fk_states_id: fk_states_id
            });
            setPostCodesList(data);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {

        }
    };

    const getSuburb = async (fk_postcodes_id = null) => {
        try {
            const data = await fetchData('/get-suburbs', {
                status: 1,
                fk_postcodes_id: fk_postcodes_id
            });
            setSuburbList(data);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {

        }
    };

    const getLanguages = async () => {
        try {
            const data = await fetchData('/get-languages', {
                status: 1
            });
            setLanguagesList(data);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {

        }
    };

    useEffect(() => {
        if (!didFetch.current) {

            getState();
            getServices();
            getPaymentMethods();
            getType();
            getLanguages();
            getPracticeDetails();

            didFetch.current = true;
        }
    }, []);


    const [servicesFeesList, setServicesFeesList] = useState([]);
    const [servicesFees, setServicesFees] = useState({ name: "", fees: "" });

    const handleChangeServicesFees = (e) => {
        setServicesFees({ ...servicesFees, [e.target.name]: e.target.value });
    };

    const addServicesFees = () => {
        if (!servicesFees.name.trim()) {
            toast.error("Service name cannot be empty!");
            return;
        }
        if (!servicesFees.fees.trim()) {
            toast.error("Service fees cannot be empty!");
            return;
        }

        setServicesFeesList([...servicesFeesList, servicesFees]);
        setServicesFees({ name: "", fees: "" });
    };

    const deleteServiceFees = (index) => {
        const updatedList = servicesFeesList.filter((_, i) => i !== index);
        setServicesFeesList(updatedList);
    };

    const [selectedImage, setSelectedImage] = useState(null);
    const [formData, setFormData] = useState({
        practices_id : decrypt_practices_id,
        is_practitioner: 1,
        fk_states_id: "",
        fk_postcodes_id: "",
        fk_suburbs_id: "",
        practice_name: "",
        short_description: "",
        full_description: "",
        address: "",
        phone: "",
        email: "",
        ahpra_number: "",
        ahpra_registration_date: "",
        ahpra_expiry_date: "",
        gender: "",
        image: null,
        services_ids: [],
        practices_types_ids: [],
        practices_areas_ids: [],
        services_fees: [],
        languages_ids: [],
    });

    const handleImageChange = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];

            setSelectedImage(URL.createObjectURL(file));

            setFormData(prevState => ({
                ...prevState,
                image: file
            }));
        } else {
            console.log("No file selected.");
        }
    };


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleServiceSelection = (serviceId) => {
        setFormData((prevState) => {
            const isSelected = prevState.services_ids.includes(serviceId);

            return {
                ...prevState,
                services_ids: isSelected
                    ? prevState.services_ids.filter((id) => id !== serviceId)
                    : [...prevState.services_ids, serviceId],
            };
        });
        
        setIsServicesList(false);
    };

    const selectedServicesText = servicesList
        .filter((service) => formData.services_ids.includes(service.services_id))
        .map((service) => service.name)
        .join(", ");

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!formData?.services_ids.length > 0) {
            toast.error("Please select at least one service");
            return;
        }

        if (!servicesFeesList?.length > 0) {
            toast.error("Please add at least one service & fees");
            return;
        }

        if (!formData?.practices_types_ids.length > 0) {
            toast.error("Please select at least one type of practice");
            return;
        }

        if (!formData?.practices_areas_ids.length > 0) {
            toast.error("Please select at least one service area");
            return;
        }

        if (!formData?.languages_ids.length > 0) {
            toast.error("Please select at least language");
            return;
        }

        const payload = {
            ...formData,
            services_ids: formData.services_ids.join(","),
            practices_types_ids: formData.practices_types_ids.join(","),
            practices_areas_ids: formData.practices_areas_ids.join(","),
            services_fees: JSON.stringify(servicesFeesList),
            languages_ids: formData.languages_ids.join(","),
        };

        try {
            const response = await api.post("edit-practices", payload, {
                headers: { "Content-Type": "multipart/form-data" },
            });

            if (response?.data?.status === true) {
                toast.success(response.data?.message);

                setTimeout(() => {
                    navigate('/admin');
                }, CONSTANT_REDIRECT_TIME);

            } else {
                toast.error(response?.data?.message || "Failed ");
                return null;
            }
        } catch (error) {
            console.error("Error :", error);
            toast.error("Something went wrong. Please try again.");
            return null;
        }

    }

    useEffect(() => {
        if (practiceDetails) {
            setFormData({
                practices_id : decrypt_practices_id,
                is_practitioner: 1,
                fk_states_id: practiceDetails.fk_states_id || "",
                fk_postcodes_id: practiceDetails.fk_postcodes_id || "",
                fk_suburbs_id: practiceDetails.fk_suburbs_id || "",
                practice_name: practiceDetails.practice_name || "",
                short_description: practiceDetails.short_description || "",
                full_description: practiceDetails.full_description || "",
                address: practiceDetails.address || "",
                phone: practiceDetails.phone || "",
                email: practiceDetails.email || "",
                image: null,
                ahpra_number: practiceDetails.ahpra_number || "",
                ahpra_registration_date: formatDateForInput(practiceDetails?.ahpra_registration_date) || "",
                ahpra_expiry_date: formatDateForInput(practiceDetails?.ahpra_expiry_date) || "",
                gender: practiceDetails?.gender,
                services_ids: practiceDetails.services?.map(service => service.services_id) || [],
                practices_types_ids: practiceDetails.practices_types?.map(type => type.practices_types_master_id) || [],
                practices_areas_ids: practiceDetails.services_areas?.map(area => area.postcodes_id) || [],
                services_fees: practiceDetails.services_fees || [],
                languages_ids: practiceDetails.languages?.map(language => language.languages_id) || [],

            });

            setServicesFeesList(practiceDetails.services_fees || []);

            setSelectedImage(practiceDetails?.image ? API_BASE_URL + practiceDetails?.image : null);

        }
    }, [practiceDetails]);

    const [isServicesFeesEditModalOpen, setIsServicesFeesEditModalOpen] = useState(false);
    const [servicesFeesEdit, setServicesFeesEdit] = useState({ index: null, servicesFeesName: "", servicesFeesAmount: "" });

    const openServicesFeesEditModal = (index) => {
        const selectedServiceFee = servicesFeesList[index];
        setServicesFeesEdit({
            index,
            servicesFeesName: selectedServiceFee.name,
            servicesFeesAmount: selectedServiceFee.fees
        });
        setIsServicesFeesEditModalOpen(true);
    };

    const handleServicesFeesEditChange = (e) => {
        const { name, value } = e.target;
        setServicesFeesEdit((prev) => ({ ...prev, [name]: value }));
    };

    const saveEditedServicesFees = (e) => {
        e.preventDefault();

        if (!servicesFeesEdit.servicesFeesName.trim()) {
            toast.error("Service name cannot be empty!");
            return;
        }
        if (!servicesFeesEdit.servicesFeesAmount.trim()) {
            toast.error("Service fee cannot be empty!");
            return;
        }

        const updatedList = [...servicesFeesList];
        updatedList[servicesFeesEdit.index] = {
            name: servicesFeesEdit.servicesFeesName,
            fees: servicesFeesEdit.servicesFeesAmount
        };

        setServicesFeesList(updatedList);
        setIsServicesFeesEditModalOpen(false);
    };

    return (
        <>
            <AdminLayout>
                <main>
                    <div className="container mx-auto px-3">
                        <div className="max-w-[850px] w-full mx-auto py-10">
                            {/* <div className="flex gap-2 listing-step justify-between items-start mb-10">
                               <div className="w-full max-w-[320px] md:max-w-[520px]">
                                    <div className="w-full">
                                        <div className="flex justify-between">
                                            <div className="w-20 sm:w-[110px]">
                                                <div className="relative mb-2">
                                                    <div className="w-7 h-7 sm:w-10 sm:h-10 mx-auto bg-blue rounded-full text-lg text-white flex items-center">
                                                        <span className="text-center text-white w-full flex justify-center items-center">
                                                            <svg width="24" height="17" viewBox="0 0 24 17" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-3 h-3 sm:w-5 sm:h-5">
                                                                <path d="M9.33293 13.2277L21.5895 0.971191L23.4751 2.85681L9.33293 16.9989L0.847656 8.5137L2.73328 6.6281L9.33293 13.2277Z" fill="white"></path>
                                                            </svg>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="text-xs sm:text-sm text-blue font-medium text-center">Practice Details</div>
                                            </div>
                                            <div className="flex-1">
                                                <hr className="border-2 border-blue top-1/2 translate-y-[-22px] sm:translate-y-[-16px] relative w-[calc(100%+52px)] sm:w-[calc(100%+70px)] mx-[-26px] sm:mx-[-35px]" />
                                            </div>
                                            <div className="w-20 sm:w-[110px]">
                                                <div className="relative mb-2">
                                                    <div className="w-7 h-7 sm:w-10 sm:h-10 mx-auto border-2 border-[#B9B9B9] rounded-full text-lg  flex items-center">
                                                        <span className="text-center text-white w-full  flex justify-center items-center">
                                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-3 h-3 sm:w-5 sm:h-5">
                                                                <rect width="16" height="16" rx="8" fill="#B9B9B9" />
                                                            </svg>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="text-xs sm:text-sm text-dark font-medium text-center ">Practitioners</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <form onSubmit={handleSubmit}
                                className="grid grid-cols-2 gap-y-4 gap-x-[20px] lg:gap-x-[30px]">
                                <div className="col-span-2">
                                    <label htmlFor="practice_name" className="mb-2 inline-block text-dark font-semibold text-base sm:text-xl">Practice name</label>
                                    <input
                                        value={formData.practice_name}
                                        onChange={handleChange}
                                        required
                                        type="text" name="practice_name" id="practice_name" className="border-[1.2px] border-dark w-full h-12 sm:h-14 rounded-xl px-4 sm:px-7 focus:border-blue outline-none text-base sm:text-xl" placeholder="Enter practice name" />
                                </div>
                                <div className="col-span-2">
                                    <label htmlFor="address" className="mb-2 inline-block text-dark font-semibold text-base sm:text-xl">Practice Address</label>
                                    <input
                                        value={formData.address}
                                        onChange={handleChange}
                                        required
                                        type="text" name="address" id="address" className="border-[1.2px] border-dark w-full h-12 sm:h-14 rounded-xl px-4 sm:px-7 focus:border-blue outline-none text-base sm:text-xl" placeholder="Enter practice address" />
                                </div>
                                <div className="col-span-2 md:col-span-1">
                                    <label htmlFor="fk_states_id" className="mb-2 inline-block text-dark font-semibold text-base sm:text-xl">State</label>
                                    <div className="relative w-full">
                                        <Select
                                            value={stateList?.find(option => option.states_id === formData.fk_states_id) ? {
                                                value: formData.fk_states_id,
                                                label: stateList.find(option => option.states_id === formData.fk_states_id)?.name
                                            } : null}
                                            onChange={(selectedOption) => {
                                                handleChange({
                                                    target: { name: "fk_states_id", value: selectedOption.value }
                                                })
                                                getPostCodes(selectedOption.value);
                                            }
                                            }
                                            options={stateList?.map(state => ({
                                                value: state.states_id,
                                                label: state.name
                                            }))}
                                            isSearchable={true}
                                            required
                                            placeholder="Select state"
                                            className="w-full text-black custom-select"
                                        />
                                    </div>
                                </div>
                                <div className="col-span-2  md:col-span-1">
                                    <label htmlFor="postcode" className="mb-2 inline-block text-dark font-semibold text-base sm:text-xl">Postcode</label>
                                    <Select
                                        value={postCodesList?.find(option => option.postcodes_id === formData.fk_postcodes_id) ? {
                                            value: formData.fk_postcodes_id,
                                            label: postCodesList.find(option => option.postcodes_id === formData.fk_postcodes_id)?.postcode
                                        } : null}
                                        onChange={(selectedOption) => {
                                            handleChange({
                                                target: { name: "fk_postcodes_id", value: selectedOption.value }
                                            })
                                            getSuburb(selectedOption.value);
                                        }
                                        }
                                        options={postCodesList?.map(postcodeItem => ({
                                            value: postcodeItem.postcodes_id,
                                            label: postcodeItem.postcode
                                        }))}
                                        isSearchable={true}
                                        required
                                        placeholder="Select postcodes"
                                        className="w-full text-black custom-select"
                                    />
                                </div>
                                <div className="col-span-2">
                                    <label htmlFor="suburb" className="mb-2 inline-block text-dark font-semibold text-base sm:text-xl">Suburb</label>
                                    <Select
                                        value={suburbList?.find(option => option.suburbs_id === formData.fk_suburbs_id) ? {
                                            value: formData.fk_suburbs_id,
                                            label: suburbList.find(option => option.suburbs_id === formData.fk_suburbs_id)?.name
                                        } : null}
                                        onChange={(selectedOption) => {
                                            handleChange({
                                                target: { name: "fk_suburbs_id", value: selectedOption.value }
                                            })
                                        }
                                        }
                                        options={suburbList?.map(suburb => ({
                                            value: suburb.suburbs_id,
                                            label: suburb.name
                                        }))}
                                        isSearchable={true}
                                        required
                                        placeholder="Select suburb"
                                        className="w-full text-black custom-select"
                                    />
                                </div>
                                <div className="col-span-2">
                                    <label htmlFor="short_description" className="mb-2 inline-block text-dark font-semibold text-base sm:text-xl">Short Description <span className="font-normal text-sm">(max.100 characters)</span></label>
                                    <textarea
                                        value={formData.short_description}
                                        onChange={handleChange}
                                        required
                                        maxLength={100}
                                        name="short_description" id="short_description" placeholder="Enter Description" className="border-[1.2px] border-dark w-full  rounded-xl py-4 px-4 sm:px-7 focus:border-blue outline-none text-base sm:text-xl min-h-32" spellCheck="false"></textarea>
                                </div>
                                <div className="col-span-2">
                                    <label htmlFor="phone" className="mb-2 inline-block text-dark font-semibold text-base sm:text-xl">Phone Number</label>
                                    <input
                                        value={formData.phone}
                                        onChange={handleChange}
                                        required
                                        type="tel" name="phone" id="phone" className="border-[1.2px] border-dark w-full h-12 sm:h-14 rounded-xl px-4 sm:px-7 focus:border-blue outline-none text-base sm:text-xl" placeholder="Enter phone number" />
                                </div>
                                <div className="col-span-2">
                                    <label htmlFor="email" className="mb-2 inline-block text-dark font-semibold text-base sm:text-xl">Practice Email</label>
                                    <input
                                        value={formData.email}
                                        onChange={handleChange}
                                        required
                                        type="text" name="email" id="email" className="border-[1.2px] border-dark w-full h-12 sm:h-14 rounded-xl px-4 sm:px-7 focus:border-blue outline-none text-base sm:text-xl" placeholder="Enter email id" />
                                </div>
                                {/* <div className=" col-span-2">
                                    <label htmlFor="image" className="mb-2 inline-block text-dark font-semibold text-base sm:text-xl">Profile Photo</label>
                                    <label htmlFor="image" className="flex flex-col items-center justify-center w-full h-24 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer hover:bg-gray-100">
                                        <span className="flex flex-col items-center justify-center pt-5 pb-6">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12 12.5858L16.2426 16.8284L14.8284 18.2426L13 16.415V22H11V16.413L9.17157 18.2426L7.75736 16.8284L12 12.5858ZM12 2C15.5934 2 18.5544 4.70761 18.9541 8.19395C21.2858 8.83154 23 10.9656 23 13.5C23 16.3688 20.8036 18.7246 18.0006 18.9776L18.0009 16.9644C19.6966 16.7214 21 15.2629 21 13.5C21 11.567 19.433 10 17.5 10C17.2912 10 17.0867 10.0183 16.8887 10.054C16.9616 9.7142 17 9.36158 17 9C17 6.23858 14.7614 4 12 4C9.23858 4 7 6.23858 7 9C7 9.36158 7.03838 9.7142 7.11205 10.0533C6.91331 10.0183 6.70879 10 6.5 10C4.567 10 3 11.567 3 13.5C3 15.2003 4.21241 16.6174 5.81986 16.934L6.00005 16.9646L6.00039 18.9776C3.19696 18.7252 1 16.3692 1 13.5C1 10.9656 2.71424 8.83154 5.04648 8.19411C5.44561 4.70761 8.40661 2 12 2Z" fill="#00A167" />
                                            </svg>
                                            <span className=" text-sm font-semibold text-green">Browse Image</span>
                                        </span>
                                        <input id="image" type="file" accept="image/*" className="hidden" onChange={handleImageChange} name="image" />
                                    </label>
                                </div> */}
                                <div className="col-span-2">
                                    <label htmlFor="image" className="mb-2 inline-block text-dark font-semibold text-base sm:text-xl">
                                        Profile Photo
                                    </label>

                                    <label
                                        htmlFor="image"
                                        className="flex flex-col items-center justify-center w-full h-24 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer hover:bg-gray-100"
                                    >
                                        {selectedImage ? (
                                            <img
                                                src={selectedImage}
                                                alt="Selected Preview"
                                                className="w-20 h-20 object-cover rounded-lg"
                                            />
                                        ) : (
                                            <span className="flex flex-col items-center justify-center pt-5 pb-6">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M12 12.5858L16.2426 16.8284L14.8284 18.2426L13 16.415V22H11V16.413L9.17157 18.2426L7.75736 16.8284L12 12.5858ZM12 2C15.5934 2 18.5544 4.70761 18.9541 8.19395C21.2858 8.83154 23 10.9656 23 13.5C23 16.3688 20.8036 18.7246 18.0006 18.9776L18.0009 16.9644C19.6966 16.7214 21 15.2629 21 13.5C21 11.567 19.433 10 17.5 10C17.2912 10 17.0867 10.0183 16.8887 10.054C16.9616 9.7142 17 9.36158 17 9C17 6.23858 14.7614 4 12 4C9.23858 4 7 6.23858 7 9C7 9.36158 7.03838 9.7142 7.11205 10.0533C6.91331 10.0183 6.70879 10 6.5 10C4.567 10 3 11.567 3 13.5C3 15.2003 4.21241 16.6174 5.81986 16.934L6.00005 16.9646L6.00039 18.9776C3.19696 18.7252 1 16.3692 1 13.5C1 10.9656 2.71424 8.83154 5.04648 8.19411C5.44561 4.70761 8.40661 2 12 2Z" fill="#00A167" />
                                                </svg>
                                                <span className="text-sm font-semibold text-green">Browse Image</span>
                                            </span>
                                        )}
                                        <input id="image" type="file" accept="image/*" className="hidden" onChange={handleImageChange} name="image" />
                                    </label>
                                </div>

                                <div className="col-span-2">
                                    <label htmlFor="full_description" className="mb-2 inline-block text-dark font-semibold text-base sm:text-xl">Overview  <span className="font-normal text-sm">(Full Description)</span></label>
                                    <textarea
                                        value={formData.full_description}
                                        onChange={handleChange}
                                        required
                                        name="full_description" id="full_description" placeholder="Enter description" className="border-[1.2px] border-dark w-full  rounded-xl py-4 px-4 sm:px-7 focus:border-blue outline-none text-base sm:text-xl min-h-32" spellCheck="false"></textarea>
                                </div>
                                <div className="col-span-2">
                                    <label className="mb-2 inline-block text-dark font-semibold text-base sm:text-xl">
                                        Services
                                    </label>
                                    <div className="relative w-full multi-select-dropdown">
                                        <div className="dropdown-box relative border-[1.2px] border-dark w-full h-12 sm:h-14 rounded-xl px-4 sm:px-7 pr-10 flex items-center justify-between cursor-pointer focus:border-blue outline-none text-base sm:text-xl bg-white" onClick={() => setIsServicesList(!isServicesList)}>
                                            <span className="selected-options text-ellipsis line-clamp-1">{selectedServicesText || "Select service"}</span>
                                            <div className="pointer-events-none absolute inset-y-0 right-4 flex items-center">
                                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M16.0008 17.562L22.6006 10.9624L24.4862 12.848L16.0008 21.3334L7.51562 12.848L9.40124 10.9624L16.0008 17.562Z" fill="black" />
                                                </svg>
                                            </div>
                                        </div>
                                        <div className={`dropdown-options absolute w-full bg-white border border-gray-300 rounded-xl mt-1 shadow-md z-10 max-h-96 overflow-y-auto ${isServicesList ? '' : 'hidden'}`}>
                                            {servicesList?.length > 0 && servicesList?.map((services, index) => (
                                                <label key={index} className="flex items-center px-4 py-2 cursor-pointer text-black text-base sm:text-xl">
                                                    <input type="checkbox" value={services?.services_id} className="practice-checkbox w-4 h-4 mr-2"
                                                        checked={formData.services_ids.includes(services.services_id)}
                                                        onChange={() => handleServiceSelection(services.services_id)} />{services?.name}
                                                </label>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-span-2">
                                    <label className="mb-2 inline-block text-dark font-semibold text-base sm:text-xl">
                                        Add Service & Fees
                                    </label>
                                    <div className="grid grid-cols-2 gap-4 flex-wrap">
                                        <div className="col-span-2 md:col-span-1 ">
                                            <input type="text"
                                                value={servicesFees.name}
                                                onChange={handleChangeServicesFees}
                                                name="name" className="border-[1.2px] border-dark w-full h-12 sm:h-14 rounded-xl px-4 sm:px-7 focus:border-blue outline-none text-base sm:text-xl" placeholder="Enter Service" />
                                        </div>
                                        <div className="col-span-2 md:col-span-1 flex space-x-4">
                                            <input
                                                value={servicesFees.fees}
                                                onChange={handleChangeServicesFees}
                                                name="fees"
                                                type="number" className="border-[1.2px] border-dark w-full h-12 sm:h-14 rounded-xl px-4 sm:px-7 focus:border-blue outline-none text-base sm:text-xl" placeholder="Enter Fees" />
                                            <button
                                                type="button"
                                                onClick={addServicesFees}
                                                className="bg-blue text-white px-4 sm:px-6 w-2/5 h-12 sm:h-14 rounded-xl">Save</button>
                                        </div>
                                    </div>

                                    {servicesFeesList?.length > 0 &&
                                        <div id="serviceListContainer" className="mt-4 overflow-x-auto">
                                            <table className="w-full border-collapse">
                                                <thead>
                                                    <tr className="bg-lightblue">
                                                        <th className="border-b border-gray-300 p-2 text-left text-blue font-semibold min-w-[180px] w-full">Service</th>
                                                        <th className="border-b border-gray-300 p-2 text-left text-blue font-semibold min-w-[120px]">Fee ({CONSTANT_CURRENCY})</th>
                                                        <th className="border-b border-gray-300 p-2 text-center  text-blue font-semibold min-w-[104px]">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody id="serviceList">
                                                    {servicesFeesList?.map((item, index) => (
                                                        <tr key={index}>
                                                            <td className="border-b border-gray-300 p-2">{item?.name}</td>
                                                            <td className="border-b border-gray-300 p-2">{item?.fees}</td>
                                                            <td className="border-b border-gray-300 p-2 text-center">
                                                                <div className="flex rounded-full shadow-xs bg-blue max-w-[120px] w-full" role="group">
                                                                    <button type="button" className="pl-4 pr-2 py-2 text-sm font-medium  bg-transparent rounded-s-full"
                                                                        onClick={() => openServicesFeesEditModal(index)}>
                                                                        <svg width="24" height="24" viewBox="0 0 24 24" className="w-5 h-5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M5 18.89H6.41421L15.7279 9.57628L14.3137 8.16207L5 17.4758V18.89ZM21 20.89H3V16.6473L16.435 3.21232C16.8256 2.8218 17.4587 2.8218 17.8492 3.21232L20.6777 6.04075C21.0682 6.43127 21.0682 7.06444 20.6777 7.45496L9.24264 18.89H21V20.89ZM15.7279 6.74786L17.1421 8.16207L18.5563 6.74786L17.1421 5.33364L15.7279 6.74786Z" fill="white"></path>
                                                                        </svg>
                                                                    </button>
                                                                    <button
                                                                        onClick={() => deleteServiceFees(index)} type="button" className="pl-2 pr-4 py-2 text-sm font-medium  bg-transparent rounded-e-full">
                                                                        <svg width="24" height="24" viewBox="0 0 24 24" className="w-5 h-5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M7 4V2H17V4H22V6H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V6H2V4H7ZM6 6V20H18V6H6ZM9 9H11V17H9V9ZM13 9H15V17H13V9Z" fill="white"></path>
                                                                        </svg>
                                                                    </button>
                                                                </div>

                                                            </td>
                                                        </tr>
                                                    ))}

                                                </tbody>
                                            </table>
                                        </div>
                                    }
                                </div>
                                <div className="col-span-2">
                                    <label htmlFor="ahpra_number" className="mb-2 inline-block text-dark font-semibold text-base sm:text-xl">AHPRA Number</label>
                                    <input
                                        value={formData.ahpra_number}
                                        onChange={handleChange}
                                        required type="text" name="ahpra_number" id="ahpra_number" className="border-[1.2px] border-dark w-full h-12 sm:h-14 rounded-xl px-4 sm:px-7 focus:border-blue outline-none text-base sm:text-xl" placeholder="Enter AHPRA number" />
                                </div>
                                <div className="col-span-2">
                                    <label htmlFor="ahpra_registration_date" className="mb-2 inline-block text-dark font-semibold text-base sm:text-xl">AHPRA Registration Date</label>
                                    <input
                                        value={formData.ahpra_registration_date}
                                        onChange={handleChange}
                                        required type="date" name="ahpra_registration_date" id="ahpra_registration_date" className="border-[1.2px] border-dark w-full h-12 sm:h-14 rounded-xl px-4 sm:px-7 focus:border-blue outline-none text-base sm:text-xl" placeholder="Enter AHPRA registration date" />
                                </div>
                                <div className="col-span-2">
                                    <label htmlFor="ahpra_expiry_date" className="mb-2 inline-block text-dark font-semibold text-base sm:text-xl">AHPRA Expiry Date</label>
                                    <input
                                        value={formData.ahpra_expiry_date}
                                        onChange={handleChange}
                                        required type="date" name="ahpra_expiry_date" id="ahpra_expiry_date" className="border-[1.2px] border-dark w-full h-12 sm:h-14 rounded-xl px-4 sm:px-7 focus:border-blue outline-none text-base sm:text-xl" placeholder="Enter AHPRA expiry date" />
                                </div>
                                <div className="col-span-2">
                                    <label htmlFor="gender" className="mb-2 inline-block text-dark font-semibold text-base sm:text-xl">Gender</label>
                                    <div className="relative w-full">
                                        <select
                                            value={formData.gender}
                                            onChange={handleChange}
                                            required id="gender" name="gender" className="custom-select border-[1.2px] border-dark w-full h-12 sm:h-14 rounded-xl px-4 sm:px-7 pr-10 focus:border-blue outline-none text-base sm:text-xl appearance-none"
                                        >
                                            <option selected>Select Gender</option>
                                            {CONSTANT_GENDER.map((gender) => (
                                                <option key={gender.key} value={gender.key}>{gender.value}</option>
                                            ))}
                                        </select>

                                        <div className="pointer-events-none absolute inset-y-0 right-4 flex items-center">
                                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M16.0008 17.562L22.6006 10.9624L24.4862 12.848L16.0008 21.3334L7.51562 12.848L9.40124 10.9624L16.0008 17.562Z" fill="black" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="col-span-2">
                                    <label htmlFor="languages" className="mb-2 inline-block text-dark font-semibold text-base sm:text-xl">Languages</label>
                                    <Select
                                        options={languagesList.map((item) => ({
                                            value: item.languages_id,
                                            label: item.name,
                                        }))}
                                        isMulti
                                        className="w-full text-black custom-select"
                                        placeholder="Enter Languages"
                                        value={languagesList
                                            .filter((item) => formData.languages_ids.includes(item.languages_id))
                                            .map((item) => ({
                                                value: item.languages_id,
                                                label: item.name,
                                            }))
                                        }
                                        onChange={(selectedOptions) => {
                                            setFormData((prevState) => ({
                                                ...prevState,
                                                languages_ids: selectedOptions.map((option) => option.value),
                                            }));
                                        }}
                                    />
                                </div>
                                <div className="col-span-2">
                                    <label className="mb-2 inline-block text-dark font-semibold text-base sm:text-xl">
                                        Type of Practice
                                    </label>
                                    <div className="relative w-full multi-select-dropdown">
                                        {/* Dropdown Box */}
                                        <div
                                            className="dropdown-box relative border-[1.2px] border-dark w-full h-12 sm:h-14 rounded-xl px-4 sm:px-7 pr-10 flex items-center justify-between cursor-pointer focus:border-blue outline-none text-base sm:text-xl bg-white"
                                            onClick={() => setIsTypeList(!isTypeList)}
                                        >
                                            <span className="selected-options text-ellipsis line-clamp-1">
                                                {formData.practices_types_ids.length > 0
                                                    ? typeList
                                                        .filter((type) => formData.practices_types_ids.includes(type.practices_types_master_id))
                                                        .map((type) => type.name)
                                                        .join(", ")
                                                    : "Select practice type"}
                                            </span>
                                            <div className="pointer-events-none absolute inset-y-0 right-4 flex items-center">
                                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M16.0008 17.562L22.6006 10.9624L24.4862 12.848L16.0008 21.3334L7.51562 12.848L9.40124 10.9624L16.0008 17.562Z" fill="black" />
                                                </svg>
                                            </div>
                                        </div>

                                        {/* Dropdown Options */}
                                        <div className={`dropdown-options absolute w-full bg-white border border-gray-300 rounded-xl mt-1 shadow-md z-10 max-h-96 overflow-y-auto ${isTypeList ? '' : 'hidden'}`}>
                                            {typeList?.length > 0 &&
                                                typeList.map((type) => (
                                                    <label key={type.practices_types_master_id} className="flex items-center px-4 py-2 cursor-pointer text-black text-base sm:text-xl">
                                                        <input
                                                            type="checkbox"
                                                            value={type.practices_types_master_id}
                                                            className="practice-checkbox mr-2 w-4 h-4"
                                                            checked={formData.practices_types_ids.includes(type.practices_types_master_id)}
                                                            onChange={() => {
                                                                setFormData((prevState) => {
                                                                    const isSelected = prevState.practices_types_ids.includes(type.practices_types_master_id);
                                                                    return {
                                                                        ...prevState,
                                                                        practices_types_ids: isSelected
                                                                            ? prevState.practices_types_ids.filter((id) => id !== type.practices_types_master_id)
                                                                            : [...prevState.practices_types_ids, type.practices_types_master_id],
                                                                    };
                                                                });
                                                                setIsTypeList(false)
                                                            }}
                                                        />
                                                        {type.name}
                                                    </label>
                                                ))}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-span-2">
                                    <label htmlFor="practices_areas_ids" className="mb-2 inline-block text-dark font-semibold text-base sm:text-xl">
                                        Servicing Areas
                                    </label>

                                    <Select
                                        options={postCodesList.map((item) => ({
                                            value: item.postcodes_id,
                                            label: item.postcode,
                                        }))}
                                        isMulti
                                        className="w-full text-black custom-select"
                                        placeholder="Enter postcodes"
                                        value={postCodesList
                                            .filter((item) => formData.practices_areas_ids.includes(item.postcodes_id))
                                            .map((item) => ({
                                                value: item.postcodes_id,
                                                label: item.postcode,
                                            }))
                                        }
                                        onChange={(selectedOptions) => {
                                            setFormData((prevState) => ({
                                                ...prevState,
                                                practices_areas_ids: selectedOptions.map((option) => option.value),
                                            }));
                                        }}
                                    />
                                </div>

                                <div className="col-span-2 flex flex-wrap gap-1 justify-between mt-8">
                                    <button type="submit" className="py-4 px-6 rounded bg-green text-white text-base lg:text-xl leading-none font-medium">Save & Exit</button>
                                    {/* <a href="practitionerslisting.html?freeplan" className="py-4 px-6 rounded bg-green text-white text-base lg:text-xl leading-none font-medium" id="practitionersbtn">Next</a> */}
                                </div>
                            </form>
                        </div>
                    </div>
                </main>

                <div
                    id="servicesFeesUpdate"
                    className={`fixed z-[999] inset-0 flex items-center justify-center bg-black bg-opacity-50 px-3 ${isServicesFeesEditModalOpen ? "block" : "hidden"}`}
                >
                    <div className="emailbox bg-white p-9 rounded-lg shadow-lg w-full max-w-[512px] relative max-h-[90vh]">
                        <button className="absolute right-4 top-4" onClick={() => setIsServicesFeesEditModalOpen(false)}>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M0.41791 0.436139C0.685579 0.168311 1.04857 0.0178675 1.42704 0.0178675C1.80551 0.0178675 2.1685 0.168311 2.43617 0.436139L9.99103 7.99754L17.5459 0.436139C17.6777 0.299682 17.8351 0.190853 18.0092 0.115982C18.1834 0.0411107 18.3708 0.00171073 18.5602 5.3593e-05C18.7497 -0.00158926 18.9377 0.0345535 19.1131 0.106382C19.2886 0.17821 19.4479 0.284296 19.5819 0.418424C19.7159 0.552553 19.822 0.712052 19.8938 0.887623C19.9654 1.06319 20.0015 1.25131 19.9999 1.44099C19.9982 1.63068 19.9588 1.81814 19.884 1.99242C19.8093 2.16671 19.7005 2.32435 19.5642 2.45612L12.0093 10.0175L19.5642 17.5789C19.8242 17.8484 19.9681 18.2092 19.9648 18.5838C19.9616 18.9584 19.8115 19.3167 19.5468 19.5815C19.2821 19.8464 18.9242 19.9967 18.5499 19.9999C18.1757 20.0032 17.8151 19.8592 17.5459 19.5989L9.99103 12.0375L2.43617 19.5989C2.16697 19.8592 1.80643 20.0032 1.43218 19.9999C1.05793 19.9967 0.699938 19.8464 0.435296 19.5815C0.170653 19.3167 0.0205397 18.9584 0.0172997 18.5838C0.0140454 18.2092 0.157906 17.8484 0.41791 17.5789L7.97277 10.0175L0.41791 2.45612C0.150327 2.18824 0 1.82494 0 1.44612C0 1.06732 0.150327 0.704024 0.41791 0.436139Z" fill="black"></path>
                            </svg>
                        </button>
                        <form onSubmit={saveEditedServicesFees} className="space-y-3">
                            <div>
                                <label className="mb-2 inline-block text-dark font-semibold text-base">Service Name</label>
                                <input
                                    type="text"
                                    name="servicesFeesName"
                                    value={servicesFeesEdit.servicesFeesName}
                                    onChange={handleServicesFeesEditChange}
                                    className="border-[1.2px] border-dark w-full h-10 sm:h-12 rounded-xl px-3 sm:px-4 focus:border-blue outline-none text-lg"
                                    placeholder="Enter service name"
                                />
                            </div>
                            <div>
                                <label className="mb-2 inline-block text-dark font-semibold text-base">Service Fee</label>
                                <input
                                    type="number"
                                    name="servicesFeesAmount"
                                    value={servicesFeesEdit.servicesFeesAmount}
                                    onChange={handleServicesFeesEditChange}
                                    className="border-[1.2px] border-dark w-full h-10 sm:h-12 rounded-xl px-3 sm:px-4 focus:border-blue outline-none text-lg"
                                    placeholder="Enter service fee"
                                />
                            </div>
                            <button type="submit" className="py-3 px-3 bg-blue w-full text-white font-semibold text-lg rounded-xl">
                                Save
                            </button>
                        </form>
                    </div>
                </div>
            </AdminLayout>
        </>)
}

export default AdminPractitionersEdit