export const formatDateForInput = (dateString) => {
    if (!dateString) return "";

    const dateObj = new Date(dateString); 
    return dateObj.toISOString().split("T")[0]; 
};

// 28 April 2025
export const formatDateForView = (dateString) => {
    if (!dateString) return "";

    const date = new Date(dateString);
    
    return new Intl.DateTimeFormat("en-GB", {
        day: "2-digit",
        month: "long",
        year: "numeric",
    }).format(date);
};