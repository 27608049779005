export const CONSTANT_CURRENCY = '$';

export const CONSTANT_GENDER = [
    { key: 1, value: "Male" },
    { key: 0, value: "Female" }
];

export const getGender = (key) => {
    const gender = CONSTANT_GENDER.find(item => item.key === key);
    return gender ? gender.value : "Unknown Gender";
};

export const CONSTANT_REDIRECT_TIME = 2000;