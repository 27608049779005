import React, { useEffect, useRef, useState } from "react";
import FrontendLayout from "../../layouts/FrontendLayout";
import { API_BASE_URL, fetchData } from "../../api/api";
import Spinner from "../../components/common/Spinner";
import Select from "react-select";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";


const FindPage = () => {
    const navigate = useNavigate();

    const didFetch = useRef(false);

    const [isLoading, setIsLoading] = useState(false);

    const [servicesList, setServicesList] = useState([]);
    const [selectedServices, setSelectedServices] = useState({
        value: null,
        label: null,
        slug: null,
    });

    const [isViewAll, setIsViewAll] = useState(false);

    const getServices = async () => {
        setIsLoading(true);
        try {
            const data = await fetchData('/get-services', { status: 1 });
            setServicesList(data);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const toggleView = () => {
        setIsViewAll(!isViewAll);
    };

    useEffect(() => {
        if (!didFetch.current) {
            getServices();
            didFetch.current = true;
        }
    }, []);

    const handleOnClickSearch = (e) => {
        e.preventDefault();

        if (!selectedServices?.value) {
            toast.error("Please select a practitioner.");
            return;
        }
        const navigateURL = "/find/" + selectedServices?.slug;
        navigate(navigateURL);
    }


    if (isLoading) {
        return (<Spinner />);
    }
    return (
        <>
            <FrontendLayout>
                <section className="pt-6 sm:pt-8 md:pt-12 lg:pt-16 xl:pt-20 pb-[60px] md:pb-[100px]">
                    <div className="container px-3 mx-auto flex flex-col items-center justify-center">

                        <h1 className="text-[28px] leading-relaxed sm:text-[39px] sm:leading-[59px] text-dark font-bold max-w-[360px] sm:max-w-[620px] w-full mb-4 sm:mb-6 lg:mb-12 text-center">We'll Find Trusted <span className="text-blue">Allied Health</span> Practitioners <span className="italic font-light block sm:inline-block">in Your Area</span></h1>
                        <form className="max-w-5xl mx-auto w-full mb-4 sm:mb-6 lg:mb-12">
                            <div className="relative">
                                <div className="absolute inset-y-0 start- sm:start-0 -start-3 flex items-center ps-6 pointer-events-none">
                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 md:w-6 md:h-6">
                                        <path d="M31.55 29.3373L22.4 20.1873C24.1063 17.9998 25.025 15.2998 25.025 12.5311C25.025 5.63105 19.4125 0.0185547 12.5125 0.0185547C5.6125 0.0185547 0 5.63105 0 12.5311C0 19.4311 5.6125 25.0436 12.5125 25.0436C15.3063 25.0436 18.0188 24.1123 20.2125 22.3873L29.3563 31.5311C29.9875 32.1123 30.9625 32.0686 31.5438 31.4373C32.0875 30.8436 32.0875 29.9311 31.55 29.3373ZM3.09375 12.5311C3.09375 7.33731 7.31875 3.11856 12.5063 3.11856C17.6938 3.11856 21.9188 7.34356 21.9188 12.5311C21.9188 17.7186 17.6938 21.9436 12.5063 21.9436C7.31875 21.9436 3.09375 17.7186 3.09375 12.5311Z" fill="#0052CC" />
                                    </svg>
                                </div>

                                {/* <input type="search" id="default-search" className="block w-full h-14 md:h-20 pl-12 sm:pl-16 pr-32 py-2 text-lg font-poppins text-dark rounded-xl bg-white outline-none" placeholder="Select Practitioner" required /> */}
                                <div className="w-full h-14 md:h-20 pl-12 sm:pl-16 pr-32 py-2 text-lg font-poppins text-dark rounded-xl bg-white outline-none flex items-center">
                                    <Select
                                        required
                                        options={servicesList.map((item) => ({
                                            value: item.services_id,
                                            label: item.name,
                                            slug: item.slug,
                                        }))}
                                        className="w-full text-black custom-select no-border no-icon"
                                        placeholder="Select Practitioner"
                                        onChange={(selectedOption) => {
                                            setSelectedServices(selectedOption);
                                        }}
                                        value={selectedServices?.value ? selectedServices : null}

                                    />
                                </div>

                                <div className="inset-y-0 end-0 absolute p-2 sm:p-3">
                                    <button
                                        onClick={handleOnClickSearch}
                                        type="submit" id="start-button" className="w-28 h-full bg-blue text-white rounded-[8px] font-bold font-poppins text-lg border-2 border-solid border-blue">Start</button>
                                </div>
                            </div>
                        </form>
                        <div id="gridContainer" className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-x-[15px] sm:gap-x-[24px] lg:gap-x-[30px] gap-y-[15px] sm:gap-y-[24px] w-full">
                            {servicesList?.length > 0 &&
                                servicesList
                                    .slice(0, isViewAll ? servicesList.length : 8)
                                    .map((service, index) => (
                                        <button
                                            onClick={() => setSelectedServices({
                                                value: service?.services_id,
                                                label: service?.name,
                                                slug: service?.slug,
                                            })}
                                            key={index} className="p-3 sm:p-5 bg-white rounded-xl flex flex-col items-center justify-center gap-y-3 hover:shadow-card service-box">
                                            <img src={`${service?.image != null ? API_BASE_URL + service?.image : ''}`} alt={service?.name} />
                                            <span className="text-base lg:text-xl sm:leading-9 text-dark font-medium text-center">{service?.name}</span>
                                        </button>
                                    ))
                            }
                            {servicesList.length > 8 && (
                                <div className="col-span-2 md:col-span-3 xl:col-span-4">
                                    <button
                                        type="button"
                                        id="toggleButton"
                                        onClick={toggleView}
                                        className="px-11 py-2 bg-blue text-white rounded-[8px] font-bold font-poppins text-lg border-2 border-solid border-blue hover:scale-[1.04] transition ease-in-out flex max-w-[240px] w-full justify-center mx-auto items-center gap-2 mt-5"
                                    >
                                        {isViewAll ? 'View Less' : 'View All'}
                                        <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg"
                                            className={`transition-transform duration-300 ${isViewAll ? 'rotate-180' : 'rotate-0'}`}>
                                            <path d="M14 1C14.0008 1.13161 13.9756 1.26207 13.9258 1.38391C13.876 1.50574 13.8027 1.61656 13.71 1.71L7.71003 7.71C7.52267 7.89625 7.26921 8.00079 7.00503 8.00079C6.74084 8.00079 6.48739 7.89625 6.30003 7.71L0.300027 1.71C0.136201 1.5187 0.0505946 1.27262 0.0603158 1.02095C0.0700369 0.769271 0.174369 0.530532 0.352463 0.352437C0.530558 0.174342 0.769297 0.0700096 1.02097 0.0602884C1.27265 0.0505672 1.51873 0.136174 1.71003 0.3L7.00003 5.59L12.29 0.3C12.4293 0.159578 12.6071 0.063607 12.8009 0.0242218C12.9947 -0.0151634 13.1958 0.00380633 13.3789 0.0787319C13.5619 0.153658 13.7186 0.281174 13.8292 0.445157C13.9397 0.609141 13.9992 0.802227 14 1Z" fill="white" />
                                        </svg>
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </section>
            </FrontendLayout>

        </>)
}

export default FindPage