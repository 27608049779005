import React from "react";
import FrontendLayout from "../../layouts/FrontendLayout";
import { Link } from "react-router-dom";


const PrivacyPolicyPage = () => {
    return (
        <>
            <FrontendLayout>
                <section>
                    <div className="container mx-auto px-3">
                        <h1 className="text-[28px] leading-relaxed sm:text-[39px] sm:leading-[59px] text-dark font-bold text-center my-6 sm:my-8 md:my-12 lg:my-16 xl:my-20">Privacy Policy</h1>
                        <div className="grid grid-cols-12 gap-[30px] mb-[60px] md:mb-[100px]">
                            <div className="col-span-12 md:col-span-7">
                                <p className="font-medium text-dark text-base text-justify mb-4 md:mb-6">At AlliedHealth.com.au, we are committed to protecting your privacy and ensuring a safe online experience. This Privacy Policy outlines how we collect, use, and protect your personal information when you use our platform to connect with allied health practitioners. By using our website, you consent to the practices described in this policy. If you do not agree with this policy, please refrain from using our services.</p>
                                <ol className="list-decimal pl-4">
                                    <li className="font-semibold text-blue text-base text-justify pt-4 md:pt-6" id="informationwecollect">Information We Collect
                                        <dd className="font-medium text-dark text-base text-justify mb-2 mt-2">We collect the following types of information when you use our website:
                                        </dd>
                                        <ul className="ml-5 list-disc">
                                            <li className="font-normal text-dark text-base mb-2"><b>Personal Information:</b>When you create an account, book an appointment, or contact us, we may collect personal details such as your name, email address, phone number, and health-related information (as required for your appointment).</li>
                                            <li className="font-normal text-dark text-base mb-2"><b>Usage Data:</b>We collect information about how you interact with our website, including IP addresses, browser type, pages viewed, and the time spent on our site.</li>
                                            <li className="font-normal text-dark text-base mb-2"><b>Payment Information:</b>If applicable, we may collect payment details through secure third-party payment processors for appointment bookings or services.</li>
                                        </ul>
                                    </li>
                                    <li className="font-semibold text-blue text-base text-justify pt-4 md:pt-6" id="howweuseyourinformation">How We Use Your Information
                                        <dd className="font-medium text-dark text-base text-justify mb-2 mt-2">We use the information we collect for the following purposes:
                                        </dd>
                                        <ul className="ml-5 list-disc">
                                            <li className="font-normal text-dark text-base mb-2">To provide you with services and facilitate appointments with allied health practitioners.</li>
                                            <li className="font-normal text-dark text-base mb-2">To improve your user experience by personalizing content and offering relevant recommendations.</li>
                                            <li className="font-normal text-dark text-base mb-2">To communicate with you about your bookings, updates, and other related services.</li>
                                            <li className="font-normal text-dark text-base mb-2">To process payments securely.</li>
                                            <li className="font-normal text-dark text-base mb-2">To comply with legal obligations or respond to lawful requests from authorities.</li>
                                        </ul>
                                    </li>
                                    <li className="font-semibold text-blue text-base text-justify pt-4 md:pt-6" id="sharingyourinformation">Sharing Your Information
                                        <dd className="font-medium text-dark text-base text-justify mb-2 mt-2">We do not sell or rent your personal information to third parties. However, we may share your data in the following circumstances:
                                        </dd>
                                        <ul className="ml-5 list-disc">
                                            <li className="font-normal text-dark text-base mb-2"><b>With Health Practitioners:</b> When you book an appointment, your contact and relevant health information may be shared with the practitioner you select, so they can provide you with appropriate care.</li>
                                            <li className="font-normal text-dark text-base mb-2"><b>Service Providers:</b>We may engage third-party service providers (such as payment processors and hosting services) who assist in operating our website and providing services to you. These providers are required to keep your information confidential and use it only for the services we request. </li>
                                            <li className="font-normal text-dark text-base mb-2"><b>Legal Requirements:</b>We may disclose your information if required by law or to protect our rights, safety, or property, or that of our users.</li>
                                        </ul>
                                    </li>
                                    <li className="font-semibold text-blue text-base text-justify pt-4 md:pt-6" id="datasecurity">Data Security
                                        <dd className="font-medium text-dark text-base text-justify mt-2">We implement reasonable security measures to protect your personal information from unauthorized access, alteration, or disclosure. However, please be aware that no data transmission over the internet is completely secure, and we cannot guarantee the absolute security of your information.
                                        </dd>
                                    </li>
                                    <li className="font-semibold text-blue text-base text-justify pt-4 md:pt-6" id="cookiesandtrackingtechnologies">Cookies and Tracking Technologies
                                        <dd className="font-medium text-dark text-base text-justify mt-2">We use cookies and similar tracking technologies to enhance your experience on our website. Cookies help us analyze site traffic, provide personalized content, and remember your preferences. You can control the use of cookies through your browser settings.
                                        </dd>
                                    </li>
                                    <li className="font-semibold text-blue text-base text-justify pt-4 md:pt-6" id="yourrightsandchoices">Your Rights and Choices
                                        <dd className="font-medium text-dark text-base text-justify mb-2 mt-2">You have the right to:
                                        </dd>
                                        <ul className="ml-5 list-disc">
                                            <li className="font-normal text-dark text-base mb-2">Access, correct, or update your personal information.</li>
                                            <li className="font-normal text-dark text-base mb-2">Request the deletion of your personal data (subject to certain exceptions).</li>
                                            <li className="font-normal text-dark text-base mb-2">Opt-out of receiving promotional emails from us.</li>
                                            <li className="font-normal text-dark text-base mb-2">Withdraw your consent for data collection at any time (though this may affect your ability to use certain services). </li>
                                        </ul>
                                        <dd className="font-medium text-dark text-base text-justify mb-2 mt-2">To exercise these rights, please contact us at support@alliedhealth.com.au. </dd>
                                    </li>
                                    <li className="font-semibold text-blue text-base text-justify pt-4 md:pt-6" id="childrensprivacy">Children’s Privacy
                                        <dd className="font-medium text-dark text-base text-justify mt-2">Our services are not intended for children under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that we have inadvertently collected such information, we will take steps to delete it.</dd>
                                    </li>
                                    <li className="font-semibold text-blue text-base text-justify pt-4 md:pt-6" id="changestothisprivacypolicy">Changes to This Privacy Policy
                                        <dd className="font-medium text-dark text-base text-justify mt-2">We may update this Privacy Policy periodically to reflect changes in our practices or legal requirements. Any updates will be posted on this page with a revised "Last Updated" date. We encourage you to review this policy regularly to stay informed about how we are protecting your information.</dd>
                                    </li>
                                    <li className="font-semibold text-blue text-base text-justify pt-4 md:pt-6" id="contactus">Contact Us
                                        <dd className="font-medium text-dark text-base text-justify mt-2">If you have any questions about this Privacy Policy or how we handle your data, please contact us at:</dd>
                                        <a className="" href="mailto:care@alliedhealth.com.au">care@alliedhealth.com.au </a>
                                    </li>
                                </ol>
                            </div>
                            <div className="col-span-12 md:col-span-5">
                                <div className="md:max-w-[367px] w-full ml-auto">
                                    <h2 className="text-dark text-[22px] sm:text-2xl leading-relaxed font-semibold max-w-[587px] w-full mb-6 md:mb-8 lg:mb-10">Table of Contents</h2>
                                    <ul className="list-disc pl-4">
                                        <li className="mb-3 md:mb-5">
                                            <a href="#informationwecollect" className="text-dark text-lg font-medium underline">Information We Collect</a>
                                        </li>
                                        <li className="mb-3 md:mb-5">
                                            <a href="#howweuseyourinformation" className="text-dark text-lg font-medium underline">How We Use Your Information</a>
                                        </li>
                                        <li className="mb-3 md:mb-5">
                                            <a href="#sharingyourinformation" className="text-dark text-lg font-medium underline">Sharing Your Information</a>
                                        </li>
                                        <li className="mb-3 md:mb-5">
                                            <a href="#datasecurity" className="text-dark text-lg font-medium underline">Data Security</a>
                                        </li>
                                        <li className="mb-3 md:mb-5">
                                            <a href="#cookiesandtrackingtechnologies" className="text-dark text-lg font-medium underline">Cookies and Tracking Technologies</a>
                                        </li>
                                        <li className="mb-3 md:mb-5">
                                            <a href="#yourrightsandchoices" className="text-dark text-lg font-medium underline">Your Rights and Choices</a>
                                        </li>
                                        <li className="mb-3 md:mb-5">
                                            <a href="#childrensprivacy" className="text-dark text-lg font-medium underline">Children’s Privacy</a>
                                        </li>
                                        <li className="mb-3 md:mb-5">
                                            <a href="#changestothisprivacypolicy" className="text-dark text-lg font-medium underline">Changes to This Privacy Policy</a>
                                        </li>
                                        <li className="mb-3 md:mb-5">
                                            <a href="#contactus" className="text-dark text-lg font-medium underline">Contact Us</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
            </FrontendLayout>
        </>)
}

export default PrivacyPolicyPage