import "./index.css";
// import "./App.css";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import HomePage from "./pages/home/HomePage";
import FindPage from "./pages/find/FindPage";
import PricingPage from "./pages/pricing/PricingPage";
import RegisterPage from "./pages/register/RegisterPage";
import LoginPage from "./pages/login/LoginPage";
import { AuthProvider } from "./context/AuthContext";
import PrivateRoute from "./context/PrivateRoute";
import TermConditionsPage from "./pages/termConditions/TermConditionsPage";
import PrivacyPolicyPage from "./pages/privacyPolicy/PrivacyPolicyPage";
import AdminHomePage from "./pagesAdmin/home/AdminHomePage";
import AboutPage from "./pages/about/AboutPage";
import ContactPage from "./pages/contact/ContactPage";
import ListPage from "./pages/list/ListPage";
import ForgotPasswordPage from "./pages/forgotpassword/ForgotPasswordPage";
import ResetPasswordPage from "./pages/resetpassword/ResetPasswordPage";
import AdminLeadsPage from "./pagesAdmin/leads/AdminLeadsPage";
import AdminSettingPage from "./pagesAdmin/setting/AdminSettingPage";
import AdminBillingPage from "./pagesAdmin/billing/AdminBillingPage";
import AdminPracticesAdd from "./pagesAdmin/practices/AdminPracticesAdd";
import AdminPracticesDetails from "./pagesAdmin/practices/AdminPracticesDetails";
import ScrollToTop from "./components/common/ScrollToTop";
import AdminPracticesEdit from "./pagesAdmin/practices/AdminPracticesEdit";
import AdminPractitionersAdd from "./pagesAdmin/practitioners/AdminPractitionersAdd";
import AdminPractitionersDetails from "./pagesAdmin/practitioners/AdminPractitionersDetails";
import AdminPractitionersEdit from "./pagesAdmin/practitioners/AdminPractitionersEdit";
import MapPage from "./pages/map/MapPage";
import MasterAdminPrivateRoute from "./context/MasterAdminPrivateRoute";
import MasterAdminHomePage from "./pagesMasterAdmin/home/MasterAdminHomePage";
import MasterAdminSubscribersList from "./pagesMasterAdmin/subscribers/MasterAdminSubscribersList";
import MasterAdminClientsList from "./pagesMasterAdmin/clients/MasterAdminClientsList";
import FrontendPracticesDetailPage from "./pages/practices/FrontendPracticesDetailPage";
import FrontendPractitionerDetailPage from "./pages/practitioner/FrontendPractitionerDetailPage";
import FindFormPage from "./pages/find/FindFormPage";
import MasterAdminLeadsList from "./pagesMasterAdmin/leads/MasterAdminLeadsList";
import MasterAdminLeadsDetails from "./pagesMasterAdmin/leads/MasterAdminLeadsDetails";

function App() {
  const basename = process.env.REACT_APP_SUB_FOLDER || "/";

  return (
    <BrowserRouter basename={basename}>
      <AuthProvider>
        <ScrollToTop />
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route exact path="/about" element={<AboutPage />} />
          <Route exact path="/contact" element={<ContactPage />} />
          <Route exact path="/register" element={<RegisterPage />} />
          <Route exact path="/login" element={<LoginPage />} />
          <Route
            exact
            path="/forgotpassword"
            element={<ForgotPasswordPage />}
          />
          <Route exact path="/resetpassword" element={<ResetPasswordPage />} />

          <Route exact path="/find" element={<FindPage />} />
          <Route exact path="/pricing" element={<PricingPage />} />
          {/* <Route exact path="/list" element={<ListPage />} /> */}
          <Route exact path="/map" element={<MapPage />} />

          <Route
            exact
            path="/terms-conditions"
            element={<TermConditionsPage />}
          />
          <Route exact path="/privacy-policy" element={<PrivacyPolicyPage />} />

          <Route exact path="/find/:service_slug" element={<FindFormPage />} />

          <Route exact path="/:postcode/:slug" element={<ListPage />} />

          <Route exact path="/:postcode/:slug/:practice_name_slug" element={<FrontendPracticesDetailPage />} />

          <Route exact path="/:postcode/:slug/:practice_name_slug/:practitioner_name_slug" element={<FrontendPractitionerDetailPage />} />

          <Route
            exact
            path="/admin"
            element={
              <PrivateRoute>
                <AdminHomePage />
              </PrivateRoute>
            }
          />

          <Route
            exact
            path="/admin/leads"
            element={
              <PrivateRoute>
                <AdminLeadsPage />
              </PrivateRoute>
            }
          />

          <Route
            exact
            path="/admin/setting"
            element={
              <PrivateRoute>
                <AdminSettingPage />
              </PrivateRoute>
            }
          />

          <Route
            exact
            path="/admin/billing"
            element={
              <PrivateRoute>
                <AdminBillingPage />
              </PrivateRoute>
            }
          />

          {/* Practices  */}
          <Route
            exact
            path="/admin/practices-add"
            element={
              <PrivateRoute>
                <AdminPracticesAdd />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/admin/practices-edit/:practices_id"
            element={
              <PrivateRoute>
                <AdminPracticesEdit />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/admin/practices-details/:practices_id"
            element={
              <PrivateRoute>
                <AdminPracticesDetails />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/admin/practices-practitioners-add/:practices_id"
            element={
              <PrivateRoute>
                <AdminPractitionersAdd />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/admin/practices-practitioners-edit/:practices_id"
            element={
              <PrivateRoute>
                <AdminPractitionersEdit />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/admin/practices-practitioners-details/:practices_id"
            element={
              <PrivateRoute>
                <AdminPractitionersDetails />
              </PrivateRoute>
            }
          />

          {/* Master Admin Routes  */}
          
          <Route
            exact
            path="/masteradmin"
            element={
              <MasterAdminPrivateRoute>
                <MasterAdminHomePage />
              </MasterAdminPrivateRoute>
            }
          />
          <Route
            exact
            path="/masteradmin/clients"
            element={
              <MasterAdminPrivateRoute>
                <MasterAdminClientsList />
              </MasterAdminPrivateRoute>
            }
          />
          <Route
            exact
            path="/masteradmin/subscribers"
            element={
              <MasterAdminPrivateRoute>
                <MasterAdminSubscribersList />
              </MasterAdminPrivateRoute>
            }
          />
          <Route
            exact
            path="/masteradmin/leads"
            element={
              <MasterAdminPrivateRoute>
                <MasterAdminLeadsList />
              </MasterAdminPrivateRoute>
            }
          />
          <Route
            exact
            path="/masteradmin/leads/:leads_id"
            element={
              <MasterAdminPrivateRoute>
                <MasterAdminLeadsDetails />
              </MasterAdminPrivateRoute>
            }
          />


        </Routes>

        
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
