import React, { useEffect, useRef, useState } from "react";
import FrontendLayout from "../../layouts/FrontendLayout";
import { Link } from "react-router-dom";
import { API_BASE_URL, fetchData } from "../../api/api";

const ContactPage = () => {


    return (
        <>
            <FrontendLayout>
                <section>
                    <div className="container mx-auto px-3">
                        <h1 className="text-[28px] leading-relaxed sm:text-[39px] sm:leading-[59px] text-dark font-bold text-center my-6 sm:my-8 md:my-12 xl:my-[50px]">Contact Us</h1>
                        <div className="bg-white rounded-[18px] shadow-card py-10 sm:py-12 px-7 sm:px-14 md:px-[110px] mb-[60px] md:mb-[100px]">
                            <div className="grid grid-cols-1 lg:grid-cols-2 gap-[15px] sm:gap-[24px] lg:gap-[30px]">
                                <div>
                                    <div className="lg:max-w-[410px] w-100">
                                        <h2 className="text-dark text-2xl font-semibold mb-3">How may we assist you?</h2>
                                        <p className="text-dark text-lg font-normal mb-[34px]">Fill in the form or drop an email!</p>
                                        {/* <div className="border border-[#c2c2c2] rounded-lg px-4 sm:px-5 py-2 sm:py-4 flex gap-3 sm:gap-4 items-center mb-3">
                                            <img src="/assets/image/svg/phoneicon.svg" />
                                                <span className="font-medium text-base sm:text-xl text-dark">+61 00123 4567</span>
                                        </div> */}
                                        <div className="border border-[#c2c2c2] rounded-lg px-4 sm:px-5 py-2 sm:py-4 flex gap-3 sm:gap-4 items-center mb-3">
                                            <img src="/assets/image/svg/mailicon.svg" />
                                                <a href="mailto:care@alliedhealth.com.au" className="font-medium text-base sm:text-xl text-dark">care@alliedhealth.com.au</a>
                                        </div>
                                        <div className="border border-[#c2c2c2] rounded-lg px-4 sm:px-5 py-2 sm:py-4 flex gap-3 sm:gap-4 items-center mb-3">
                                            <img src="/assets/image/svg/locationicon.svg" className="mb-auto" />
                                                <span className="font-medium text-base sm:text-xl text-dark">
                                                    Suite 703, 155 King Street Sydney NSW 2000
                                                </span>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="mb-4">
                                        <label htmlFor="lastname" className="mb-2 sm:mb-4 inline-block text-dark font-semibold text-xl">Your name</label>
                                        <input type="text" name="" id="lastname" className="border-[1.2px] border-dark w-full h-12 sm:h-14 md:h-[66px] rounded-xl px-4 sm:px-7 focus:border-blue outline-none text-lg sm:text-xl" placeholder="Enter your name" />
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="lastname" className="mb-2 sm:mb-4 inline-block text-dark font-semibold text-xl">Email</label>
                                        <input type="text" name="" id="lastname" className="border-[1.2px] border-dark w-full h-12 sm:h-14 md:h-[66px] rounded-xl px-4 sm:px-7 focus:border-blue outline-none text-lg sm:text-xl" placeholder="Enter your email" />
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="lastname" className="mb-2 sm:mb-4 inline-block text-dark font-semibold text-xl">Subject</label>
                                        <input type="text" name="" id="lastname" className="border-[1.2px] border-dark w-full h-12 sm:h-14 md:h-[66px] rounded-xl px-4 sm:px-7 focus:border-blue outline-none text-lg sm:text-xl" placeholder="Enter subject" />
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="description" className="mb-2 sm:mb-4 inline-block text-dark font-semibold text-xl">Message</label>
                                        <textarea name="" id="description" placeholder="Description" className="border-[1.2px] border-dark w-full h-12 sm:h-14 md:h-[66px] rounded-xl py-4 px-4 sm:px-7 focus:border-blue outline-none text-lg sm:text-xl min-h-36"></textarea>
                                    </div>
                                    <button type="submit" id="register" className="h-auto py-3 px-3 sm:h-14 md:h-[66px] bg-blue w-full text-center text-white font-semibold text-lg sm:text-2xl rounded-xl mb-5 sm:mb-[30px]">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </FrontendLayout>
        </>)
}

export default ContactPage