import React, { useContext, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";
import api from "../../../api/api";
import { toast } from "react-toastify";

const UsersListTab = ({ }) => {

    return (
        <>
            <div className="flex items-center flex-wrap gap-3 mb-5">
                <ul className="flex gap-2 text-base font-medium text-dark ">
                    <li>
                        <a href="#" className="text-blue">Settings</a>
                    </li>
                    <li>/</li>
                    <li><span>User</span></li>
                </ul>
                <button className="py-3 px-4 sm:px-8 border-green  border-2 border-solid bg-green text-white rounded-[8px] font-medium sm:font-semibold text-sm sm:text-base leading-none text-center text-nowrap flex gap-3 w-fit ml-auto items-center" id="adduserbtn">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.0001 10.9999L22.0002 10.9997V12.9997L13.0001 12.9999V21.9998H11.0001V12.9999L2.00004 13.0001L2 11.0001L11.0001 10.9999L11 2.00025L13 2.00024L13.0001 10.9999Z" fill="white" />
                    </svg>
                    Add User
                </button>
            </div>
            <div className="overflow-x-auto">
                <table className="w-full">
                    <thead className="bg-lightblue">
                        <tr>
                            <th className="py-2 px-1.5 sm:py-4 sm:px-3 text-base text-blue font-semibold text-start min-w-[67px] sm:min-w-[90px] w-[90px]">Photo</th>
                            <th className="py-2 px-1.5 sm:py-4 sm:px-3 text-base text-blue font-semibold text-start min-w-48">First Name</th>
                            <th className="py-2 px-1.5 sm:py-4 sm:px-3 text-base text-blue font-semibold text-start min-w-48">Last Name</th>
                            <th className="py-2 px-1.5 sm:py-4 sm:px-3 text-base text-blue font-semibold text-start min-w-48">Email Id (User Name)</th>
                            <th className="py-2 px-1.5 sm:py-4 sm:px-3 text-base text-blue font-semibold text-start min-w-48">Reset Password</th>
                            <th className="py-2 px-1.5 sm:py-4 sm:px-3 text-base text-blue font-semibold text-start min-w-[90px] lg:min-w-[150px] ">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="">
                            <td className="py-2 px-1.5 sm:py-4 sm:px-3 border-t-2 border-solid border-[#dfdfdf]">
                                <img src="/assets/image/Physiotherapist-1.png" alt="" srcSet="" className="h-[50px] w-[50px] sm:h-[70px] sm:w-[70px] rounded" />
                            </td>
                            <td className="py-2 px-1.5 sm:py-4 sm:px-3 border-t-2 border-solid border-[#dfdfdf]">
                                <span className="text-dark text-base font-medium">Mr. Leroy</span>
                            </td>
                            <td className="py-2 px-1.5 sm:py-4 sm:px-3 border-t-2 border-solid border-[#dfdfdf]">
                                <span className="text-dark text-base font-medium">Axelsen</span>
                            </td>
                            <td className="py-2 px-1.5 sm:py-4 sm:px-3 border-t-2 border-solid border-[#dfdfdf]">
                                <span className="text-dark text-base font-medium">Axelsen@gmail.com</span>
                            </td>
                            <td className="py-2 px-1.5 sm:py-4 sm:px-3 border-t-2 border-solid border-[#dfdfdf]">
                                <a href="javascript:void(0)" className="text-blue text-base font-medium underline underline-offset-2 edituserbtn">Reset Password</a>
                            </td>
                            <td className="py-2 px-1.5 sm:py-4 sm:px-3 border-t-2 border-solid border-[#dfdfdf]">
                                <div className="flex rounded-full shadow-xs bg-blue max-w-[120px] w-full" role="group">
                                    <button type="button" className="pl-6 pr-3 py-2 text-sm font-medium  bg-transparent rounded-s-full edituserbtn">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5 18.89H6.41421L15.7279 9.57628L14.3137 8.16207L5 17.4758V18.89ZM21 20.89H3V16.6473L16.435 3.21232C16.8256 2.8218 17.4587 2.8218 17.8492 3.21232L20.6777 6.04075C21.0682 6.43127 21.0682 7.06444 20.6777 7.45496L9.24264 18.89H21V20.89ZM15.7279 6.74786L17.1421 8.16207L18.5563 6.74786L17.1421 5.33364L15.7279 6.74786Z" fill="white"></path>
                                        </svg>
                                    </button>
                                    <button type="button" className="pl-3 pr-6 py-2 text-sm font-medium  bg-transparent rounded-e-full deletebtn">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7 4V2H17V4H22V6H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V6H2V4H7ZM6 6V20H18V6H6ZM9 9H11V17H9V9ZM13 9H15V17H13V9Z" fill="white"></path>
                                        </svg>
                                    </button>
                                </div>
                            </td>
                        </tr>
                        <tr className="">
                            <td className="py-2 px-1.5 sm:py-4 sm:px-3 border-t-2 border-solid border-[#dfdfdf]">
                                <img src="/assets/image/Physiotherapist-2.png" alt="" srcSet="" className="h-[50px] w-[50px] sm:h-[70px] sm:w-[70px] rounded" />
                            </td>
                            <td className="py-2 px-1.5 sm:py-4 sm:px-3 border-t-2 border-solid border-[#dfdfdf]">
                                <span className="text-dark text-base font-medium">Dr. Velma</span>
                            </td>
                            <td className="py-2 px-1.5 sm:py-4 sm:px-3 border-t-2 border-solid border-[#dfdfdf]">
                                <span className="text-dark text-base font-medium">Kesten</span>
                            </td>
                            <td className="py-2 px-1.5 sm:py-4 sm:px-3 border-t-2 border-solid border-[#dfdfdf]">
                                <span className="text-dark text-base font-medium ">velmakesten@gmail.com</span>
                            </td>
                            <td className="py-2 px-1.5 sm:py-4 sm:px-3 border-t-2 border-solid border-[#dfdfdf]">
                                <a href="javascript:void(0)" className="text-blue text-base font-medium underline underline-offset-2 edituserbtn">Reset Password</a>
                            </td>
                            <td className="py-2 px-1.5 sm:py-4 sm:px-3 border-t-2 border-solid border-[#dfdfdf]">
                                <div className="flex rounded-full shadow-xs bg-blue max-w-[120px] w-full" role="group">
                                    <button type="button" className="pl-6 pr-3 py-2 text-sm font-medium  bg-transparent rounded-s-full edituserbtn">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5 18.89H6.41421L15.7279 9.57628L14.3137 8.16207L5 17.4758V18.89ZM21 20.89H3V16.6473L16.435 3.21232C16.8256 2.8218 17.4587 2.8218 17.8492 3.21232L20.6777 6.04075C21.0682 6.43127 21.0682 7.06444 20.6777 7.45496L9.24264 18.89H21V20.89ZM15.7279 6.74786L17.1421 8.16207L18.5563 6.74786L17.1421 5.33364L15.7279 6.74786Z" fill="white"></path>
                                        </svg>
                                    </button>
                                    <button type="button" className="pl-3 pr-6 py-2 text-sm font-medium  bg-transparent rounded-e-full deletebtn">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7 4V2H17V4H22V6H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V6H2V4H7ZM6 6V20H18V6H6ZM9 9H11V17H9V9ZM13 9H15V17H13V9Z" fill="white"></path>
                                        </svg>
                                    </button>
                                </div>
                            </td>
                        </tr>
                        <tr className="">
                            <td className="py-2 px-1.5 sm:py-4 sm:px-3 border-t-2 border-solid border-[#dfdfdf]">
                                <img src="/assets/image/Physiotherapist-3.png" alt="" srcSet="" className="h-[50px] w-[50px] sm:h-[70px] sm:w-[70px] rounded" />
                            </td>
                            <td className="py-2 px-1.5 sm:py-4 sm:px-3 border-t-2 border-solid border-[#dfdfdf]">
                                <span className="text-dark text-base font-medium">Mr. Erika</span>
                            </td>
                            <td className="py-2 px-1.5 sm:py-4 sm:px-3 border-t-2 border-solid border-[#dfdfdf]">
                                <span className="text-dark text-base font-medium">Trumper</span>
                            </td>
                            <td className="py-2 px-1.5 sm:py-4 sm:px-3 border-t-2 border-solid border-[#dfdfdf]">
                                <span className="text-dark text-base font-medium">trumpererika@gmail.com</span>
                            </td>
                            <td className="py-2 px-1.5 sm:py-4 sm:px-3 border-t-2 border-solid border-[#dfdfdf]">
                                <a href="javascript:void(0)" className="text-blue text-base font-medium underline underline-offset-2 edituserbtn">Reset Password</a>
                            </td>
                            <td className="py-2 px-1.5 sm:py-4 sm:px-3 border-t-2 border-solid border-[#dfdfdf]">
                                <div className="flex rounded-full shadow-xs bg-blue max-w-[120px] w-full" role="group">
                                    <button type="button" className="pl-6 pr-3 py-2 text-sm font-medium  bg-transparent rounded-s-full edituserbtn">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5 18.89H6.41421L15.7279 9.57628L14.3137 8.16207L5 17.4758V18.89ZM21 20.89H3V16.6473L16.435 3.21232C16.8256 2.8218 17.4587 2.8218 17.8492 3.21232L20.6777 6.04075C21.0682 6.43127 21.0682 7.06444 20.6777 7.45496L9.24264 18.89H21V20.89ZM15.7279 6.74786L17.1421 8.16207L18.5563 6.74786L17.1421 5.33364L15.7279 6.74786Z" fill="white"></path>
                                        </svg>
                                    </button>
                                    <button type="button" className="pl-3 pr-6 py-2 text-sm font-medium  bg-transparent rounded-e-full deletebtn">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7 4V2H17V4H22V6H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V6H2V4H7ZM6 6V20H18V6H6ZM9 9H11V17H9V9ZM13 9H15V17H13V9Z" fill="white"></path>
                                        </svg>
                                    </button>
                                </div>
                            </td>
                        </tr>
                        <tr className="">
                            <td className="py-2 px-1.5 sm:py-4 sm:px-3 border-t-2 border-solid border-[#dfdfdf]">
                                <img src="/assets/image/Physiotherapist-4.png" alt="" srcSet="" className="h-[50px] w-[50px] sm:h-[70px] sm:w-[70px] rounded" />
                            </td>
                            <td className="py-2 px-1.5 sm:py-4 sm:px-3 border-t-2 border-solid border-[#dfdfdf]">
                                <span className="text-dark text-base font-medium">Mr. Trevor</span>
                            </td>
                            <td className="py-2 px-1.5 sm:py-4 sm:px-3 border-t-2 border-solid border-[#dfdfdf]">
                                <span className="text-dark text-base font-medium">Banes</span>
                            </td>
                            <td className="py-2 px-1.5 sm:py-4 sm:px-3 border-t-2 border-solid border-[#dfdfdf]">
                                <span className="text-dark text-base font-medium">banestrevor@gmail.com</span>
                            </td>
                            <td className="py-2 px-1.5 sm:py-4 sm:px-3 border-t-2 border-solid border-[#dfdfdf]">
                                <a href="javascript:void(0)" className="text-blue text-base font-medium underline underline-offset-2 edituserbtn">Reset Password</a>
                            </td>
                            <td className="py-2 px-1.5 sm:py-4 sm:px-3 border-t-2 border-solid border-[#dfdfdf]">
                                <div className="flex rounded-full shadow-xs bg-blue max-w-[120px] w-full" role="group">
                                    <button type="button" className="pl-6 pr-3 py-2 text-sm font-medium  bg-transparent rounded-s-full edituserbtn">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5 18.89H6.41421L15.7279 9.57628L14.3137 8.16207L5 17.4758V18.89ZM21 20.89H3V16.6473L16.435 3.21232C16.8256 2.8218 17.4587 2.8218 17.8492 3.21232L20.6777 6.04075C21.0682 6.43127 21.0682 7.06444 20.6777 7.45496L9.24264 18.89H21V20.89ZM15.7279 6.74786L17.1421 8.16207L18.5563 6.74786L17.1421 5.33364L15.7279 6.74786Z" fill="white"></path>
                                        </svg>
                                    </button>
                                    <button type="button" className="pl-3 pr-6 py-2 text-sm font-medium  bg-transparent rounded-e-full deletebtn">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7 4V2H17V4H22V6H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V6H2V4H7ZM6 6V20H18V6H6ZM9 9H11V17H9V9ZM13 9H15V17H13V9Z" fill="white"></path>
                                        </svg>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div id="adduserform" className="fixed bg-[#0000007A] top-0 left-0 h-screen w-full z-[999] hidden justify-center items-center px-3">
                <div className="container mx-auto bg-white rounded-xl sm:rounded-2xl px-3 py-6 sm:py-10 lg:py-20 sm:px-10 lg:px-20 innercard">
                    <form action="">
                        <div className="grid grid-cols-2 gap-y-4 gap-x-[20px] lg:gap-x-[30px]">
                            <div className="col-span-2 sm:col-span-1">
                                <label htmlFor="firstname" className="mb-2 inline-block text-dark font-semibold text-base sm:text-xl">First name</label>
                                <input type="text" name="firstname" id="firstname" className="border-[1.2px] border-dark w-full h-12 sm:h-14 rounded-xl px-4 sm:px-7 focus:border-blue outline-none text-lg sm:text-xl" placeholder="Enter first name" />
                            </div>
                            <div className="col-span-2 sm:col-span-1">
                                <label htmlFor="lastname" className="mb-2 inline-block text-dark font-semibold text-base sm:text-xl">Last name</label>
                                <input type="text" name="lastname" id="lastname" className="border-[1.2px] border-dark w-full h-12 sm:h-14 rounded-xl px-4 sm:px-7 focus:border-blue outline-none text-lg sm:text-xl" placeholder="Enter last name" />
                            </div>
                            <div className="col-span-2 sm:col-span-1">
                                <label htmlFor="email" className="mb-2 inline-block text-dark font-semibold text-base sm:text-xl">Email</label>
                                <input type="email" name="email" id="email" className="border-[1.2px] border-dark w-full h-12 sm:h-14 rounded-xl px-4 sm:px-7 focus:border-blue outline-none text-lg sm:text-xl" placeholder="Enter your email address" />
                            </div>
                            <div className="col-span-2 sm:col-span-1">
                                <label htmlFor="createpassword" className="mb-2 inline-block text-dark font-semibold text-base sm:text-xl">Create Password</label>
                                <div className="relative">
                                    <input type="password" name="" id="createpassword" className="password-input border-[1.2px] border-dark w-full h-12 sm:h-14 rounded-xl px-4 sm:px-7 focus:border-blue outline-none text-lg sm:text-xl" placeholder="Enter password" />
                                    <a href="javascript:void(0)" className="password-toggle absolute top-1/2 right-5 -translate-y-2/4">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M17.8827 19.2968C16.1814 20.3755 14.1638 21.0002 12.0003 21.0002C6.60812 21.0002 2.12215 17.1204 1.18164 12.0002C1.61832 9.62283 2.81932 7.5129 4.52047 5.93457L1.39366 2.80777L2.80788 1.39355L22.6069 21.1925L21.1927 22.6068L17.8827 19.2968ZM5.9356 7.3497C4.60673 8.56015 3.6378 10.1672 3.22278 12.0002C4.14022 16.0521 7.7646 19.0002 12.0003 19.0002C13.5997 19.0002 15.112 18.5798 16.4243 17.8384L14.396 15.8101C13.7023 16.2472 12.8808 16.5002 12.0003 16.5002C9.51498 16.5002 7.50026 14.4854 7.50026 12.0002C7.50026 11.1196 7.75317 10.2981 8.19031 9.60442L5.9356 7.3497ZM12.9139 14.328L9.67246 11.0866C9.5613 11.3696 9.50026 11.6777 9.50026 12.0002C9.50026 13.3809 10.6196 14.5002 12.0003 14.5002C12.3227 14.5002 12.6309 14.4391 12.9139 14.328ZM20.8068 16.5925L19.376 15.1617C20.0319 14.2268 20.5154 13.1586 20.7777 12.0002C19.8603 7.94818 16.2359 5.00016 12.0003 5.00016C11.1544 5.00016 10.3329 5.11773 9.55249 5.33818L7.97446 3.76015C9.22127 3.26959 10.5793 3.00016 12.0003 3.00016C17.3924 3.00016 21.8784 6.87993 22.8189 12.0002C22.5067 13.6998 21.8038 15.2628 20.8068 16.5925ZM11.7229 7.50857C11.8146 7.50299 11.9071 7.50016 12.0003 7.50016C14.4855 7.50016 16.5003 9.51488 16.5003 12.0002C16.5003 12.0933 16.4974 12.1858 16.4919 12.2775L11.7229 7.50857Z" fill="#393939"></path>
                                            ';
                                        </svg>
                                    </a>
                                </div>
                            </div>
                            <div className="col-span-2 sm:col-span-1">
                                <label htmlFor="confirmpassword" className="mb-2 inline-block text-dark font-semibold text-base sm:text-xl">Confirm Password</label>
                                <div className="relative">
                                    <input type="password" name="" id="confirmpassword" className="password-input border-[1.2px] border-dark w-full h-12 sm:h-14 rounded-xl px-4 sm:px-7 focus:border-blue outline-none text-lg sm:text-xl" placeholder="Enter confirm password" />
                                    <a href="javascript:void(0)" className="password-toggle absolute top-1/2 right-5 -translate-y-2/4">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M17.8827 19.2968C16.1814 20.3755 14.1638 21.0002 12.0003 21.0002C6.60812 21.0002 2.12215 17.1204 1.18164 12.0002C1.61832 9.62283 2.81932 7.5129 4.52047 5.93457L1.39366 2.80777L2.80788 1.39355L22.6069 21.1925L21.1927 22.6068L17.8827 19.2968ZM5.9356 7.3497C4.60673 8.56015 3.6378 10.1672 3.22278 12.0002C4.14022 16.0521 7.7646 19.0002 12.0003 19.0002C13.5997 19.0002 15.112 18.5798 16.4243 17.8384L14.396 15.8101C13.7023 16.2472 12.8808 16.5002 12.0003 16.5002C9.51498 16.5002 7.50026 14.4854 7.50026 12.0002C7.50026 11.1196 7.75317 10.2981 8.19031 9.60442L5.9356 7.3497ZM12.9139 14.328L9.67246 11.0866C9.5613 11.3696 9.50026 11.6777 9.50026 12.0002C9.50026 13.3809 10.6196 14.5002 12.0003 14.5002C12.3227 14.5002 12.6309 14.4391 12.9139 14.328ZM20.8068 16.5925L19.376 15.1617C20.0319 14.2268 20.5154 13.1586 20.7777 12.0002C19.8603 7.94818 16.2359 5.00016 12.0003 5.00016C11.1544 5.00016 10.3329 5.11773 9.55249 5.33818L7.97446 3.76015C9.22127 3.26959 10.5793 3.00016 12.0003 3.00016C17.3924 3.00016 21.8784 6.87993 22.8189 12.0002C22.5067 13.6998 21.8038 15.2628 20.8068 16.5925ZM11.7229 7.50857C11.8146 7.50299 11.9071 7.50016 12.0003 7.50016C14.4855 7.50016 16.5003 9.51488 16.5003 12.0002C16.5003 12.0933 16.4974 12.1858 16.4919 12.2775L11.7229 7.50857Z" fill="#393939"></path>
                                            ';
                                        </svg>
                                    </a>
                                </div>
                            </div>
                            <br className="hidden sm:block" />
                            <div className="col-span-2 sm:col-span-1">
                                <button type="submit" className="h-auto py-3 px-3 sm:h-14 bg-blue w-full text-center text-white font-semibold text-lg sm:text-2xl rounded-xl sm:mt-4">Save & Add User</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div id="edituserform" className="fixed bg-[#0000007A] top-0 left-0 h-screen w-full z-[999] hidden justify-center items-center px-3">
                <div className="container mx-auto bg-white rounded-xl sm:rounded-2xl px-3 py-6 sm:py-10 lg:py-20 sm:px-10 lg:px-20 innercard">
                    <form action="">
                        <div className="grid grid-cols-2 gap-y-4 gap-x-[20px] lg:gap-x-[30px]">
                            <div className="col-span-2 sm:col-span-1 ">
                                <label htmlFor="email" className="mb-2 inline-block text-dark font-semibold text-base sm:text-xl">Email</label>
                                <input type="email" name="email" id="email" className="border-[1.2px] border-dark w-full h-12 sm:h-14 rounded-xl px-4 sm:px-7 focus:border-blue outline-none text-lg sm:text-xl" placeholder="Enter your email address" />
                            </div>
                            <div className="col-span-2 sm:col-span-1 flex items-end">
                                <button type="submit" className="h-auto py-3 px-3 sm:h-14 bg-blue w-full text-center text-white font-semibold text-lg sm:text-2xl rounded-xl ">Send Reset Password Link</button>
                            </div>
                            <div className="col-span-2 sm:col-span-1">
                                <label htmlFor="createpassword" className="mb-2 inline-block text-dark font-semibold text-base sm:text-xl">Create Password</label>
                                <div className="relative">
                                    <input type="password" name="" id="createpassword" className="password-input border-[1.2px] border-dark w-full h-12 sm:h-14 rounded-xl px-4 sm:px-7 focus:border-blue outline-none text-lg sm:text-xl" placeholder="Enter password" />
                                    <a href="javascript:void(0)" className="password-toggle absolute top-1/2 right-5 -translate-y-2/4">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M17.8827 19.2968C16.1814 20.3755 14.1638 21.0002 12.0003 21.0002C6.60812 21.0002 2.12215 17.1204 1.18164 12.0002C1.61832 9.62283 2.81932 7.5129 4.52047 5.93457L1.39366 2.80777L2.80788 1.39355L22.6069 21.1925L21.1927 22.6068L17.8827 19.2968ZM5.9356 7.3497C4.60673 8.56015 3.6378 10.1672 3.22278 12.0002C4.14022 16.0521 7.7646 19.0002 12.0003 19.0002C13.5997 19.0002 15.112 18.5798 16.4243 17.8384L14.396 15.8101C13.7023 16.2472 12.8808 16.5002 12.0003 16.5002C9.51498 16.5002 7.50026 14.4854 7.50026 12.0002C7.50026 11.1196 7.75317 10.2981 8.19031 9.60442L5.9356 7.3497ZM12.9139 14.328L9.67246 11.0866C9.5613 11.3696 9.50026 11.6777 9.50026 12.0002C9.50026 13.3809 10.6196 14.5002 12.0003 14.5002C12.3227 14.5002 12.6309 14.4391 12.9139 14.328ZM20.8068 16.5925L19.376 15.1617C20.0319 14.2268 20.5154 13.1586 20.7777 12.0002C19.8603 7.94818 16.2359 5.00016 12.0003 5.00016C11.1544 5.00016 10.3329 5.11773 9.55249 5.33818L7.97446 3.76015C9.22127 3.26959 10.5793 3.00016 12.0003 3.00016C17.3924 3.00016 21.8784 6.87993 22.8189 12.0002C22.5067 13.6998 21.8038 15.2628 20.8068 16.5925ZM11.7229 7.50857C11.8146 7.50299 11.9071 7.50016 12.0003 7.50016C14.4855 7.50016 16.5003 9.51488 16.5003 12.0002C16.5003 12.0933 16.4974 12.1858 16.4919 12.2775L11.7229 7.50857Z" fill="#393939"></path>
                                            ';
                                        </svg>
                                    </a>
                                </div>
                            </div>
                            <div className="col-span-2 sm:col-span-1">
                                <label htmlFor="confirmpassword" className="mb-2 inline-block text-dark font-semibold text-base sm:text-xl">Confirm Password</label>
                                <div className="relative">
                                    <input type="password" name="" id="confirmpassword" className="password-input border-[1.2px] border-dark w-full h-12 sm:h-14 rounded-xl px-4 sm:px-7 focus:border-blue outline-none text-lg sm:text-xl" placeholder="Enter confirm password" />
                                    <a href="javascript:void(0)" className="password-toggle absolute top-1/2 right-5 -translate-y-2/4">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M17.8827 19.2968C16.1814 20.3755 14.1638 21.0002 12.0003 21.0002C6.60812 21.0002 2.12215 17.1204 1.18164 12.0002C1.61832 9.62283 2.81932 7.5129 4.52047 5.93457L1.39366 2.80777L2.80788 1.39355L22.6069 21.1925L21.1927 22.6068L17.8827 19.2968ZM5.9356 7.3497C4.60673 8.56015 3.6378 10.1672 3.22278 12.0002C4.14022 16.0521 7.7646 19.0002 12.0003 19.0002C13.5997 19.0002 15.112 18.5798 16.4243 17.8384L14.396 15.8101C13.7023 16.2472 12.8808 16.5002 12.0003 16.5002C9.51498 16.5002 7.50026 14.4854 7.50026 12.0002C7.50026 11.1196 7.75317 10.2981 8.19031 9.60442L5.9356 7.3497ZM12.9139 14.328L9.67246 11.0866C9.5613 11.3696 9.50026 11.6777 9.50026 12.0002C9.50026 13.3809 10.6196 14.5002 12.0003 14.5002C12.3227 14.5002 12.6309 14.4391 12.9139 14.328ZM20.8068 16.5925L19.376 15.1617C20.0319 14.2268 20.5154 13.1586 20.7777 12.0002C19.8603 7.94818 16.2359 5.00016 12.0003 5.00016C11.1544 5.00016 10.3329 5.11773 9.55249 5.33818L7.97446 3.76015C9.22127 3.26959 10.5793 3.00016 12.0003 3.00016C17.3924 3.00016 21.8784 6.87993 22.8189 12.0002C22.5067 13.6998 21.8038 15.2628 20.8068 16.5925ZM11.7229 7.50857C11.8146 7.50299 11.9071 7.50016 12.0003 7.50016C14.4855 7.50016 16.5003 9.51488 16.5003 12.0002C16.5003 12.0933 16.4974 12.1858 16.4919 12.2775L11.7229 7.50857Z" fill="#393939"></path>
                                            ';
                                        </svg>
                                    </a>
                                </div>
                            </div>
                            <div className="col-span-2 sm:col-span-1">
                                <button type="submit" className="h-auto py-3 px-3 sm:h-14 bg-blue w-full text-center text-white font-semibold text-lg sm:text-2xl rounded-xl sm:mt-4">Reset Password</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div id="deletepopup" className="fixed bg-[#0000007A] top-0 left-0 h-screen w-full z-[999] hidden justify-center items-center px-3">
                <div className="max-w-[630px] w-full mx-auto bg-white rounded-xl sm:rounded-2xl px-3 py-6 sm:py-10 sm:px-10 lg:px-20 innercard">
                    <div className="max-w-[312px] w-full flex justify-center items-center gap-6 flex-col mx-auto">
                        <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M36 66C19.4314 66 6 52.5684 6 36C6 19.4314 19.4314 6 36 6C52.5684 6 66 19.4314 66 36C66 52.5684 52.5684 66 36 66ZM36 60C49.2549 60 60 49.2549 60 36C60 22.7452 49.2549 12 36 12C22.7452 12 12 22.7452 12 36C12 49.2549 22.7452 60 36 60ZM36 31.7574L44.4852 23.2721L48.7278 27.5147L40.2426 36L48.7278 44.4852L44.4852 48.7278L36 40.2426L27.5147 48.7278L23.2721 44.4852L31.7574 36L23.2721 27.5147L27.5147 23.2721L36 31.7574Z" fill="#FF5252" />
                        </svg>
                        <h4 className="text-center font-bold text-xl mg:text-2xl text-blue">Are you sure! <br />You want to Delete the User?</h4>
                        <div className="flex justify-between gap-3 w-full">
                            <button className="bg-transparent text-center text-dark text-sm sm:text-base font-semibold sm:w-full sm:max-w-[154px] rounded-lg py-2 px-3 sm:py-3 sm:px-0 flex items-center justify-center border border-dark cancelbtn">Cancel</button>
                            <button className="bg-blue text-center text-white text-sm sm:text-base font-semibold sm:w-full sm:max-w-[154px] rounded-lg py-2 px-3 sm:py-3 sm:px-0 flex items-center justify-center border border-blue deletebtn">Delete</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default UsersListTab;
