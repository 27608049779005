import React, { useContext, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthContext } from "../context/AuthContext";
import { API_BASE_URL } from "../api/api";

const MasterAdminLayout = ({ children }) => {
    const location = useLocation();
    const currentPath = location.pathname + location.hash;

    const isActive = (path) => currentPath === path;

    const { user, logout } = useContext(AuthContext);

    const [isDropdownVisible, setIsDropdownVisible] = useState(false);

    const toggleDropdown = () => {
        setIsDropdownVisible((prev) => !prev);
    };

    const [isNotificationDropdownVisible, setIsNotificationDropdownVisible] = useState(false);

    const toggleNotificationDropdown = () => {
        setIsNotificationDropdownVisible((prev) => !prev);
    };

    const [isSidebarVisible, setIsSidebarVisible] = useState(false);

    return (
        <>
            {/* <ToastContainer autoClose={120000} /> */}
            <ToastContainer autoClose={2000} />
            <header className="bg-lightblue sticky top-0 z-[100] shadow-header">
                <nav className=" flex items-center justify-between py-4 px-3" aria-label="Global">
                    <div className="flex mr-6 sm:mr-10 xl:mr-36">
                        <Link to={"/masteradmin"} className="-m-1.5 p-1.5">
                            <span className="sr-only">Allied Health</span>
                            <img className="h-14 w-auto max-w-[180px] lg:max-w-full" src="/assets/image/logo.svg" alt="webtualglobal" />
                        </Link>
                    </div>
                    <div className="flex lg:hidden lg:ml-auto order-3 lg:order-2">
                        <button type="button" className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                            id="menu-open-btn" onClick={() => setIsSidebarVisible(true)}>
                            <span className="sr-only">Open main menu</span>
                            <svg className="h-7 w-7" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor"
                                aria-hidden="true">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                            </svg>
                        </button>
                    </div>
                    <div className="hidden lg:flex gap-x-4 lg:gap-x-11 mr-auto navlink">
                        <Link to="/masteradmin" className={`font-poppins font-medium text-base ${isActive("/masteradmin") ? "active" : "text-dark"}`}>Home</Link>
                        <Link to="/masteradmin/leads" className={`font-poppins font-medium text-base ${isActive("/masteradmin/leads") ? "active" : "text-dark"}`}>Leads</Link>
                        <Link to="/masteradmin/clients" className={`font-poppins font-medium text-base ${isActive("/masteradmin/clients") ? "active" : "text-dark"}`}>Clients</Link>
                        <Link to="/masteradmin/subscribers" className={`font-poppins font-medium text-base ${isActive("/masteradmin/subscribers") ? "active" : "text-dark"}`}>Subscribers</Link>
                    </div>
                    <div className="flex order-2 lg:order-3 justify-end gap-x-3 items-center ml-auto lg:ml-0 mr-3">
                        <button
                            onClick={toggleNotificationDropdown}
                            id="notificationsbtn" className="relative ">
                            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-8 h-8 sm:w-10 sm:h-10">
                                <path d="M34.3824 26.6648C34.2527 26.5086 34.1253 26.3523 34.0003 26.2016C32.2816 24.1227 31.2418 22.868 31.2418 16.9828C31.2418 13.9359 30.5128 11.4359 29.0761 9.56094C28.0168 8.17578 26.5847 7.125 24.6972 6.34844C24.6729 6.33493 24.6512 6.3172 24.6332 6.29609C23.9543 4.02266 22.0964 2.5 20.0011 2.5C17.9058 2.5 16.0488 4.02266 15.3699 6.29375C15.3518 6.31408 15.3304 6.33125 15.3066 6.34453C10.9019 8.15781 8.76128 11.6367 8.76128 16.9805C8.76128 22.868 7.723 24.1227 6.00269 26.1992C5.87769 26.35 5.75035 26.5031 5.62066 26.6625C5.28566 27.0665 5.07341 27.558 5.00903 28.0789C4.94465 28.5997 5.03083 29.1281 5.25738 29.6016C5.73941 30.6172 6.76675 31.2477 7.93941 31.2477H32.0714C33.2386 31.2477 34.2589 30.618 34.7425 29.607C34.9701 29.1335 35.0571 28.6047 34.9932 28.0832C34.9294 27.5617 34.7174 27.0695 34.3824 26.6648Z" fill="#0052CC" />
                                <path d="M19.9995 37.5C21.1285 37.4991 22.2361 37.1926 23.205 36.6132C24.1738 36.0337 24.9678 35.2028 25.5027 34.2086C25.5279 34.1609 25.5403 34.1076 25.5388 34.0537C25.5373 33.9998 25.5218 33.9473 25.494 33.9011C25.4662 33.855 25.4269 33.8168 25.3799 33.7903C25.333 33.7638 25.28 33.7499 25.2261 33.75H14.7745C14.7206 33.7498 14.6675 33.7636 14.6204 33.79C14.5734 33.8164 14.534 33.8546 14.506 33.9008C14.4781 33.947 14.4626 33.9996 14.4611 34.0535C14.4595 34.1075 14.472 34.1609 14.4972 34.2086C15.032 35.2027 15.8258 36.0335 16.7945 36.613C17.7633 37.1924 18.8707 37.4989 19.9995 37.5Z" fill="#0052CC" />
                            </svg>
                            <span className="aspect-square flex items-center justify-center bg-red-600 text-white leading-none rounded-full border-2 border-solid border-lightblue absolute top-[-4px] right-[-4px] min-h-[25px]">!</span>
                        </button>
                        <div className="dropdownfilter  lg:mx-8 relative">
                            <button
                                onClick={toggleDropdown}
                                className="w-full flex justify-between items-center" aria-controls="headeraccountbtn"
                                aria-expanded={isDropdownVisible ? "true" : "false"}>
                                <img src={user?.profile_image && API_BASE_URL + user?.profile_image} alt="user" title="user" height="56" width="56" className="w-10 h-10 sm:h-14 sm:w-14 rounded-full border border-solid border-white aspect-square" />
                                <svg className={`dropdown-arrow transition-transform duration-300 ml-3 hidden sm:block ${isDropdownVisible ? "rotate-180" : ""
                                    }`} width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.636 13.828L19.1308 6.33337L21.2721 8.47471L11.636 18.1108L2 8.47471L4.14134 6.33337L11.636 13.828Z" fill="black"></path>
                                </svg>
                            </button>
                            <div
                                className={`border space-y-2 dropdownContentfilter fixed top-[88px] right-2 bg-white rounded-lg py-3 px-4 sm:py-6 sm:px-8 shadow-card w-[320px] sm:w-[430px] flex flex-wrap items-center gap-2 ${isDropdownVisible ? "" : "hidden"
                                    }`}
                                id="headeraccountbtn">
                                <div className="w-16">
                                    <img src={user?.profile_image && API_BASE_URL + user?.profile_image} alt="" className="w-16 h-16 rounded object-cover object-center" />
                                </div>
                                <div className="w-[calc(100%-72px)]">
                                    <h3 className="text-xl font-semibold text-dark">{user?.firstname} {user?.lastname}</h3>
                                    <p className="text-base font-semibold text-[#707070]">{user?.email}</p>
                                    {/* <Link to={'/admin/setting'} className="text-lg font-semibold text-blue underline underline-offset-2">User Profile</Link> */}
                                </div>
                                <div className="w-full">
                                    <button onClick={logout} className="flex flex-wrap gap-2 underline underline-offset-2 text-xl font-semibold text-dark">
                                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15 27.5C8.09644 27.5 2.5 21.9035 2.5 15C2.5 8.09644 8.09644 2.5 15 2.5C19.0891 2.5 22.7196 4.46347 25.0002 7.49904L21.6136 7.49914C19.8509 5.94374 17.5356 5 15 5C9.47715 5 5 9.47715 5 15C5 20.5229 9.47715 25 15 25C17.5363 25 19.852 24.0557 21.6149 22.4998H25.0011C22.7206 25.536 19.0896 27.5 15 27.5ZM23.75 20V16.25H13.75V13.75H23.75V10L30 15L23.75 20Z" fill="#0052CC" />
                                        </svg>
                                        Sign Out
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
                <div className="lg:hidden" role="dialog" aria-modal="true">
                    <div className="fixed inset-0 z-10 navsidebar bg-[#00000066]" id="menu-close-wrapper"></div>
                    <div className={`fixed inset-y-0 z-10 w-10/12 sm:w-full navsidebar overflow-y-auto px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10 navsidebar bg-white flex flex-col ${isSidebarVisible ? 'active' : ''}`}>
                        <div className="flex items-center justify-between">
                            <Link to={'/admin/'} className="-m-1.5 p-1.5">
                                <span className="sr-only">Allied Health</span>
                                <img className="h-8 w-auto" src="/assets/image/logo.svg" alt="webtualglobal" height="32" width="189" />
                            </Link>
                            <button type="button" className="-m-2.5 rounded-md p-2.5 text-gray-700" id="menu-close-btn" onClick={() => setIsSidebarVisible(false)}>
                                <span className="sr-only">Close menu</span>
                                <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor"
                                    aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>
                        <div className="mt-6 flow-root">
                            <div className="-my-6 divide-y divide-gray-500/10">
                                <div className="space-y-2 py-6">
                                    <Link to={'/masteradmin'} className={`-mx-3 block rounded-lg px-2 py-2 text-base text-plus font-semibold leading-7  hover:bg-gray-50 ${isActive("/masteradmin") ? "text-blue" : "text-gray-900"}`}>Home</Link>
                                    <Link to={'/masteradmin/leads'} className={`-mx-3 block rounded-lg px-2 py-2 text-base text-plus font-semibold leading-7 hover:bg-gray-50 ${isActive("/masteradmin/leads") ? "text-blue" : "text-gray-900"}`}>Leads</Link>
                                    <Link to={'/masteradmin/clients'} className={`-mx-3 block rounded-lg px-2 py-2 text-base text-plus font-semibold leading-7 hover:bg-gray-50 ${isActive("/masteradmin/clients") ? "text-blue" : "text-gray-900"}`}>Clients</Link>
                                    <Link to={'/masteradmin/subscribers'} className={`-mx-3 block rounded-lg px-2 py-2 text-base text-plus font-semibold leading-7 hover:bg-gray-50 ${isActive("/masteradmin/subscribers") ? "text-blue" : "text-gray-900"}`}>Subscribers</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div id="notificationssidebar" className="fixed top-[88px] bg-white border border-l-[#ccc] h-[calc(100vh-88px)] w-full max-w-[430px] transition-all duration-300 hidden -right-full"> */}
                <div id="notificationssidebar" className={`fixed top-[88px] bg-white border border-l-[#ccc] h-[calc(100vh-88px)] w-full max-w-[430px] transition-all duration-300 ${isNotificationDropdownVisible ? "right-0" : "hidden -right-full"
                    }`}>
                    <div className="flex gap-3 px-[18px] py-4">
                        <h2 className="text-xl font-semibold text-dark">Notifications</h2>
                        <button className="border border-solid border-blue rounded-full text-xs px-2.5 font-semibold text-blue ml-auto">Clear All</button>
                        <button id="closenotificationssidebar" onClick={toggleNotificationDropdown}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="12" cy="12" r="11.25" fill="white" stroke="black" strokeWidth="1.5" />
                                <path d="M11.6038 10.1363L16.7401 5L18.2076 6.4675L13.0712 11.6038L18.2076 16.74L16.7401 18.2075L11.6038 13.0713L6.46751 18.2075L5 16.74L10.1363 11.6038L5 6.4675L6.46751 5L11.6038 10.1363Z" fill="black" />
                            </svg>
                        </button>
                    </div>
                    <div className="overflow-y-auto h-[calc(100vh-88px-60px)] no-scrollbar z-10">
                        {/* <div className="px-[18px] py-2 border-y border-[#DFDFDF]">
                            <span className="text-[#7B7B7B] font-medium text-xs block text-end">31/01/2025</span>
                            <p className="text-dark text-sm">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry.</p>
                        </div> */}
                    </div>
                </div>
            </header>

            {children}
        </>
    )

};

export default MasterAdminLayout;
