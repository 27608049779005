import React, { useEffect, useRef, useState } from "react";
import FrontendLayout from "../../layouts/FrontendLayout";
import { Link, useNavigate, useParams } from "react-router-dom";
import { API_BASE_URL, fetchData } from "../../api/api";
import PracticeCard from "../../components/frontend/practices/PracticeCard";
import Spinner from "../../components/common/Spinner";
import Select from "react-select";
import { CONSTANT_GENDER, CONSTANT_REDIRECT_TIME } from "../../constant/common";
import { extractSlugDetails } from "../../utils/slug";
import { toast } from "react-toastify";
import CompareModal from "../../components/frontend/filter/CompareModal";


const ListPage = () => {
    const navigate = useNavigate();

    const { postcode,slug } = useParams();

    const { services, suburb } = extractSlugDetails(slug);

    const didFetch = useRef(false);

    const [isFilterTab, setIsFilterTab] = useState(false);

    const [searchList, setSearchList] = useState([]);

    const [selectedLanguages, setSelectedLanguages] = useState([]);
    const [selectedPaymentMethods, setSelectedPaymentMethods] = useState([]);
    const [selectedGender, setSelectedGender] = useState(null);

    const [postcodeData, setPostcodeData] = useState(null);
    const [suburbData, setSuburbData] = useState(null);
    const [serviceData, setServiceData] = useState(null);

    const [currentPage, setCurrentPage] = useState(1);

    const itemsPerPage = 10;

    const totalPages = Math.ceil(searchList.length / itemsPerPage);

    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    const paginatedData = searchList?.length > 0 ? searchList.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    ) : [];

    const [isLoading, setIsLoading] = useState(false);

    const [selectedFilter, setSelectedFilter] = useState("All");

    const [paymentMethodsList, setPaymentMethodsList] = useState([]);

    const [languagesList, setLanguagesList] = useState([]);

    const [selectedComparisons, setSelectedComparisons] = useState([]);

    const [isCompareModal, setIsCompareModal] = useState(false);

    const getSearchList = async (tab) => {
        setIsLoading(true);
        let is_practitioner = null;
        if (tab == "Practitioners") {
            is_practitioner = 1;
        }
        else if (tab == "Practices") {
            is_practitioner = 0;
        }
        else {
            is_practitioner = null;
        }

        try {
            const data = await fetchData('/get-frontend-practices', {
                status: 1,
                is_practitioner: is_practitioner,
                languages_ids: selectedLanguages.join(","),
                payment_methods_ids: selectedPaymentMethods.join(","),
                gender: selectedGender,
                services: services,
                postcode: postcode,
                suburb: suburb,
            });

            if (data.error === true) {
                navigate("/");
            }
            else {
                setSearchList(data?.practices ? data?.practices : []);
                setPostcodeData(data?.postcode ? data?.postcode : null);
                setSuburbData(data?.suburb ? data?.suburb : null);
                setServiceData(data?.services ? data?.services : null);

                setSelectedComparisons([]);
                setCurrentPage(1);
            }


        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const getPaymentMethods = async () => {
        try {
            const data = await fetchData('/get-master-practice-payment-methods', { status: 1 });
            setPaymentMethodsList(data);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {

        }
    };

    const getLanguages = async () => {
        try {
            const data = await fetchData('/get-languages', { status: 1 });
            setLanguagesList(data);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {

        }
    };

    useEffect(() => {
        if (!didFetch.current) {
            getSearchList(null);
            getPaymentMethods();
            getLanguages();
            didFetch.current = true;
            didFetch.filter = false;
        }
    }, []);

    useEffect(() => {
        if (didFetch.filter) {
            getSearchList(selectedFilter);
        }
    }, [selectedLanguages, selectedPaymentMethods, selectedGender]);

    useEffect(() => {
        if(selectedComparisons?.length === 0)
        {
            setIsCompareModal(false);
        }
    }, [selectedComparisons]);


    const handleTabChange = (tab) => {
        didFetch.filter = true;
        setSelectedFilter(tab)
        getSearchList(tab);
    }

    const handleGenderChange = (event) => {
        didFetch.filter = true;
        setSelectedGender(event.target.value);
    };

    const handleOnClickClearAll = () => {
        setSelectedLanguages([]);
        setSelectedPaymentMethods([]);
        setSelectedGender(null);
    };

    const filteredSearchList = searchList
        .filter((item) =>
            selectedComparisons.some((compareItem) => compareItem.practices_id === item.practices_id)
        )
        .sort(
            (a, b) =>
                selectedComparisons.findIndex((compareItem) => compareItem.practices_id === a.practices_id) -
                selectedComparisons.findIndex((compareItem) => compareItem.practices_id === b.practices_id)
        );


    if (isLoading) {
        return (<Spinner />);
    }

    return (
        <>
            <FrontendLayout>
                <section className="pt-6 sm:pt-8 md:pt-12 lg:pt-16 xl:pt-[70px] pb-[60px] md:pb-[100px]">
                    <div className="container px-3 mx-auto lg:!max-w-[1024px]">
                        {serviceData?.name &&
                            <div className="flex justify-center items-center gap-5 mb-4 md:mb-6">
                                <h1 className="text-xl sm:text-[28px] leading-normal text-dark font-normal lg:text-center">
                                    {serviceData?.name} in <span className="text-green font-bold">{suburbData?.name}, {postcodeData?.state_name} {postcodeData?.postcode}</span>
                                </h1>
                            </div>
                        }
                        <div className="flex flex-col sm:flex-row justify-around items-center gap-0 mb-4 md:mb-6 bg-white rounded">
                            <button
                                className={`px-4 py-2 sm:py-3 text-base sm:text-xl font-bold ${selectedFilter === "All" ? "text-blue" : "text-dark font-medium"
                                    }`}
                                onClick={() => handleTabChange("All")}
                            >
                                All
                            </button>
                            <button
                                className={`px-4 py-2 sm:py-3 text-base sm:text-xl font-bold ${selectedFilter === "Practitioners" ? "text-blue" : "text-dark font-medium"
                                    }`}
                                onClick={() => handleTabChange("Practitioners")}
                            >
                                Practitioners
                            </button>
                            <button
                                className={`px-4 py-2 sm:py-3 text-base sm:text-xl font-bold ${selectedFilter === "Practices" ? "text-blue" : "text-dark font-medium"
                                    }`}
                                onClick={() => handleTabChange("Practices")}
                            >
                                Practices
                            </button>
                        </div>
                        <div className="flex flex-wrap justify-between items-center mb-4 md:mb-6 bg-white rounded px-4 lg:px-6 py-2 gap-3">
                            <span className="text-base sm:text-lg text-dark font-normal order-1 ">
                                {searchList?.length > 0 && (
                                    <>
                                        {searchList.length}{" "}
                                        {selectedFilter === "Practices"
                                            ? "Practices "
                                            : `${serviceData?.name} ${selectedFilter === "All" ? "& Practices " : ""}`}
                                        found
                                    </>
                                )}


                            </span>
                            <div className="flex gap-1 flex-wrap justify-end md:order-2 ml-auto md:ml-0">
                                <button
                                    onClick={() => setIsFilterTab(!isFilterTab)}
                                    type="button"
                                    className="px-5 lg:px-7 py-1.5 bg-transparent text-dark border border-dark text-xs font-bold rounded border-solid flex gap-2 items-center" id="filter-open-btn">
                                    Filter
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.5 13.5H10.5V12H7.5V13.5ZM2.25 4.5V6H15.75V4.5H2.25ZM4.5 9.75H13.5V8.25H4.5V9.75Z" fill="black" />
                                    </svg>
                                </button>
                                <div className="" role="dialog" aria-modal="true">
                                    <div className="fixed inset-0 z-10 filtersidebar bg-[#00000066]" id="filter-close-wrapper"></div>
                                    <div className={`fixed inset-y-0 z-10 w-10/12 sm:w-full filtersidebar overflow-y-auto px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10 bg-white flex flex-col ${isFilterTab ? 'active' : ''}`}>
                                        <div className="flex items-center justify-between">
                                            <div className="-m-1.5 p-1.5">
                                                <span className="sr-only">Allied Health</span>
                                                <img className="h-8 w-auto" src="/assets/image/logo.svg" alt="webtualglobal" height="32" width="189" />
                                            </div>
                                            <button onClick={() => setIsFilterTab(false)}
                                                type="button" className="-m-2.5 rounded-md p-2.5 text-gray-700" id="filter-close-btn">
                                                <span className="sr-only">filter Close menu</span>
                                                <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                                                </svg>
                                            </button>
                                        </div>
                                        <div className="mt-16 flow-root">
                                            <div className="-my-6 divide-y divide-gray-500/10">
                                                <div className="py-6">
                                                    <div className="flex justify-between items-center mb-2">
                                                        <span className="text-2xl font-semibold text-dark ">
                                                            Filter
                                                        </span>
                                                        <button
                                                            onClick={handleOnClickClearAll}
                                                            type="button" className="text-lg  font-normal text-dark">Clear All</button>
                                                    </div>
                                                    <hr className=" -mx-6 mb-2" />
                                                    <div>
                                                        <label htmlFor="gender" className="text-lg font-semibold text-dark">
                                                            Gender
                                                        </label>

                                                        <div className="flex items-center gap-3">
                                                            {CONSTANT_GENDER?.map((genderItem, index) => (
                                                                <div key={index} className="flex items-center">
                                                                    <input name="gender" id={`radio_${index}`} type="radio" value={genderItem?.key} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded-full dark:bg-gray-700 dark:border-gray-600"
                                                                        checked={selectedGender ? selectedGender == genderItem?.key : false}
                                                                        onChange={handleGenderChange} />
                                                                    <label htmlFor={`radio_${index}`} className="ms-2 text-lg font-normal text-dark w-[calc(100%-20px)] cursor-pointer">
                                                                        {genderItem?.value}
                                                                    </label>
                                                                </div>
                                                            ))}
                                                        </div>

                                                    </div>
                                                    <hr className="-mx-6 my-2" />
                                                    <div>
                                                        <label htmlFor="practices_areas_ids" className="text-lg font-semibold text-dark">
                                                            Language
                                                        </label>

                                                        <Select
                                                            options={languagesList.map((item) => ({
                                                                value: item.languages_id,
                                                                label: item.name,
                                                            }))}
                                                            isMulti
                                                            className="w-full text-black custom-select"
                                                            placeholder="Select Languages"
                                                            value={languagesList
                                                                .filter((item) => selectedLanguages.includes(item.languages_id))
                                                                .map((item) => ({
                                                                    value: item.languages_id,
                                                                    label: item.name,
                                                                }))
                                                            }
                                                            onChange={(selectedOptions) => {
                                                                didFetch.filter = true;
                                                                setSelectedLanguages(selectedOptions.map((option) => option.value));
                                                            }}
                                                        />

                                                    </div>
                                                    <hr className="-mx-6 my-2" />
                                                    <div>
                                                        <label htmlFor="practices_areas_ids" className="text-lg font-semibold text-dark">
                                                            Billing
                                                        </label>

                                                        <Select
                                                            options={paymentMethodsList.map((item) => ({
                                                                value: item.practices_payment_methods_master_id,
                                                                label: item.name,
                                                            }))}
                                                            isMulti
                                                            className="w-full text-black custom-select"
                                                            placeholder="Select Billing"
                                                            value={paymentMethodsList
                                                                .filter((item) => selectedPaymentMethods.includes(item.practices_payment_methods_master_id))
                                                                .map((item) => ({
                                                                    value: item.practices_payment_methods_master_id,
                                                                    label: item.name,
                                                                }))
                                                            }
                                                            onChange={(selectedOptions) => {
                                                                didFetch.filter = true;
                                                                setSelectedPaymentMethods(selectedOptions.map((option) => option.value));
                                                            }}
                                                        />

                                                    </div>
                                                    {/* <div className="flex flex-wrap gap-2 mb-10">
                                                        <button className="bg-lightblue rounded px-4 py-1.5 flex gap-1 text-dark font-semibold text-xs">
                                                            English
                                                            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M7.99972 7.39104L11.2996 4.09119L12.2424 5.03399L8.94252 8.33384L12.2424 11.6336L11.2996 12.5764L7.99972 9.27664L4.69989 12.5764L3.75708 11.6336L7.05692 8.33384L3.75708 5.03399L4.69989 4.09119L7.99972 7.39104Z" fill="#393939" />
                                                            </svg>
                                                        </button>
                                                        <button className="bg-lightblue rounded px-4 py-1.5 flex gap-1 text-dark font-semibold text-xs">
                                                            Spanish
                                                            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M7.99972 7.39104L11.2996 4.09119L12.2424 5.03399L8.94252 8.33384L12.2424 11.6336L11.2996 12.5764L7.99972 9.27664L4.69989 12.5764L3.75708 11.6336L7.05692 8.33384L3.75708 5.03399L4.69989 4.09119L7.99972 7.39104Z" fill="#393939" />
                                                            </svg>
                                                        </button>
                                                        <button className="bg-lightblue rounded px-4 py-1.5 flex gap-1 text-dark font-semibold text-xs">
                                                            Russian
                                                            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M7.99972 7.39104L11.2996 4.09119L12.2424 5.03399L8.94252 8.33384L12.2424 11.6336L11.2996 12.5764L7.99972 9.27664L4.69989 12.5764L3.75708 11.6336L7.05692 8.33384L3.75708 5.03399L4.69989 4.09119L7.99972 7.39104Z" fill="#393939" />
                                                            </svg>
                                                        </button>
                                                    </div> */}
                                                    {/* <div className="flex  justify-between items-center mb-2">

                                                        <div className="dropdownfilter w-full">
                                                            <button className="text-lg font-semibold text-dark w-full flex justify-between items-center" aria-controls="Gender" aria-expanded="false">
                                                                GENDER
                                                                <svg className="dropdown-arrow transition-transform duration-300" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M11.636 13.828L19.1308 6.33337L21.2721 8.47471L11.636 18.1108L2 8.47471L4.14134 6.33337L11.636 13.828Z" fill="black"></path>
                                                                </svg>
                                                            </button>
                                                            <div className="mt-2 space-y-2 dropdownContentfilter hidden" id="Gender">
                                                                <div className="flex items-center">
                                                                    <input id="male-radio" type="radio" name="gender" value="male" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded-full dark:bg-gray-700 dark:border-gray-600" />
                                                                        <label htmlFor="male-radio" className="ms-2 text-lg font-normal text-dark w-[calc(100%-20px)]">
                                                                            Male
                                                                        </label>
                                                                </div>
                                                                <div className="flex items-center">
                                                                    <input id="female-radio" type="radio" name="gender" value="female" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded-full dark:bg-gray-700 dark:border-gray-600" />
                                                                        <label htmlFor="female-radio" className="ms-2 text-lg font-normal text-dark w-[calc(100%-20px)]">
                                                                            Female
                                                                        </label>
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div>
                                                    <hr className=" -mx-6" />
                                                    <div className="flex  justify-between items-center mt-2 mb-2">
                                                        <div className="dropdownfilter w-full">
                                                            <button className="text-lg font-semibold text-dark w-full flex justify-between items-center" aria-controls="Language" aria-expanded="false">
                                                                LANGUAGE
                                                                <svg className="dropdown-arrow transition-transform duration-300" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M11.636 13.828L19.1308 6.33337L21.2721 8.47471L11.636 18.1108L2 8.47471L4.14134 6.33337L11.636 13.828Z" fill="black" />
                                                                </svg>
                                                            </button>
                                                            <div className="mt-2 space-y-2 hidden dropdownContentfilter" id="Language">
                                                                <div className="flex items-center">
                                                                    <input id="English" type="checkbox" name="Language" value="English" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded dark:bg-gray-700 dark:border-gray-600" />
                                                                    <label htmlFor="English" className="ms-2 text-lg font-normal text-dark w-[calc(100%-20px)]">
                                                                        English</label>
                                                                </div>
                                                                <div className="flex items-center">
                                                                    <input id="Spanish" type="checkbox" name="Language" value="Spanish" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded dark:bg-gray-700 dark:border-gray-600" />
                                                                    <label htmlFor="Spanish" className="ms-2 text-lg  font-normal text-dark w-[calc(100%-20px)]"
                                                                    >Spanish</label>
                                                                </div>
                                                                <div className="flex items-center">
                                                                    <input id="Russian" type="checkbox" name="Language" value="Russian" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded dark:bg-gray-700 dark:border-gray-600" />
                                                                    <label htmlFor="Russian" className="ms-2 text-lg  font-normal text-dark w-[calc(100%-20px)]">
                                                                        Russian</label>
                                                                </div>
                                                                <div className="flex items-center">
                                                                    <input id="Arabic" type="checkbox" name="Language" value="Arabic" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded dark:bg-gray-700 dark:border-gray-600" />
                                                                    <label htmlFor="Arabic" className="ms-2 text-lg  font-normal text-dark w-[calc(100%-20px)]">
                                                                        Arabic</label>
                                                                </div>
                                                                <div className="flex items-center">
                                                                    <input id="French" type="checkbox" name="Language" value="French" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded dark:bg-gray-700 dark:border-gray-600" />
                                                                    <label htmlFor="French" className="ms-2 text-lg  font-normal text-dark w-[calc(100%-20px)]">
                                                                        French</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr className=" -mx-6" />
                                                    <div className="flex  justify-between items-center mt-2 mb-2">
                                                        <div className="dropdownfilter w-full">
                                                            <button className="text-lg font-semibold text-dark w-full flex justify-between items-center" aria-controls="billing" aria-expanded="false">
                                                                BILLING
                                                                <svg className="dropdown-arrow transition-transform duration-300" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M11.636 13.828L19.1308 6.33337L21.2721 8.47471L11.636 18.1108L2 8.47471L4.14134 6.33337L11.636 13.828Z" fill="black" />
                                                                </svg>
                                                            </button>
                                                            <div className="mt-2 space-y-2 hidden dropdownContentfilter" id="billing">
                                                                <div className="flex items-center">
                                                                    <input id="Online" type="radio" name="billing" value="online" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded-full dark:bg-gray-700 dark:border-gray-600" />
                                                                    <label htmlFor="Online" className="ms-2 text-lg font-normal text-dark w-[calc(100%-20px)]">
                                                                        Online
                                                                    </label>
                                                                </div>
                                                                <div className="flex items-center">
                                                                    <input id="offline" type="radio" name="billing" value="offline" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded-full dark:bg-gray-700 dark:border-gray-600" />
                                                                    <label htmlFor="offline" className="ms-2 text-lg font-normal text-dark w-[calc(100%-20px)]" >
                                                                        offline
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr className=" -mx-6" /> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="dropdown">
                                    <button type="button" className="px-5 lg:px-7 py-1.5 bg-transparent text-dark border border-dark text-xs font-bold rounded border-solid flex gap-2 items-center" aria-controls="disclosure-1" aria-expanded="true">
                                        Sort By
                                        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.10974 10.7388L14.3684 5.48022L15.8709 6.98269L9.10974 13.7439L2.34866 6.98269L3.85113 5.48022L9.10974 10.7388Z" fill="#393939" />
                                        </svg>
                                    </button>
                                    <div className="dropdownContent hidden absolute bg-white shadow-md rounded-lg z-10 py-2" id="disclosure-1">
                                        <a href="index.html" className="block rounded-lg py-2 px-3 font-normal text-sm text-gray-900 hover:bg-gray-50">Physiotherapist </a>
                                        <a href="#" className="block rounded-lg py-2 px-3 font-normal text-sm text-gray-900 hover:bg-gray-50">Clinic</a>
                                        <a href="#" className="block rounded-lg py-2 px-3 font-normal text-sm text-gray-900 hover:bg-gray-50">low experience</a>
                                        <a href="#" className="block rounded-lg py-2 px-3 font-normal text-sm text-gray-900 hover:bg-gray-50">High experience</a>
                                    </div>
                                </div>*/}
                                {/* <Link to={'/list'} className="px-5 lg:px-7 py-1.5 bg-blue text-white border border-blue text-xs font-bold rounded border-solid flex items-center justify-center">List
                                </Link>
                                <Link to={'/map'} className="px-5 lg:px-7 py-1.5 bg-transparent text-dark border border-dark text-xs font-bold rounded border-solid flex items-center justify-center">Map
                                </Link> */}
                            </div>
                        </div>

                        <div>

                            {paginatedData?.length > 0 ? paginatedData?.map((item, index) => (
                                <React.Fragment key={index}>
                                    <PracticeCard item={item}
                                        selectedComparisons={selectedComparisons}
                                        setSelectedComparisons={setSelectedComparisons}
                                        postcode={postcode}
                                        slug={slug}
                                    />
                                </React.Fragment>
                            ))
                                :
                                <p className="text-center my-2">No practices & practitioners found.</p>}


                        </div>
                        {paginatedData?.length > 0 && (
                            <div className="pt-3 py-3 border-t-0 border-solid border-[#dfdfdf]">
                                <ul className="flex items-center -space-x-px h-10 text-base justify-end">
                                    {/* Previous Button */}
                                    <li>
                                        <button
                                            onClick={() => handlePageChange(currentPage - 1)}
                                            disabled={currentPage === 1}
                                            className={`flex items-center justify-center px-4 h-10 ms-0 leading-tight border border-e-0 rounded-s-lg border-[#73aeff] 
          ${currentPage === 1 ? "text-gray-400 bg-gray-200 cursor-not-allowed" : "text-blue bg-gray-200 hover:bg-gray-100"}`}
                                        >
                                            <span className="sr-only">Previous</span>
                                            <svg className="w-3 h-3 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 1 1 5l4 4"></path>
                                            </svg>
                                        </button>
                                    </li>

                                    {/* Dynamic Pagination Logic */}
                                    {Array.from({ length: totalPages }, (_, i) => i + 1)
                                        .filter((page) => {
                                            return (
                                                page === 1 ||
                                                page === totalPages ||
                                                (page >= currentPage - 1 && page <= currentPage + 1)
                                            );
                                        })
                                        .reduce((acc, page, index, arr) => {
                                            if (index > 0 && page !== arr[index - 1] + 1) {
                                                acc.push("...");
                                            }
                                            acc.push(page);
                                            return acc;
                                        }, [])
                                        .map((page, index) => (
                                            <li key={index}>
                                                {page === "..." ? (
                                                    <span className={`flex items-center justify-center px-4 h-10 leading-tight border border-[#73aeff] bg-gray-200 text-blue`}>...</span>
                                                ) : (
                                                    <button
                                                        onClick={() => handlePageChange(page)}
                                                        className={`flex items-center justify-center px-4 h-10 leading-tight border border-[#73aeff] 
                ${currentPage === page ? "bg-white text-blue" : "bg-gray-200 text-blue hover:bg-gray-100"}`}
                                                    >
                                                        {page}
                                                    </button>
                                                )}
                                            </li>
                                        ))}

                                    {/* Next Button */}
                                    <li>
                                        <button
                                            onClick={() => handlePageChange(currentPage + 1)}
                                            disabled={currentPage === totalPages}
                                            className={`flex items-center justify-center px-4 h-10 leading-tight border rounded-e-lg border-[#73aeff] 
          ${currentPage === totalPages ? "text-gray-400 bg-gray-200 cursor-not-allowed" : "text-blue bg-gray-200 hover:bg-gray-100"}`}
                                        >
                                            <span className="sr-only">Next</span>
                                            <svg className="w-3 h-3 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4"></path>
                                            </svg>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        )}

                    </div>


                    <div id="bottomSheet" class={`fixed  bottom-0 left-0 w-full bg-white border-t border-gray-300 shadow-lg px-4 py-3 items-center justify-center gap-4 z-50 ${selectedComparisons?.length > 0 ? 'flex' : 'hidden'}`}>
                        <div className="flex flex-wrap items-center space-x-4">
                            {filteredSearchList.length > 0 ? (
                                filteredSearchList.map((item) => (
                                    <div
                                        key={item.practices_id}
                                        className="border rounded-lg flex items-center justify-center bg-white relative gap-3 p-1"
                                    >
                                        <img
                                            src={item.image ? API_BASE_URL + item.image : null}
                                            alt="Item 1"
                                            className="object-cover rounded-lg w-10 aspect-square"
                                        />
                                        <button className="text-sm font-bold"
                                            onClick={() => setSelectedComparisons((prev) => prev.filter(compareItem => compareItem.practices_id !== item.practices_id))}
                                        >
                                            <svg
                                                className="h-6 w-6"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                strokeWidth="1.5"
                                                stroke="currentColor"
                                                aria-hidden="true"
                                            >
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                                            </svg>
                                        </button>
                                    </div>
                                ))
                            ) : null
                            }

                            {filteredSearchList.length < 3 &&
                                <div className="flex items-center justify-center h-full p-2 border border-dashed border-gray-400 rounded-lg">
                                    <div className="text-gray-500">Add {3 - filteredSearchList.length} more</div>
                                </div>
                            }
                            <button
                                type="button"
                                onClick={() => setIsCompareModal(true)}
                                className="bg-blue text-white py-2 px-4 rounded-lg shadow-md h-full comparebtn">Compare</button>
                            <button
                                type="button"
                                onClick={() => setSelectedComparisons([])}
                                className="text-dark border border-gray-300 py-2 px-4 rounded-lg  h-full">Clear</button>
                        </div>
                    </div>

                    <div id="comparepopup" class={`fixed bg-[#0000007A] top-0 left-0 h-screen w-full z-[999] justify-center items-center px-3 ${isCompareModal ? 'flex' : 'hidden'}`}>
                        <CompareModal setIsCompareModal={setIsCompareModal} items={filteredSearchList} setSelectedComparisons={setSelectedComparisons}
                        postcode={postcode}
                        slug={slug}
                        services={services}
                        suburb={suburb}
                        />

                    </div>
                </section>
            </FrontendLayout>
        </>)
}

export default ListPage