import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../api/api";

const FrontendLayout = ({ children }) => {
    const location = useLocation();
    const currentPath = location.pathname + location.hash;

    const [formData, setFormData] = useState({
        email: ""
    });
    const [subscribeMsg, setSubscribeMsg] = useState("");

    const isActive = (path) => currentPath === path;

    const { hash } = useLocation();

    const [isSidebarVisible, setIsSidebarVisible] = useState(false);

    // useEffect(() => {
    //     if (hash) {
    //         const targetId = hash.replace("#", "");
    //         const element = document.getElementById(targetId);
    
    //         if (element) {
    //             const offset = 96;
    //             const elementPosition = element.getBoundingClientRect().top + window.scrollY;
    
    //             window.scrollTo({
    //                 top: elementPosition - offset,
    //                 behavior: "smooth",
    //             });
    //         }
    //     } else {
    //         window.scrollTo({
    //             top: 0,
    //             behavior: "smooth",
    //         });
    //     }
    // }, [hash]);
    
    useEffect(() => {
        if (hash) {
            const targetId = hash.replace("#", "");
    
            setTimeout(() => {
                const element = document.getElementById(targetId);
    
                if (element) {
                    const offset = 96;
                    const elementPosition = element.getBoundingClientRect().top + window.scrollY;
    
                    window.scrollTo({
                        top: elementPosition - offset,
                        behavior: "smooth",
                    });
                }
            }, 50); 
        } else {
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        }
    }, [hash]);
    
    

    const [isScrollToTopVisible, setIsScrollToTopVisible] = useState(false);

    useEffect(() => {
        const toggleVisibility = () => {
            if (window.scrollY > 300) {
                setIsScrollToTopVisible(true);
            } else {
                setIsScrollToTopVisible(false);
            }
        };

        window.addEventListener("scroll", toggleVisibility);
        return () => window.removeEventListener("scroll", toggleVisibility);
    }, []);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    const handleSubmitSubscribers = async (e) => {
        e.preventDefault();

        const payload = {
            ...formData
        }

        try {
            const response = await api.post("add-subscribers", payload);

            if (response?.data?.status === true) {
                
                setSubscribeMsg(response.data?.message);

                setFormData({ ...formData, email: "" });

            } else {
                toast.error(response?.data?.message || "Failed to add subscriber.");
                return null;
            }
        } catch (error) {
            console.error("Error adding subscriber:", error);
            toast.error("Something went wrong. Please try again.");
            return null;
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };


    return (
        <>
            {/* <ToastContainer autoClose={120000} /> */}
            <ToastContainer autoClose={2000} />
            <div className="bg-bgcolor">
                <header className="bg-lightblue sticky top-0 z-[100] shadow-header">
                    <nav className="container mx-auto flex items-center justify-between py-4 px-3" aria-label="Global">
                        <div className="flex">
                            <Link to="/" className="-m-1.5 p-1.5">
                                <span className="sr-only">Allied Health</span>
                                <img className="h-16 w-auto max-w-[200px] xl:max-w-full" src="/assets/image/logo.svg" alt="webtualglobal" />
                            </Link>
                        </div>
                        <div className="flex lg:hidden">
                            <button type="button" className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                                id="menu-open-btn" onClick={() => setIsSidebarVisible(true)}>
                                <span className="sr-only">Open main menu</span>
                                <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor"
                                    aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                                </svg>
                            </button>
                        </div>
                        <div className="hidden lg:flex gap-x-4 xl:gap-x-11">
                            <Link to="/" className={`font-poppins font-medium text-base ${isActive("/") ? "text-blue" : "text-dark"}`}>Compare</Link>
                            <Link to="/find" className={`font-poppins font-medium text-base ${isActive("/find") ? "text-blue" : "text-dark"}`}>Find</Link>
                            <Link to="/#Howitworks" className={`font-poppins font-medium text-base ${isActive("/#Howitworks") ? "text-blue" : "text-dark"}`}>How it Works</Link>
                            <Link to="/#faqs" className={`font-poppins font-medium text-base ${isActive("/#faqs") ? "text-blue" : "text-dark"}`}>FAQs</Link>
                        </div>
                        <div className="hidden lg:flex  lg:justify-end gap-x-3">
                            <Link to="/register" className="px-4 py-3 bg-transparent text-blue rounded-[8px] font-bold border-2 border-solid border-blue hover:scale-[1.04] inline-block transition ease-in-out">List Your Practice For Free</Link>
                            <Link to="/login" className="px-11 py-3 bg-blue text-white rounded-[8px] font-bold border-2 border-solid border-blue hover:scale-[1.04] inline-block transition ease-in-out">Login</Link>
                        </div>
                    </nav>
                    <div className="lg:hidden" role="dialog" aria-modal="true">
                        <div className="fixed inset-0 z-10 navsidebar bg-[#00000066]" id="menu-close-wrapper"></div>
                        <div className={`fixed inset-y-0 z-10 w-10/12 sm:w-full navsidebar overflow-y-auto px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10 navsidebar bg-white flex flex-col ${isSidebarVisible ? 'active' : ''}`}>
                            <div className="flex items-center justify-between">
                                <Link to="/" className="-m-1.5 p-1.5">
                                    <span className="sr-only">Allied Health</span>
                                    <img className="h-8 w-auto" src="/assets/image/logo.svg" alt="webtualglobal" height="32" width="189" />
                                </Link>
                                <button type="button" className="-m-2.5 rounded-md p-2.5 text-gray-700" id="menu-close-btn" onClick={() => setIsSidebarVisible(false)}>
                                    <span className="sr-only">Close menu</span>
                                    <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor"
                                        aria-hidden="true">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </button>
                            </div>
                            <div className="mt-6 flow-root">
                                <div className="-my-6 divide-y divide-gray-500/10">
                                    <div className="space-y-2 py-6">
                                        <Link to="/" className={`-mx-3 block rounded-lg px-2 py-2 text-base text-plus font-semibold leading-7 hover:bg-gray-50 ${isActive("/") ? "text-blue" : "text-gray-900"}`}>Compare</Link>
                                        <Link to="/find" className={`-mx-3 block rounded-lg px-2 py-2 text-base text-plus font-semibold leading-7 hover:bg-gray-50 ${isActive("/find") ? "text-blue" : "text-gray-900"}`}>Find</Link>
                                        <Link to="/#Howitworks" className={`-mx-3 block rounded-lg px-2 py-2 text-base text-plus font-semibold leading-7 hover:bg-gray-50 ${isActive("/#Howitworks") ? "text-blue" : "text-gray-900"}`}>How it Works</Link>
                                        <Link to="/#faqs" className={`-mx-3 block rounded-lg px-2 py-2 text-base text-plus font-semibold leading-7 hover:bg-gray-50 ${isActive("/#faqs") ? "text-blue" : "text-gray-900"}`}>FAQs</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-auto">
                                <Link to="/register" className="px-4 py-3 bg-transparent text-blue text-center rounded-[8px] font-bold border-2 border-solid border-blue hover:scale-[1.04] block transition ease-in-out mb-4">List Your Practice For Free</Link>
                                <Link to="/login" className="px-4 py-3 bg-blue text-white text-center rounded-[8px] font-bold border-2 border-solid border-blue hover:scale-[1.04] block transition ease-in-out">Login</Link>
                            </div>
                        </div>
                    </div>
                </header>

                {children}

                <footer className="bg-dark">
                    <div className="container mx-auto px-3 py-[60px]">
                        <div className="grid  grid-cols-12 gap-x-6 gap-y-4 ">
                            <div className="col-span-12 md:col-span-6 xl:col-span-4">
                                <img src="/assets/image/light-logo.svg" alt="" srcSet="" className="mb-4 sm:mb-6" />
                                <p className="text-white"><b>Connect with Allied Health Professionals:</b> Easily find and compare trusted allied health practitioners. Whether you need physiotherapy, dietetics, occupational therapy, or more, our directory makes it simple to connect with the right expert for your health needs. Start your journey to better health today!</p>
                            </div>
                            <div className="col-span-12 sm:col-span-6 md:col-span-3 xl:col-span-2">
                                <h4 className="text-white font-semibold text-[22px] leading-normal mb-6">Useful Link</h4>
                                <ul>
                                    <li className="mb-2"><Link to="/" className="text-white text-base">Compare</Link></li>
                                    <li className="mb-2"><Link to="/find" className="text-white text-base">Find</Link></li>
                                    <li className="mb-2"><Link to="/#Howitworks" className="text-white text-base" >How it works</Link></li>
                                    <li className="mb-2"><Link to="/#faqs" className="text-white text-base">FAQs</Link></li>
                                </ul>
                            </div>
                            <div className="col-span-12 sm:col-span-6 md:col-span-3 xl:col-span-2">
                                <h4 className="text-white font-semibold text-[22px] leading-normal mb-6">Our Company</h4>
                                <ul>
                                    <li className="mb-2"><Link to="/about" className="text-white text-base">About Us</Link></li>
                                    <li className="mb-2"><Link to="/contact" className="text-white text-base">Contact Us</Link></li>
                                    <li className="mb-2"><Link to="/terms-conditions" className="text-white text-base">Terms & Conditions</Link></li>
                                    <li className="mb-2"><Link to="/privacy-policy" className="text-white text-base">Privacy Policy</Link></li>
                                </ul>
                            </div>
                            <div className="col-span-12 xl:col-span-4">
                                <h4 className="text-white font-semibold text-[22px] leading-normal mb-2">Newsletter</h4>
                                <p className="text-white text-xs font-light mb-6">Subscribe to our newsletter to get the latest news and updates about Allied Health.</p>
                                <form id="newsletter-form" aria-labelledby="newsletter-headline" onSubmit={handleSubmitSubscribers}>
                                    <div className="">
                                        <div className="flex items-center">
                                            <input
                                                value={formData.email}
                                                onChange={handleChange}
                                                name="email" type="email" required="required" placeholder="Enter your email" aria-label="Email address" className="w-full px-5 py-3 text-base leading-6 transition duration-150 ease-in-out border-2 rounded-l-md appearance-none focus:outline-none max-w-xs text-dark placeholder-gray-500 focus:placeholder-dark bg-white h-[60px]" />
                                            <button type="submit" className="block px-8 py-2 h-[60px] text-white transition duration-100 ease-in-out bg-blue-500 rounded-r-md shadow-sm bg-green text-base font-semibold">Subscribe</button>
                                        </div>
                                    </div>
                                </form>
                                <p id="success-message" className="text-white text-sm font-medium mt-4">{subscribeMsg}</p>
                            </div>
                            <div className="col-span-12 mt-6 md:mt-12 lg:mt-20">
                                <p className="text-lg font-light text-center text-white">Allied Health.com.au Copyright {new Date().getFullYear()} All Rights Reserved.</p>
                            </div>
                        </div>
                    </div>
                    <button
                        onClick={scrollToTop}
                        id="backToTopBtn" title="Go to top" className={`fixed bottom-5 right-5 z-50 border-none rounded-full transition-opacity duration-300 ${isScrollToTopVisible ? "opacity-100" : "opacity-0"
                            }`}>
                        <img src="/assets/image/toptoback.png" alt="" srcSet="" />
                    </button>
                </footer>

            </div>
        </>
    )

};

export default FrontendLayout;
