import React, { useEffect, useRef, useState } from "react";
import FrontendLayout from "../../layouts/FrontendLayout";
import { Link, useNavigate, useParams } from "react-router-dom";
import { API_BASE_URL, fetchData } from "../../api/api";
import Spinner from "../../components/common/Spinner";
import Select from "react-select";
import { CONSTANT_GENDER, CONSTANT_REDIRECT_TIME } from "../../constant/common";
import { extractSlugDetails } from "../../utils/slug";
import { toast } from "react-toastify";
import PracticeDetailCard from "../../components/frontend/practices/PracticeDetailCard";


const FrontendPracticesDetailPage = () => {
    const navigate = useNavigate();

    const { postcode, slug, practice_name_slug } = useParams();

    const { services, suburb } = extractSlugDetails(slug);

    const didFetch = useRef(false);

    const [isLoading, setIsLoading] = useState(false);

    const [practiceDetails, setPracticeDetails] = useState([]);

    const getPracticeDetails = async () => {
        setIsLoading(true);
        try {
            const data = await fetchData('/detail-frontend-practices', {
                services: services,
                postcode: postcode,
                suburb: suburb,
                slug: practice_name_slug,
            });

            if (data.error === true) {
                navigate("/");
            }
            else {
                setPracticeDetails(data);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (!didFetch.current) {
            getPracticeDetails();
            didFetch.current = true;
        }
    }, []);


    if (isLoading) {
        return (<Spinner />);
    }

    return (
        <>
            <FrontendLayout>
                <section className="pt-6 sm:pt-8 md:pt-12 lg:pt-16 xl:pt-[70px] pb-[60px] md:pb-[100px]">
                    <div className="container px-3 mx-auto">
                        <PracticeDetailCard
                            item={practiceDetails}
                            services={services}
                            suburb={suburb}
                            postcode={postcode}
                            slug={slug}
                            practice_name_slug={practice_name_slug}
                        />
                    </div>
                </section>
            </FrontendLayout>
        </>)
}

export default FrontendPracticesDetailPage