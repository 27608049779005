import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import AdminLayout from "../../layouts/AdminLayout";
import api, { API_BASE_URL, fetchData } from "../../api/api";
import Spinner from "../../components/common/Spinner";
import { AuthContext } from "../../context/AuthContext";
import { encryptData } from "../../utils/encryptDecrypt";
import { toast } from "react-toastify";
import { CONSTANT_REDIRECT_TIME } from "../../constant/common";
import MasterAdminLayout from "../../layouts/MasterAdminLayout";


const MasterAdminHomePage = () => {

    return (
        <>
            <MasterAdminLayout>
              
            </MasterAdminLayout>
        </>)
}

export default MasterAdminHomePage