import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import AdminLayout from "../../layouts/AdminLayout";
import { API_BASE_URL, fetchData } from "../../api/api";
import Select from "react-select";
import { toast } from "react-toastify";
import { CONSTANT_CURRENCY, CONSTANT_REDIRECT_TIME } from "../../constant/common";
import api from "../../api/api";
import { decryptData } from "../../utils/encryptDecrypt";
import Spinner from "../../components/common/Spinner";
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
import PractitionerDetailsCard from "../../components/admin/practices/PractitionerDetailsCard";

const images = [
    "/assets/image/physiotherapist-gallery-1.png",
    "/assets/image/physiotherapist-gallery-2.png",
    "/assets/image/physiotherapist-gallery-3.png",
    "/assets/image/physiotherapist-gallery-4.png",
    "/assets/image/physiotherapist-gallery-5.png",
    "/assets/image/physiotherapist-gallery-6.png",
    "/assets/image/physiotherapist-gallery-7.png",
    "/assets/image/physiotherapist-gallery-8.png",
    "/assets/image/physiotherapist-gallery-9.png",
];

const AdminPracticesDetails = () => {
    const navigate = useNavigate();

    const { practices_id } = useParams();
    const decrypt_practices_id = decryptData(practices_id);

    const didFetch = useRef(false);

    const [isLoading, setIsLoading] = useState(false);

    const [practiceDetails, setPracticeDetails] = useState([]);
    const [practitionersList, setPractitionersList] = useState([]);

    const [practiceactiveTab, setPracticeActiveTab] = useState("overview");

    const [currentPage, setCurrentPage] = useState(1);

    const getPracticeDetails = async () => {
        setIsLoading(true);
        try {
            const data = await fetchData('/detail-practices', { practices_id: decrypt_practices_id });
            setPracticeDetails(data);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const getPractitionersList = async () => {
        setIsLoading(true);
        try {
            const data = await fetchData('/get-practitioners', { fk_practices_id: decrypt_practices_id });
            setPractitionersList(data);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (!didFetch.current) {
            getPracticeDetails();
            getPractitionersList();
            didFetch.current = true;
        }
    }, []);

    if (isLoading) {
        return (<Spinner adminLayout={true} />);
    }

    const handleImageChange = async (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];

            const payload = {
                practices_id: practiceDetails?.practices_id,
                image: file,
            };

            try {
                const response = await api.post("update-practices-image", payload, {
                    headers: { "Content-Type": "multipart/form-data" },
                });

                if (response?.data?.status === true) {
                    toast.success(response.data?.message);

                    setTimeout(() => {
                        getPracticeDetails();
                    }, CONSTANT_REDIRECT_TIME);

                } else {
                    toast.error(response?.data?.message || "Failed ");
                    return null;
                }
            } catch (error) {
                console.error("Error :", error);
                toast.error("Something went wrong. Please try again.");
                return null;
            }
        } else {
            console.log("No file selected.");
        }
    };

    const itemsPerPage = 10;

    const totalPages = Math.ceil(practitionersList.length / itemsPerPage);

    const paginatedData =
        practitionersList.length > 0
            ? practitionersList.slice(
                (currentPage - 1) * itemsPerPage,
                currentPage * itemsPerPage
            )
            : [];

    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    return (
        <>
            <AdminLayout>
                <div className="bg-[#EDF4FF]">
                    <section className="py-10 lg:py-[50px]">
                        <div className="container px-3 mx-auto">
                            <h1 className="text-dark text-2xl font-semibold mb-6">Practice Details</h1>
                            <div className="px-4 py-4 sm:px-[30px] sm:py-[30px] md:px-[60px] md:py-[60px] bg-white rounded-[18px]">
                                <div className="mb-4 md:mb-6 lg:mb-10 flex flex-wrap gap-y-3">
                                    <div className="w-full md:w-[calc(100%-170px)] flex flex-wrap gap-y-3 listcardwrapper">
                                        <div className="w-[100px] sm:w-[170px] w-100 imgcard">
                                            <div>
                                                <img src={practiceDetails?.image ? API_BASE_URL + practiceDetails?.image : null} alt="" className="w-full rounded-lg aspect-square object-cover mb-1.5" />
                                            </div>
                                            <label htmlFor="image1" className=" py-2 bg-transparent text-dark border border-dark text-xs sm:text-base w-full flex items-center justify-center font-semibold rounded border-solid max-w-[130px] mx-auto cursor-pointer">Add Logo</label>
                                            <input type="file" name="" id="image1" className="hidden" onChange={handleImageChange} />
                                        </div>
                                        <div className="w-[calc(100%-100px)] sm:w-[calc(100%-170px)] px-3 sm:px-6 contentcard">
                                            <div className="sm:max-w-[406px] w-100">
                                                <div className="text-lg sm:text-xl md:text-2xl block w-fit font-semibold text-gray-800 mb-1 sm:mb-2 ">
                                                    <span>{practiceDetails?.practice_name}</span>
                                                    {practiceDetails?.is_verified == 1
                                                        &&
                                                        <span className="float-right mt-1 pl-2">
                                                            <img src="/assets/image/verifiedicon.svg" alt="" srcSet="" />
                                                        </span>
                                                    }

                                                </div>
                                                {/* <div className="flex items-center flex-wrap gap-x-3 gap-y-1 mb-2 sm:mb-4">
                                                    <span className="flex items-center text-green text-xs font-medium">
                                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M9.00045 13.695L3.71036 16.6562L4.89186 10.7099L0.440918 6.59385L6.4612 5.88005L9.00045 0.375L11.5396 5.88005L17.5599 6.59385L13.109 10.7099L14.2905 16.6562L9.00045 13.695Z" fill="#FFC116" />
                                                        </svg>
                                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M9.00045 13.695L3.71036 16.6562L4.89186 10.7099L0.440918 6.59385L6.4612 5.88005L9.00045 0.375L11.5396 5.88005L17.5599 6.59385L13.109 10.7099L14.2905 16.6562L9.00045 13.695Z" fill="#FFC116" />
                                                        </svg>
                                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M9.00045 13.695L3.71036 16.6562L4.89186 10.7099L0.440918 6.59385L6.4612 5.88005L9.00045 0.375L11.5396 5.88005L17.5599 6.59385L13.109 10.7099L14.2905 16.6562L9.00045 13.695Z" fill="#FFC116" />
                                                        </svg>
                                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M9.00045 13.695L3.71036 16.6562L4.89186 10.7099L0.440918 6.59385L6.4612 5.88005L9.00045 0.375L11.5396 5.88005L17.5599 6.59385L13.109 10.7099L14.2905 16.6562L9.00045 13.695Z" fill="#FFC116" />
                                                        </svg>
                                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M9.00045 11.976L12.1855 13.7588L11.4741 10.1788L14.1539 7.70055L10.5292 7.27075L9.00045 3.95627V11.976ZM9.00045 13.695L3.71036 16.6562L4.89186 10.7099L0.440918 6.59385L6.4612 5.88005L9.00045 0.375L11.5396 5.88005L17.5599 6.59385L13.109 10.7099L14.2905 16.6562L9.00045 13.695Z" fill="#FFC116" />
                                                        </svg>
                                                        <span className="mx-1 text-dark">(4.5)</span>
                                                        79 Reviews
                                                    </span>
                                                </div> */}
                                                <Link
                                                    // to={`https://www.google.com/maps?q=${practiceDetails?.suburb?.latitude},${practiceDetails?.suburb?.longitude}`}
                                                    to={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(practiceDetails?.address + ', ' + practiceDetails?.suburb?.name + ', ' + practiceDetails?.state?.name)}`}
                                                    target="_blank" rel="noopener noreferrer" className="flex gap-x-2 sm:gap-x-3 gap-y-1 sm:items-center mb-2 sm:mb-3">
                                                    <svg width="24" className="w-4 h-4 sm:w-6 sm:h-6 mt-1 sm:mt-0" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M18.364 17.364L12 23.7279L5.63604 17.364C2.12132 13.8492 2.12132 8.15076 5.63604 4.63604C9.15076 1.12132 14.8492 1.12132 18.364 4.63604C21.8787 8.15076 21.8787 13.8492 18.364 17.364ZM12 13C13.1046 13 14 12.1046 14 11C14 9.89543 13.1046 9 12 9C10.8954 9 10 9.89543 10 11C10 12.1046 10.8954 13 12 13Z" fill="#0052CC" />
                                                    </svg>
                                                    <span className="text-dark text-sm sm:text-base font-medium underline underline-offset-[2px] w-[calc(100%-24px)] sm:w-[calc(100%-36px)]">{practiceDetails?.address}, {practiceDetails?.suburb?.name}, {practiceDetails?.state?.name}, {practiceDetails?.postcode?.postcode}</span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full md:w-[170px] flex flex-wrap lg:flex-col lg:items-start lg:justify-center gap-2 sm:gap-3">
                                        <Link to={`tel:${practiceDetails?.phone}`} className="bg-green text-center text-white text-sm sm:text-base font-semibold md:w-full  rounded-lg py-2 px-3 sm:py-3  sm:px-2 flex items-center justify-center border border-green">Call {practiceDetails?.practice_name}</Link>
                                        <Link to={`mailto:${practiceDetails?.email}`} className="bg-transparent text-center text-dark text-sm sm:text-base font-semibold md:w-full  rounded-lg py-2 px-3 sm:py-3 sm:px-2 flex items-center justify-center border border-dark emailButton">Email {practiceDetails?.practice_name}</Link>
                                        {/* <button className="bg-transparent text-center text-dark text-sm sm:text-base font-semibold md:w-full  rounded-lg py-2 px-3 sm:py-3  sm:px-2 flex items-center justify-center border border-dark">Book Appointment</button>
                                        <button className="bg-transparent text-center text-dark text-sm sm:text-base font-semibold md:w-full  rounded-lg py-2 px-3 sm:py-3  sm:px-2 flex items-center justify-center border border-dark">View Website</button> */}
                                    </div>
                                    <div id="emailModal" className="fixed z-[999] inset-0 flex items-center justify-center bg-black bg-opacity-50 hidden" >
                                        <div className="emailbox bg-white p-9 rounded-lg shadow-lg w-full max-w-[512px] relative min-h-[60vh] max-h-[90vh]" >
                                            <form action="#" method="POST" className="space-y-3 " id="mailform">
                                                <div>
                                                    <label htmlFor="name" className="mb-2 inline-block text-dark font-semibold text-base">Your name</label>
                                                    <input type="text" name="name" id="name" className="border-[1.2px] border-dark w-full h-10 sm:h-12 rounded-xl px-3 sm:px-4 focus:border-blue outline-none text-lg" placeholder="Enter your name" />
                                                </div>
                                                <div>
                                                    <label htmlFor="email" className="mb-2 inline-block text-dark font-semibold text-base">Email</label>
                                                    <input type="email" name="email" id="email" className="border-[1.2px] border-dark w-full h-10 sm:h-12 rounded-xl px-3 sm:px-4 focus:border-blue outline-none text-lg " placeholder="Enter your email" />
                                                </div>
                                                <div>
                                                    <label htmlFor="phone" className="mb-2 inline-block text-dark font-semibold text-base">Phone</label>
                                                    <input type="text" id="phone" name="phone" placeholder="Enter your phone number" className="border-[1.2px] border-dark w-full h-10 sm:h-12 rounded-xl px-3 sm:px-4 focus:border-blue outline-none text-lg " />
                                                </div>
                                                <div>
                                                    <label htmlFor="message" className="mb-2 inline-block text-dark font-semibold text-base">Message</label>
                                                    <textarea id="message" name="message" placeholder="Description" className="border-[1.2px] border-dark w-full  rounded-xl px-3 sm:px-4 focus:border-blue outline-none text-lg  py-3 min-h-28 "></textarea>
                                                </div>
                                                <div className="flex items-start">
                                                    <input id="default-checkbox" type="checkbox" value="" className="w-4 sm:w-5 h-4 sm:h-5 text-blue-600 bg-gray-100 border-gray-300 rounded  dark:bg-gray-700 dark:border-gray-600 mt-1" />
                                                    <label htmlFor="default-checkbox" className="ms-2 text-sm font-medium text-dark w-[calc(100%-20px)]">By submitting this form, I agree to the <a href="terms-conditions.html" className="text-blue font-semibold">Terms & Conditions</a>  & <a href="privacy-policy.html" className="text-blue font-semibold">Privacy Policy</a>, and I understand that I can opt out at any time.</label>
                                                </div>
                                                <button type="submit" className="h-auto py-3 px-3 sm:h-14 bg-blue w-full text-center text-white font-semibold text-lg sm:text-2xl rounded-xl mb-4 sm:mb-4">Send</button>
                                            </form>
                                            <div id="emailthankYouSection" className="flex items-center justify-center flex-col hidden">
                                                <img src="/assets/image/svg/thankyou.svg" alt="" className="mb-8" />
                                                <p className="text-[26px] leading-normal font-bold text-center text-blue mb-6">Thank you for your submission!</p>
                                                <p className="text-xl font-semibold text-center text-blue mb-3">Your message has been successfully sent.</p>
                                                <p className="text-xl font-light text-center text-dark mb-16">Our team will be in touch with you shortly</p>
                                                <a href="index.html" className="px-11 py-3  bg-blue text-white rounded-[8px] font-bold border-2 border-solid border-blue hover:scale-[1.04] inline-block transition ease-in-out">Go to Home</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-container">
                                    <div className="tab-btn p-1.5 flex flex-wrap lg:justify-between bg-[#f5f5f5] rounded-lg gap-3 w-full max-w-[850px]">
                                        <button
                                            onClick={() => setPracticeActiveTab('overview')}
                                            className={`px-5 py-2 text-base font-semibold rounded ${practiceactiveTab === "overview" ? "bg-blue text-white" : "bg-transparent text-dark"}`}>Overview</button>
                                        <button
                                            onClick={() => setPracticeActiveTab('services_fees')}
                                            className={`px-5 py-2 text-base font-semibold rounded ${practiceactiveTab === "services_fees" ? "bg-blue text-white" : "bg-transparent text-dark"}`}>Services & Fees</button>
                                        <button
                                            onClick={() => setPracticeActiveTab('photos')}
                                            className={`px-5 py-2 text-base font-semibold rounded ${practiceactiveTab === "photos" ? "bg-blue text-white" : "bg-transparent text-dark"}`}>Photos</button>
                                        <button
                                            onClick={() => setPracticeActiveTab('faqs')}
                                            className={`px-5 py-2 text-base font-semibold rounded ${practiceactiveTab === "faqs" ? "bg-blue text-white" : "bg-transparent text-dark"}`}>FAQs</button>
                                        <button
                                            onClick={() => setPracticeActiveTab('more_details')}
                                            className={`px-5 py-2 text-base font-semibold rounded ${practiceactiveTab === "more_details" ? "bg-blue text-white" : "bg-transparent text-dark"}`}>More Details</button>
                                    </div>

                                    <div className="tab-content rounded-lg pt-6">
                                        {practiceactiveTab === "overview" &&
                                            <div className="">
                                                <p className="text-dark text-sm font-medium mb-3 last:mb-0 text-justify">{practiceDetails?.full_description}</p>
                                            </div>
                                        }

                                        {practiceactiveTab === "services_fees" &&
                                            <div className="">
                                                <h3 className="text-dark text-lg font-semibold mb-4">Services & Fees</h3>
                                                <ul className="space-y-3">
                                                    <li className="text-dark flex flex-wrap items-center"><span className="font-semibold text-base min-w-56">Services :</span>
                                                        <span>
                                                            {practiceDetails?.services?.map(service => service.name).join(", ")}
                                                        </span>
                                                    </li>
                                                    <li className="text-dark flex flex-wrap items-center"><span className="font-semibold text-base min-w-56">Servicing Areas :</span>
                                                        <span> {practiceDetails?.services_areas?.map(services_area => services_area.postcode).join(", ")}</span>
                                                    </li>
                                                    <li className="text-dark flex flex-wrap items-start">
                                                        <span className="font-semibold text-base min-w-56">Add Service & Fees :</span>
                                                        <div className="space-y-2">
                                                            {practiceDetails?.services_fees.length > 0 ?
                                                                <>
                                                                    {practiceDetails?.services_fees.map((item, index) => <p key={index}>{item?.name} - {item?.fees}</p>)}
                                                                </>
                                                                : null
                                                            }
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        }

                                        {/* {practiceactiveTab === "photos" &&
                                            <div className="">
                                                <PhotoProvider>
                                                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3 photoGallery">
                                                        {images.map((src, index) => (
                                                            <PhotoView key={index} src={src}>
                                                                <img src={src} alt="physiotherapist gallery" className="w-full object-cover aspect-[175/111] rounded-lg cursor-pointer" />
                                                            </PhotoView>
                                                        ))}
                                                    </div>
                                                </PhotoProvider>
                                            </div>
                                        } */}


                                        {/* {practiceactiveTab === "faqs" &&
                                            <div className="">
                                                <div className="mb-6 relative w-full max-w-[630px] mx-auto">
                                                    <div className="absolute left-[30px] top-1/2 -translate-y-1/2 w-6 h-6 z-10">
                                                        <img src="data:image/svg+xml,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M23.6625%2022.0027L16.8%2015.1402C18.0797%2013.4996%2018.7688%2011.4746%2018.7688%209.39805C18.7688%204.22305%2014.5594%200.0136719%209.38438%200.0136719C4.20937%200.0136719%200%204.22305%200%209.39805C0%2014.573%204.20937%2018.7824%209.38438%2018.7824C11.4797%2018.7824%2013.5141%2018.084%2015.1594%2016.7902L22.0172%2023.648C22.4906%2024.084%2023.2219%2024.0512%2023.6578%2023.5777C24.0656%2023.1324%2024.0656%2022.448%2023.6625%2022.0027ZM2.32031%209.39805C2.32031%205.50273%205.48906%202.33867%209.37969%202.33867C13.2703%202.33867%2016.4391%205.50742%2016.4391%209.39805C16.4391%2013.2887%2013.2703%2016.4574%209.37969%2016.4574C5.48906%2016.4574%202.32031%2013.2887%202.32031%209.39805Z%22%20fill%3D%22%230052CC%22%2F%3E%3C%2Fsvg%3E" alt="Search Icon" />
                                                    </div>
                                                    <input type="search" name="" id="" className="py-[14px] w-full text-dark text-lg pl-[68px] pr-[30px] bg-[#f5f5f5] rounded-lg focus:border-blue outline-none relative " placeholder="search for a question" />
                                                </div>
                                                <div className="dropdownfilter w-full bg-[#f5f5f5] py-6 px-8 rounded-lg mb-3 last:mb-0">
                                                    <button className=" w-full flex justify-between items-center" aria-controls="faq-1" aria-expanded="true">
                                                        <span className="text-xl text-start font-semibold text-dark w-[calc(100%-30px)]">How do i get started?</span>
                                                        <svg className="dropdown-arrow transition-transform duration-300 rotate-180" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M11.636 13.828L19.1308 6.33337L21.2721 8.47471L11.636 18.1108L2 8.47471L4.14134 6.33337L11.636 13.828Z" fill="black" />
                                                        </svg>
                                                    </button>
                                                    <div className="mt-4 space-y-2 dropdownContentfilter" id="faq-1">
                                                        <p className="text-justify font-medium text-dark text-base">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries.</p>
                                                    </div>
                                                </div>
                                                <div className="dropdownfilter w-full bg-[#f5f5f5] py-6 px-8 rounded-lg mb-3 last:mb-0">
                                                    <button className="text-xl font-semibold text-dark w-full flex justify-between items-center" aria-controls="faq-2" aria-expanded="false">
                                                        <span className="text-xl text-start font-semibold text-dark w-[calc(100%-30px)]">Which is included in the free plan?</span>
                                                        <svg className="dropdown-arrow transition-transform duration-300" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M11.636 13.828L19.1308 6.33337L21.2721 8.47471L11.636 18.1108L2 8.47471L4.14134 6.33337L11.636 13.828Z" fill="black" />
                                                        </svg>
                                                    </button>
                                                    <div className="mt-4 space-y-2 hidden dropdownContentfilter" id="faq-2">
                                                        <p className="text-justify font-medium text-dark text-base">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries.</p>
                                                    </div>
                                                </div>
                                                <div className="dropdownfilter w-full bg-[#f5f5f5] py-6 px-8 rounded-lg mb-3 last:mb-0">
                                                    <button className="text-xl font-semibold text-dark w-full flex justify-between items-center" aria-controls="faq-3" aria-expanded="false">
                                                        <span className="text-xl text-start font-semibold text-dark w-[calc(100%-30px)]">How do i cancel my membership?</span>
                                                        <svg className="dropdown-arrow transition-transform duration-300" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M11.636 13.828L19.1308 6.33337L21.2721 8.47471L11.636 18.1108L2 8.47471L4.14134 6.33337L11.636 13.828Z" fill="black" />
                                                        </svg>
                                                    </button>
                                                    <div className="mt-4 space-y-2 hidden dropdownContentfilter" id="faq-3">
                                                        <p className="text-justify font-medium text-dark text-base">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries.</p>
                                                    </div>
                                                </div>
                                                <div className="dropdownfilter w-full bg-[#f5f5f5] py-6 px-8 rounded-lg mb-3 last:mb-0">
                                                    <button className="text-xl font-semibold text-dark w-full flex justify-between items-center" aria-controls="faq-4" aria-expanded="false">
                                                        <span className="text-xl text-start font-semibold text-dark w-[calc(100%-30px)]">How do i transfer my membership to a different account?</span>
                                                        <svg className="dropdown-arrow transition-transform duration-300" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M11.636 13.828L19.1308 6.33337L21.2721 8.47471L11.636 18.1108L2 8.47471L4.14134 6.33337L11.636 13.828Z" fill="black" />
                                                        </svg>
                                                    </button>
                                                    <div className="mt-4 space-y-2 hidden dropdownContentfilter" id="faq-4">
                                                        <p className="text-justify font-medium text-dark text-base">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries.</p>
                                                    </div>
                                                </div>
                                                <div className="dropdownfilter w-full bg-[#f5f5f5] py-6 px-8 rounded-lg mb-3 last:mb-0">
                                                    <button className="text-xl font-semibold text-dark w-full flex justify-between items-center" aria-controls="faq-5" aria-expanded="false">
                                                        <span className="text-xl text-start font-semibold text-dark w-[calc(100%-30px)]">What is the refund policy?</span>
                                                        <svg className="dropdown-arrow transition-transform duration-300" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M11.636 13.828L19.1308 6.33337L21.2721 8.47471L11.636 18.1108L2 8.47471L4.14134 6.33337L11.636 13.828Z" fill="black" />
                                                        </svg>
                                                    </button>
                                                    <div className="mt-4 space-y-2 hidden dropdownContentfilter" id="faq-5">
                                                        <p className="text-justify font-medium text-dark text-base">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        } */}

                                        {practiceactiveTab === "more_details" &&
                                            <div className="">
                                                <ul className="space-y-3">
                                                    <li className="text-dark flex flex-wrap items-center"><span className="font-semibold text-base min-w-56">Type of Practice :</span><span>{practiceDetails?.practices_types?.map(practices_type => practices_type.name).join(", ")}</span></li>
                                                    <li className="text-dark flex flex-wrap items-center"><span className="font-semibold text-base min-w-56">Servicing Areas :</span> <span>{practiceDetails?.services_areas?.map(services_area => services_area.postcode).join(", ")}</span></li>
                                                    {/* <li className="text-dark flex flex-wrap items-center"><span className="font-semibold text-base min-w-56">Custom URL :</span> <a href="#">https:www.apexclinic.com</a></li> */}
                                                </ul>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    {paginatedData?.length > 0 &&

                        <section className="pb-10 lg:pb-[50px]">
                            <div className="container px-3 mx-auto">
                                <h2 className="text-dark text-2xl font-semibold mb-6">Practitioners Details</h2>

                                <div className="flex flex-col gap-8">

                                    {paginatedData?.length > 0 ?
                                        <>

                                            {paginatedData?.map((practitioner, index) => (
                                                <PractitionerDetailsCard key={index} practitionerDetails={practitioner} getPractitionersList={getPractitionersList} />
                                            )
                                            )}

                                            {paginatedData?.length > 0 &&
                                                <div className="px-3 py-3 border-t-2 border-solid border-[#dfdfdf]">
                                                    <ul className="flex items-center -space-x-px h-10 text-base justify-end">
                                                        <li>
                                                            <button
                                                                onClick={() => handlePageChange(currentPage - 1)}
                                                                disabled={currentPage === 1}
                                                                className={`flex items-center justify-center px-4 h-10 ms-0 leading-tight border border-e-0 rounded-s-lg border-[#73aeff]
                                        ${currentPage === 1 ? "text-gray-400 bg-gray-200 cursor-not-allowed" : "text-blue bg-lightblue hover:bg-gray-100 hover:text-gray-700"}`}
                                                            >
                                                                <span className="sr-only">Previous</span>
                                                                <svg className="w-3 h-3 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 1 1 5l4 4" />
                                                                </svg>
                                                            </button>
                                                        </li>
                                                        {Array.from({ length: totalPages }, (_, i) => (
                                                            <li key={i}>
                                                                <button onClick={() => handlePageChange(i + 1)}
                                                                    className={`flex items-center justify-center px-4 h-10 leading-tight border border-[#73aeff] 
                                        ${currentPage === i + 1 ? "bg-blue-600 text-blue" : "text-blue bg-lightblue hover:bg-gray-100 hover:text-gray-700"}`}
                                                                >
                                                                    {i + 1}
                                                                </button>
                                                            </li>
                                                        ))}
                                                        <li>
                                                            <button
                                                                onClick={() => handlePageChange(currentPage + 1)}
                                                                disabled={currentPage === totalPages}
                                                                className={`flex items-center justify-center px-4 h-10 leading-tight border rounded-e-lg border-[#73aeff]
                                        ${currentPage === totalPages ? "text-gray-400 bg-gray-200 cursor-not-allowed" : "text-blue bg-lightblue hover:bg-gray-100 hover:text-gray-700"}`}
                                                            >
                                                                <span className="sr-only">Next</span>
                                                                <svg className="w-3 h-3 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4" />
                                                                </svg>
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            }


                                        </> : null}


                                </div>

                            </div>
                        </section>
                    }
                </div>
            </AdminLayout>
        </>)
}

export default AdminPracticesDetails