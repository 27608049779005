import React, { useState } from "react";

const FAQ = () => {
    const [openFAQ, setOpenFAQ] = useState(null);

    const toggleFAQ = (id) => {
        setOpenFAQ(openFAQ === id ? null : id);
    };

    return (
        <div style={{ display: "block" }}>
            <div className="dropdownfilter w-full bg-white lg:py-4 lg:px-3 py-6 px-4 rounded-lg mb-1 last:mb-0">
                <button
                    onClick={() => toggleFAQ(1)}
                    className="w-full flex justify-between items-center"
                    aria-controls="faq-1"
                    aria-expanded={openFAQ === 1}
                >
                    <span className="w-[calc(100%-30px)] text-start text-lg font-semibold text-dark">
                        What is Allied Health.com.au?
                    </span>
                    <svg
                        className={`dropdown-arrow transition-transform duration-300 ${openFAQ === 1 ? "rotate-180" : ""
                            }`}
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M11.636 13.828L19.1308 6.33337L21.2721 8.47471L11.636 18.1108L2 8.47471L4.14134 6.33337L11.636 13.828Z"
                            fill="black"
                        ></path>
                    </svg>
                </button>
                <div
                    className={`mt-4 space-y-2 dropdownContentfilter transition-all duration-300 ${openFAQ === 1 ? "block" : "hidden"
                        }`}
                    id="faq-1"
                >
                    <p className="text-justify font-medium text-dark text-base">
                        Allied Health.com.au is an online platform designed to help patients
                        easily connect with a wide range of qualified allied health
                        practitioners. Whether you need physiotherapy, occupational therapy,
                        speech pathologist, or other services, our platform makes it easy to
                        find and compare experienced professionals near you.
                    </p>
                </div>
            </div>

            <div className="dropdownfilter w-full bg-white lg:py-4 lg:px-3 py-6 px-4 rounded-lg mb-1 last:mb-0">
                <button
                    onClick={() => toggleFAQ(2)}
                    className="w-full flex justify-between items-center"
                    aria-controls="faq-2"
                    aria-expanded={openFAQ === 2}
                >
                    <span className="w-[calc(100%-30px)] text-start text-lg font-semibold text-dark">
                        How do I find a practitioner?
                    </span>
                    <svg
                        className={`dropdown-arrow transition-transform duration-300 ${openFAQ === 2 ? "rotate-180" : ""
                            }`}
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M11.636 13.828L19.1308 6.33337L21.2721 8.47471L11.636 18.1108L2 8.47471L4.14134 6.33337L11.636 13.828Z"
                            fill="black"
                        ></path>
                    </svg>
                </button>
                <div
                    className={`mt-4 space-y-2 dropdownContentfilter transition-all duration-300 ${openFAQ === 2 ? "block" : "hidden"
                        }`}
                    id="faq-2"
                >
                    <p className="text-justify font-medium text-dark text-base mb-3">
                        You can find practitioners by two ways:
                    </p>
                    <ol className="list-decimal pl-4">
                        <li className="text-justify font-medium text-dark text-base">
                            Click on Home, select practitioner and answer few Questions. We
                            will be able to find the correct practitioner for you.
                        </li>
                        <li className="text-justify font-medium text-dark text-base">
                            Also simply click on compare to find practitioners based on their
                            specialty, location, and availability. You can filter results by
                            practitioner type and more to ensure you find the right fit for
                            your needs.
                        </li>
                    </ol>
                </div>
            </div>

            <div className="dropdownfilter w-full bg-white lg:py-4 lg:px-3 py-6 px-4 rounded-lg mb-1 last:mb-0">
                <button
                    onClick={() => toggleFAQ(3)}
                    className="w-full flex justify-between items-center"
                    aria-controls="faq-3"
                    aria-expanded={openFAQ === 3}
                >
                    <span className="w-[calc(100%-30px)] text-start text-lg font-semibold text-dark">
                        Do I need to pay anything to find or compare the practitioner on Allied
                        Health.com.au?
                    </span>
                    <svg
                        className={`dropdown-arrow transition-transform duration-300 ${openFAQ === 3 ? "rotate-180" : ""
                            }`}
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M11.636 13.828L19.1308 6.33337L21.2721 8.47471L11.636 18.1108L2 8.47471L4.14134 6.33337L11.636 13.828Z"
                            fill="black"
                        ></path>
                    </svg>
                </button>
                <div
                    className={`mt-4 space-y-2 dropdownContentfilter transition-all duration-300 ${openFAQ === 3 ? "block" : "hidden"
                        }`}
                    id="faq-3"
                >
                    <p className="text-justify font-medium text-dark text-base">
                        No, this service is completed free for patients.
                    </p>
                </div>
            </div>

            <div className="dropdownfilter w-full bg-white lg:py-4 lg:px-3 py-6 px-4 rounded-lg mb-1 last:mb-0">
                <button
                    onClick={() => toggleFAQ(4)}
                    className="w-full flex justify-between items-center"
                    aria-controls="faq-4"
                    aria-expanded={openFAQ === 4}
                >
                    <span className="w-[calc(100%-30px)] text-start text-lg font-semibold text-dark">
                        What types of practitioners are available on Allied Health.com.au?
                    </span>
                    <svg
                        className={`dropdown-arrow transition-transform duration-300 ${openFAQ === 4 ? "rotate-180" : ""
                            }`}
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M11.636 13.828L19.1308 6.33337L21.2721 8.47471L11.636 18.1108L2 8.47471L4.14134 6.33337L11.636 13.828Z"
                            fill="black"
                        ></path>
                    </svg>
                </button>
                <div
                    className={`mt-4 space-y-2 dropdownContentfilter transition-all duration-300 ${openFAQ === 4 ? "block" : "hidden"
                        }`}
                    id="faq-4"
                >
                    <p className="text-justify font-medium text-dark text-base mb-3">
                        Our platform connects patients with a wide range of allied health
                        professionals, including:
                    </p>
                    <ul className="list-disc pl-7">
                        <li className="text-justify font-medium text-dark text-base">
                            Physiotherapists
                        </li>
                        <li className="text-justify font-medium text-dark text-base">
                            Occupational Therapists
                        </li>
                        <li className="text-justify font-medium text-dark text-base">
                            Speech pathologist
                        </li>
                        <li className="text-justify font-medium text-dark text-base">Dietitians</li>
                        <li className="text-justify font-medium text-dark text-base">
                            Psychologists
                        </li>
                        <li className="text-justify font-medium text-dark text-base">Podiatrists</li>
                        <li className="text-justify font-medium text-dark text-base">
                            Chiropractors
                        </li>
                        <li className="text-justify font-medium text-dark text-base">
                            Exercise Physiologists
                        </li>
                        <li className="text-justify font-medium text-dark text-base">And more!</li>
                    </ul>
                </div>
            </div>

            <div className="dropdownfilter w-full bg-white lg:py-4 lg:px-3 py-6 px-4 rounded-lg mb-1 last:mb-0">
                <button
                    onClick={() => toggleFAQ(5)}
                    className="w-full flex justify-between items-center"
                    aria-controls="faq-5"
                    aria-expanded={openFAQ === 5}
                >
                    <span className="w-[calc(100%-30px)] text-start text-lg font-semibold text-dark">
                        Can I choose a practitioner based on their gender & Languages?
                    </span>
                    <svg
                        className={`dropdown-arrow transition-transform duration-300 ${openFAQ === 5 ? "rotate-180" : ""
                            }`}
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M11.636 13.828L19.1308 6.33337L21.2721 8.47471L11.636 18.1108L2 8.47471L4.14134 6.33337L11.636 13.828Z"
                            fill="black"
                        ></path>
                    </svg>
                </button>
                <div
                    className={`mt-4 space-y-2 dropdownContentfilter transition-all duration-300 ${openFAQ === 5 ? "block" : "hidden"
                        }`}
                    id="faq-5"
                > <p className="text-justify font-medium text-dark text-base">
                        Yes, our platform allows you to filter practitioners by gender & language
                        to help ensure you feel comfortable during your treatment. You can select
                        your preferred gender & language option while searching for practitioners.
                    </p>
                </div>
            </div>

            <div className="dropdownfilter w-full bg-white lg:py-4 lg:px-3 py-6 px-4 rounded-lg mb-1 last:mb-0">
                <button
                    onClick={() => toggleFAQ(6)}
                    className="w-full flex justify-between items-center"
                    aria-controls="faq-6"
                    aria-expanded={openFAQ === 6}
                >
                    <span className="w-[calc(100%-30px)] text-start text-lg font-semibold text-dark">
                        Is my personal and medical information secure?
                    </span>
                    <svg
                        className={`dropdown-arrow transition-transform duration-300 ${openFAQ === 6 ? "rotate-180" : ""
                            }`}
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M11.636 13.828L19.1308 6.33337L21.2721 8.47471L11.636 18.1108L2 8.47471L4.14134 6.33337L11.636 13.828Z"
                            fill="black"
                        ></path>
                    </svg>
                </button>
                <div
                    className={`mt-4 space-y-2 dropdownContentfilter transition-all duration-300 ${openFAQ === 6 ? "block" : "hidden"
                        }`}
                    id="faq-6"
                > <p className="text-justify font-medium text-dark text-base">
                        Yes, your privacy and confidentiality are our top priority. We adhere to
                        strict privacy policies and ensure that all your personal and medical
                        information is encrypted and securely stored. We only share your
                        information with practitioners after getting your consent.
                    </p>
                </div>
            </div>

            <div className="dropdownfilter w-full bg-white lg:py-4 lg:px-3 py-6 px-4 rounded-lg mb-1 last:mb-0">
                <button
                    onClick={() => toggleFAQ(7)}
                    className="w-full flex justify-between items-center"
                    aria-controls="faq-7"
                    aria-expanded={openFAQ === 7}
                >
                    <span className="w-[calc(100%-30px)] text-start text-lg font-semibold text-dark">
                        How can I contact Allied Health.com.au if I need help?
                    </span>
                    <svg
                        className={`dropdown-arrow transition-transform duration-300 ${openFAQ === 7 ? "rotate-180" : ""
                            }`}
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M11.636 13.828L19.1308 6.33337L21.2721 8.47471L11.636 18.1108L2 8.47471L4.14134 6.33337L11.636 13.828Z"
                            fill="black"
                        ></path>
                    </svg>
                </button>
                <div
                    className={`mt-4 space-y-2 dropdownContentfilter transition-all duration-300 ${openFAQ === 7 ? "block" : "hidden"
                        }`}
                    id="faq-7"
                >
                    <p className="text-justify font-medium text-dark text-base">
                        If you need assistance, our customer support team is here to help. You can
                        reach us via the "Contact Us" page on our website, or by emailing
                        <a href="mailto:support@alliedhealth.com.au" className="text-blue"
                        > support@alliedhealth.com.au.{' '}
                        </a>
                        we’ll get back to you as soon as possible!
                    </p>
                </div>
            </div>
        </div>
    );
};

export default FAQ;
