import React, { createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { API_BASE_URL } from "../api/api";
import { jwtDecode } from "jwt-decode";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    const savedUser = localStorage.getItem("user");
    return savedUser ? JSON.parse(savedUser) : null;
  });

  const [token, setToken] = useState(localStorage.getItem("token") || null);
  const navigate = useNavigate();

  const login = async (credentials) => {
    try {
      const response = await axios.post(`${API_BASE_URL}login`, credentials);

      if (response.data.status) {
        const userData = response.data.data;

        localStorage.setItem("token", userData?.token);
        localStorage.setItem("user", JSON.stringify(userData?.user));

        setUser(userData?.user);
        setToken(userData?.token);

        if (credentials?.remember) {
          const sevenDaysLater = Date.now() + 7 * 24 * 60 * 60 * 1000;
          localStorage.setItem("lastCleared", sevenDaysLater.toString());
        } else {
          localStorage.setItem("lastCleared", Date.now().toString());
        }

        toast.success("Login successful!");
        // navigate("/masteradmin");
        if (userData?.user?.role?.roles_id === 1) {
          navigate("/masteradmin");
        } else {
          navigate("/admin");
        }

      } else {
        toast.error(response.data.message || "Login failed!");

        throw new Error(response.data.message || "Login failed!");
      }
    } catch (error) {
      // toast.error("An error occurred. Please try again later.");
      console.error("Login error:", error);

      throw error;
    }
  };

  const checkUserLogin = () => {
    const savedUser = localStorage.getItem("user");
    const savedToken = localStorage.getItem("token");
    const lastCleared = localStorage.getItem("lastCleared");

    if (!savedUser || !savedToken) {
      toast.warning("You need to log in first!");
      logout();
      return false;
    }

    // Check if session has exceeded 4 hours (4 * 60 * 60 * 1000 ms)
    const sessionLimit = 4 * 60 * 60 * 1000; // 4 hours in milliseconds
    if (lastCleared && Date.now() - parseInt(lastCleared, 10) > sessionLimit) {
      logout();
      localStorage.removeItem("lastCleared");
      return false;
    }

    try {
      const user = JSON.parse(savedUser);
      if (user.role.roles_id === 1) {
        toast.error("Access denied. Only Admins can access this page.");
        logout();
        return false;
      }
    } catch (error) {
      toast.error("Invalid user data. Please log in again.");
      logout();
      return false;
    }

    try {
      const decodedToken = jwtDecode(savedToken);
      if (decodedToken.exp * 1000 < Date.now()) {
        toast.warning("Session expired. Please log in again.");
        logout();
        return false;
      }
    } catch (error) {
      toast.error("Invalid token. Please log in again.");
      logout();
      return false;
    }

    return true;
  };

  const checkMasterAdminLogin = () => {
    const savedUser = localStorage.getItem("user");
    const savedToken = localStorage.getItem("token");

    if (!savedUser || !savedToken) {
      toast.warning("You need to log in first!");
      logout();
      return false;
    }

    try {
      const user = JSON.parse(savedUser);
      if (user.role.roles_id !== 1) {
        toast.error("Access denied. Only Master Admins can access this page.");
        logout();
        return false;
      }
    } catch (error) {
      toast.error("Invalid user data. Please log in again.");
      logout();
      return false;
    }

    return true;
  };

  const logout = () => {
    setUser(null);
    setToken(null);
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("lastCleared");
    toast.info("Logged out successfully!");
    navigate("/login");
  };

  useEffect(() => {
    const savedUser = localStorage.getItem("user");
    if (savedUser) {
      setUser(JSON.parse(savedUser));
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        token,
        setUser,
        setToken,
        login,
        logout,
        checkUserLogin,
        checkMasterAdminLogin,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
