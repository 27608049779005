import React, { useContext, useRef, useState } from "react";
import FrontendLayout from "../../layouts/FrontendLayout";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { API_BASE_URL } from "../../api/api";
import { AuthContext } from "../../context/AuthContext";
import axios from "axios";
import { CONSTANT_REDIRECT_TIME } from "../../constant/common";

const RegisterPage = () => {
    const [otp, setOtp] = useState(new Array(6).fill(""));

    const inputRefs = useRef([]);

    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword((prev) => !prev);
    };

    const [formIndex, setFormIndex] = useState(1);

    const [isLoading, setIsLoading] = useState(false);
    const { user, setUser, setToken } = useContext(AuthContext);
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        fk_roles_id: 2,
        practice_name: "",
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        password: "",
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            const response = await axios.post(`${API_BASE_URL}register`, formData);
            if (response.data.status) {
                const userData = response.data.data;

                localStorage.setItem("user", JSON.stringify(userData));

                setUser(userData);

                setFormIndex(2);
                toast.success("Registration successful! Please check the email for OTP");

            } else {
                toast.error(response.data.message || "Registration failed!");
            }
        } catch (error) {
            toast.error("An error occurred. Please try again.");
            console.error("Registration error:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleOTPChange = (index, e) => {
        const value = e.target.value;

        if (!/^\d*$/.test(value)) return;

        const newOtp = [...otp];
        newOtp[index] = value.substring(value.length - 1);
        setOtp(newOtp);

        if (value && index < otp.length - 1) {
            inputRefs.current[index + 1].focus();
        }

        if (newOtp.every((digit) => digit !== "")) {
        }
    };

    const handleOTPKeyDown = (index, e) => {
        if (e.key === "Backspace" && !otp[index] && index > 0) {
            inputRefs.current[index - 1].focus();
        }
    };

    const handleOTPSubmit = async (e) => {
        e.preventDefault();

        const otpValue = otp.join("");
        const payload = {
            fk_users_id: user?.users_id,
            value: otpValue
        };

        try {
            const response = await axios.post(`${API_BASE_URL}otp-validate`, payload);

            if (response.data.status) {
                const userData = response.data.data.user;
                const token = response.data.data.token;

                setUser(userData);
                setToken(token);
                localStorage.setItem("user", JSON.stringify(userData));
                localStorage.setItem("token", token);

                toast.success("OTP validated successfully!");

                setTimeout(() => {
                    navigate("/admin");
                }, CONSTANT_REDIRECT_TIME);
            } else {
                toast.error(response.data.message || "OTP validation failed.");
            }
        } catch (error) {
            toast.error("An error occurred while validating OTP.");
            console.error("OTP Validation Error:", error);
        }
    };


    return (
        <>
            <FrontendLayout>
                <section>
                    <div className="container mx-auto px-4 sm:px-3 py-6 flex flex-wrap sm:flex-nowrap justify-between items-center gap-2">
                        <div className="hidden lg:block">
                            <div className="px-6 py-2 bg-transparent text-transparent rounded-[8px] font-bold font-poppins text-base border-2 border-solid border-transparent  inline-block text-center">Pricing</div>
                        </div>
                        <ol className="flex items-center w-[180px] sm:w-[400px] login-stap">
                            <li className="flex w-full items-center after:content-[''] after:w-full after:h-1 after:bg-blue">
                                <div className="flex items-center justify-center  w-10 h-10 bg-blue text-white rounded-full lg:h-12 lg:w-12 shrink-0">
                                    1
                                </div>
                            </li>
                            <li className="flex items-center">
                                <div className={`flex items-center justify-center w-10 h-10 rounded-full lg:h-12 lg:w-12 shrink-0 ${formIndex === 1 ? 'bg-lightblue' : 'bg-blue text-white'}`}>
                                    {formIndex === 1 ? '2' :
                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.3329 20.2279L25.5895 7.97144L27.4751 9.85705L13.3329 23.9991L4.84766 15.5139L6.73328 13.6283L13.3329 20.2279Z" fill="white"></path>
                                        </svg>
                                    }
                                </div>
                            </li>
                        </ol>
                        <div className="">
                            {formIndex === 1 &&
                                <Link to="/pricing" className="px-6 py-2 bg-green text-white rounded-[8px] font-bold font-poppins text-base border-2 border-solid border-green hover:scale-[1.04] transition ease-in-out inline-block text-center">Pricing</Link>
                            }
                        </div>
                    </div>

                    {formIndex === 1 ?
                        <div className="container mx-auto px-3"><div className="rounded-[18px] bg-white px-5 sm:px-8 lg:px-28 py-8 shadow-card mx-auto mb-[60px]">
                            <h1 className="text-dark text-[28px] sm:text-[34px] leading-normal sm:leading-relaxed font-semibold">List Your Practice For Free!</h1>
                            <span className="text-dark text-xl mb-4 inline-block">Enter your details to create an account.</span>
                            <form onSubmit={handleSubmit}>
                                <div className="grid grid-cols-2 gap-y-4 gap-x-[20px] lg:gap-x-[30px]">
                                    <div className="col-span-2">
                                        <label htmlFor="practice_name" className="mb-2 inline-block text-dark font-semibold text-xl">Practice name</label>
                                        <input required value={formData.practice_name} onChange={handleChange} type="text" name="practice_name" id="practice_name" className="border-[1.2px] border-dark w-full h-12 sm:h-14 rounded-xl px-4 sm:px-7 focus:border-blue outline-none text-lg sm:text-xl" placeholder="Enter your Practice name" />
                                    </div>
                                    <div className="col-span-2 sm:col-span-1">
                                        <label htmlFor="firstname" className="mb-2 inline-block text-dark font-semibold text-xl">First name</label>
                                        <input required value={formData.firstname} onChange={handleChange} type="text" name="firstname" id="firstname" className="border-[1.2px] border-dark w-full h-12 sm:h-14 rounded-xl px-4 sm:px-7 focus:border-blue outline-none text-lg sm:text-xl" placeholder="Enter first name" />
                                    </div>
                                    <div className="col-span-2 sm:col-span-1">
                                        <label htmlFor="lastname" className="mb-2 inline-block text-dark font-semibold text-xl">Last name</label>
                                        <input required value={formData.lastname} onChange={handleChange} type="text" name="lastname" id="lastname" className="border-[1.2px] border-dark w-full h-12 sm:h-14 rounded-xl px-4 sm:px-7 focus:border-blue outline-none text-lg sm:text-xl" placeholder="Enter last name" />
                                    </div>
                                    <div className="col-span-2 sm:col-span-1">
                                        <label htmlFor="email" className="mb-2 inline-block text-dark font-semibold text-xl">Email</label>
                                        <input required value={formData.email} onChange={handleChange} type="email" name="email" id="email" className="border-[1.2px] border-dark w-full h-12 sm:h-14 rounded-xl px-4 sm:px-7 focus:border-blue outline-none text-lg sm:text-xl" placeholder="Enter your email address" />
                                    </div>
                                    <div className="col-span-2 sm:col-span-1">
                                        <label htmlFor="Password" className="mb-2 inline-block text-dark font-semibold text-xl">Password</label>
                                        <div className="relative">
                                            <input required value={formData.password} onChange={handleChange} type={showPassword ? "text" : "password"}
                                                name="password" id="Password" className="password-input border-[1.2px] border-dark w-full h-12 sm:h-14 rounded-xl px-4 sm:px-7 focus:border-blue outline-none text-lg sm:text-xl" placeholder="Enter password" />
                                            <div
                                                onClick={togglePasswordVisibility}
                                                className="password-toggle absolute top-1/2 right-5 -translate-y-2/4 cursor-pointer">
                                                {showPassword ? <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.0003 3C17.3924 3 21.8784 6.87976 22.8189 12C21.8784 17.1202 17.3924 21 12.0003 21C6.60812 21 2.12215 17.1202 1.18164 12C2.12215 6.87976 6.60812 3 12.0003 3ZM12.0003 19C16.2359 19 19.8603 16.052 20.7777 12C19.8603 7.94803 16.2359 5 12.0003 5C7.7646 5 4.14022 7.94803 3.22278 12C4.14022 16.052 7.7646 19 12.0003 19ZM12.0003 16.5C9.51498 16.5 7.50026 14.4853 7.50026 12C7.50026 9.51472 9.51498 7.5 12.0003 7.5C14.4855 7.5 16.5003 9.51472 16.5003 12C16.5003 14.4853 14.4855 16.5 12.0003 16.5ZM12.0003 14.5C13.381 14.5 14.5003 13.3807 14.5003 12C14.5003 10.6193 13.381 9.5 12.0003 9.5C10.6196 9.5 9.50026 10.6193 9.50026 12C9.50026 13.3807 10.6196 14.5 12.0003 14.5Z" fill="black"></path></svg> : <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M17.8827 19.2968C16.1814 20.3755 14.1638 21.0002 12.0003 21.0002C6.60812 21.0002 2.12215 17.1204 1.18164 12.0002C1.61832 9.62283 2.81932 7.5129 4.52047 5.93457L1.39366 2.80777L2.80788 1.39355L22.6069 21.1925L21.1927 22.6068L17.8827 19.2968ZM5.9356 7.3497C4.60673 8.56015 3.6378 10.1672 3.22278 12.0002C4.14022 16.0521 7.7646 19.0002 12.0003 19.0002C13.5997 19.0002 15.112 18.5798 16.4243 17.8384L14.396 15.8101C13.7023 16.2472 12.8808 16.5002 12.0003 16.5002C9.51498 16.5002 7.50026 14.4854 7.50026 12.0002C7.50026 11.1196 7.75317 10.2981 8.19031 9.60442L5.9356 7.3497ZM12.9139 14.328L9.67246 11.0866C9.5613 11.3696 9.50026 11.6777 9.50026 12.0002C9.50026 13.3809 10.6196 14.5002 12.0003 14.5002C12.3227 14.5002 12.6309 14.4391 12.9139 14.328ZM20.8068 16.5925L19.376 15.1617C20.0319 14.2268 20.5154 13.1586 20.7777 12.0002C19.8603 7.94818 16.2359 5.00016 12.0003 5.00016C11.1544 5.00016 10.3329 5.11773 9.55249 5.33818L7.97446 3.76015C9.22127 3.26959 10.5793 3.00016 12.0003 3.00016C17.3924 3.00016 21.8784 6.87993 22.8189 12.0002C22.5067 13.6998 21.8038 15.2628 20.8068 16.5925ZM11.7229 7.50857C11.8146 7.50299 11.9071 7.50016 12.0003 7.50016C14.4855 7.50016 16.5003 9.51488 16.5003 12.0002C16.5003 12.0933 16.4974 12.1858 16.4919 12.2775L11.7229 7.50857Z" fill="#393939"></path>';
                                                </svg>}

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-span-2">
                                        <div className="flex items-start sm:items-center my-2">
                                            <input required id="default-checkbox" type="checkbox" value="" className="w-4 sm:w-6 h-5 sm:h-6 text-blue-600 bg-gray-100 border-gray-300 rounded  dark:bg-gray-700 dark:border-gray-600" />
                                            <label htmlFor="default-checkbox" className="ms-2 text-base sm:text-xl font-medium text-dark w-[calc(100%-20px)]">I accept the <Link to="/terms-conditions" className="text-blue font-semibold">Terms &amp; Conditions</Link> and <Link to="/privacy-policy" className="text-blue font-semibold">Privacy Policy</Link></label>
                                        </div>
                                    </div>
                                    <div className="col-span-2 md:col-span-1">
                                        {/* <Link to="/otp-screen" type="submit" className="h-auto block py-3 px-3 sm:h-14 bg-blue w-full text-center text-white font-semibold text-lg sm:text-2xl rounded-xl  mb-5 sm:mb-[30px]">Next</Link> */}
                                        <button type="submit" className="h-auto block py-3 px-3 sm:h-14 bg-blue w-full text-center text-white font-semibold text-lg sm:text-2xl rounded-xl  mb-5 sm:mb-[30px]">Next</button>
                                        <p className="text-center text-dark text-xl font-medium">Already have an account? <Link to="/login" className="text-blue font-semibold">Login</Link></p>
                                    </div>
                                </div>
                            </form>
                        </div>
                        </div>
                        : <div className="container mx-auto px-3 pb-[60px] md:pb-[100px] flex justify-center items-center flex-col">
                            <div className="max-w-[850px] w-full rounded-[18px] bg-white sm:px-14 px-5 py-10 shadow-card">
                                <p className="text-dark text-xl text-center mb-6">Please confirm your email by entering the activation code that has been sent to your email address <a href="#" className="font-semibold text-blue">{user?.email}.</a></p>
                                <p className="text-dark text-xl text-center mb-6 md:mb-6">If you do not receive this email within a few minutes,
                                    kindly check your spam or junk folder.</p>
                                <h1 className="text-dark text-center text-[24px] sm:text-[30px] leading-normal font-semibold mb-5 ">Kindly enter the email verification code</h1>
                                <form id="otp-form" onSubmit={handleOTPSubmit}>
                                    <div className="flex items-center justify-center gap-2 sm:gap-3">
                                        {otp.map((value, index) => (
                                            <input
                                                required
                                                key={index}
                                                value={value}
                                                ref={(el) => (inputRefs.current[index] = el)}
                                                onChange={(e) => handleOTPChange(index, e)}
                                                onKeyDown={(e) => handleOTPKeyDown(index, e)}
                                                type="text"
                                                className="w-[40px] h-[40px] sm:w-[56px] sm:h-[56px] text-center text-dark border-[1.2px] border-dark rounded p-2 sm:p-4 outline-none text-lg"
                                                maxLength="1"
                                                onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')}
                                            />

                                        ))}

                                    </div>
                                    <div className="max-w-[520px] w-full mx-auto mt-10 ">
                                        <button type="submit" className="h-auto py-3 px-3 sm:h-14 md:h-[66px] bg-blue w-full text-center text-white font-semibold text-lg sm:text-2xl rounded-xl mb-5 sm:mb-[30px]">Confirm</button>
                                    </div>
                                </form>


                            </div>
                        </div>}

                </section>
            </FrontendLayout >
        </>)
}

export default RegisterPage