import React, { useEffect, useRef, useState } from "react";
import FrontendLayout from "../../layouts/FrontendLayout";
import { Link } from "react-router-dom";
import api, { API_BASE_URL, fetchData } from "../../api/api";
import { toast } from "react-toastify";
import Spinner from "../../components/common/Spinner";

const ForgotPasswordPage = () => {
    const [isLoading, setIsLoading] = useState(false);

    const [email, setEmail] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        setIsLoading(true);

        const payload = {
            email: email,
            url: window.location.origin + process.env.REACT_APP_SUB_FOLDER || '/',
        };

        try {
            const response = await api.post("forgot-password-users", payload);

            if (response?.data?.status === true) {
                toast.success(response.data?.message);

                setEmail("");

            } else {
                toast.error(response?.data?.message || "Failed ");
                return null;
            }
        } catch (error) {
            console.error("Error :", error);
            toast.error("Something went wrong. Please try again.");
            return null;
        } finally {
            setIsLoading(false);
        }
    };    
    

    return (
        <>
            <FrontendLayout>
                <section>
                    <div className="container mx-auto px-3 pt-[40px] pb-[40px] md:pt-[50px] md:pb-[50px] flex justify-center items-center flex-col">
                        <form onSubmit={handleSubmit} className="max-w-[630px] w-full rounded-[18px] bg-white sm:px-14 px-5 py-8 shadow-card">
                            <h1 className="text-dark text-[28px] sm:text-[34px] leading-relaxed font-semibold">Forgot Password!</h1>
                            <span className="text-dark text-xl mb-4 inline-block">Enter your registers email and reset your password</span>
                            <div className="mb-4 sm:mb-5">
                                <label htmlFor="email" className="mb-2 inline-block text-dark font-semibold text-xl">Email</label>
                                <input
                                required
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    type="email" name="email" id="email" className="border-[1.2px] border-dark w-full h-12 sm:h-14 rounded-xl px-4 sm:px-7 focus:border-blue outline-none text-lg sm:text-xl" placeholder="Enter your email address" />
                            </div>
                            <button type="submit" className="h-auto py-3 px-3 sm:h-14 bg-blue w-full text-center text-white font-semibold text-lg sm:text-2xl rounded-xl mt-6 mb-4 sm:mb-4 block">Forgot Password</button>
                        </form>
                    </div>
                </section>
            </FrontendLayout>
        </>)
}

export default ForgotPasswordPage