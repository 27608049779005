import React, { useEffect, useRef, useState } from "react";
import FrontendLayout from "../../layouts/FrontendLayout";
import { Link } from "react-router-dom";
import { fetchData } from "../../api/api";
import Spinner from "../../components/common/Spinner";

const PricingPage = () => {
    const didFetch = useRef(false);

    const [isLoading, setIsLoading] = useState(false);

    const [pricingPlanList, setPricingPlanList] = useState([]);
    const [pricingPlanFeaturesList, setPricingPlanFeaturesList] = useState([]);

    const getData = async () => {
        setIsLoading(true);
        try {
            const data = await fetchData('/get-pricing-plan-features', { status: 1 });
            setPricingPlanList(data.pricing_plan);
            setPricingPlanFeaturesList(data.pricing_plan_features);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (!didFetch.current) {
            getData();
            didFetch.current = true;
        }
    }, []);

    if (isLoading) {
        return (<Spinner />);
    }

    return (
        <>
            <FrontendLayout>


                <section className="pt-6 sm:pt-8 md:pt-12  pb-[60px] md:pb-[100px]">
                    <div className="container mx-auto px-4 sm:px-3">
                        <div className="bg-white py-[24px] lg:py-[30px] rounded-[18px] shadow-card">
                            <h1 className="text-center text-[#222] text-[24px] sm:text-[34px] leading-relaxed font-semibold mb-[24px] lg:mb-[30px]">Pricing</h1>
                            <div className="overflow-x-auto">
                                {pricingPlanFeaturesList?.length > 0 &&
                                    <table className="min-w-full border-collapse">
                                        <thead>
                                            <tr className="bg-dark">
                                                <th className="px-4 py-2 text-left font-semibold text-xl text-white w-1/4 min-w-48">Features</th>
                                                {pricingPlanList?.length > 0 && pricingPlanList?.map((pricingplan, index) => (
                                                    <th className="px-4 py-2 text-center font-semibold text-xl text-white w-1/4 min-w-40">{pricingplan?.name}</th>
                                                ))}
                                            </tr>
                                            <tr className="">
                                                <th className="px-4 py-6"></th>
                                                {pricingPlanList?.length > 0 && pricingPlanList?.map((pricingplan, index) => (
                                                    <th className="px-4 py-6" key={index}>
                                                        <Link to="/register" className="w-full h-12 items-center justify-center bg-transparent text-blue rounded-[8px] font-bold border-2 border-solid border-blue hover:scale-[1.04] flex transition ease-in-out max-w-44 mx-auto hover:text-white hover:bg-blue">
                                                            ${pricingplan?.price}/{pricingplan?.duration}
                                                        </Link>
                                                    </th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {pricingPlanFeaturesList?.length > 0 && pricingPlanFeaturesList?.map((pricingplanFeatures, index) => (
                                                <tr key={index}
                                                    className={index % 2 === 0 ? 'bg-[#f5f5f5]' : 'bg-white'}
                                                >
                                                    <td className="px-4 py-3.5 text-dark font-semibold text-base text-left">
                                                        {pricingplanFeatures['feature_name']}
                                                    </td>
                                                    <td className="px-4 py-3.5 text-green font-semibold text-base text-center">
                                                        {pricingplanFeatures['free_listing'] != 0 ? (

                                                            pricingplanFeatures['feature_type'] == 'radio' ? (<>
                                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="mx-auto">
                                                                    <path d="M10 0C4.5 0 0 4.5 0 10C0 15.5 4.5 20 10 20C15.5 20 20 15.5 20 10C20 4.5 15.5 0 10 0ZM14.3 7.6L9.7 13.6C9.5 13.8 9.2 14 8.9 14C8.6 14 8.3 13.9 8.1 13.6L5.7 10.5C5.4 10.1 5.4 9.4 5.9 9.1C6.4 8.8 7 8.8 7.3 9.3L8.9 11.4L12.7 6.4C13 6 13.7 5.9 14.1 6.2C14.6 6.5 14.6 7.1 14.3 7.6Z" fill="#00A167" />
                                                                </svg>
                                                            </>) : pricingplanFeatures['free_listing']

                                                        ) : (
                                                            <>
                                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="mx-auto">
                                                                    <path d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z" fill="#FF6174" />
                                                                    <path d="M7.20878 13.4886C7.07088 13.4885 6.93611 13.4475 6.82149 13.3709C6.70688 13.2942 6.61755 13.1853 6.56479 13.0579C6.51204 12.9305 6.49822 12.7903 6.52509 12.655C6.55196 12.5198 6.61831 12.3955 6.71576 12.2979L12.2972 6.71655C12.4294 6.59331 12.6043 6.52622 12.7851 6.52941C12.9658 6.53259 13.1383 6.60582 13.2661 6.73364C13.3939 6.86147 13.4672 7.03392 13.4703 7.21466C13.4735 7.39541 13.4064 7.57034 13.2832 7.70259L7.7018 13.284C7.63726 13.349 7.56045 13.4006 7.47582 13.4358C7.39118 13.4709 7.30042 13.4889 7.20878 13.4886Z" fill="white" />
                                                                    <path d="M12.789 13.4886C12.6974 13.4889 12.6066 13.4709 12.522 13.4358C12.4373 13.4006 12.3605 13.349 12.296 13.284L6.71459 7.70259C6.59136 7.57034 6.52426 7.39541 6.52745 7.21466C6.53064 7.03392 6.60386 6.86147 6.73169 6.73364C6.85951 6.60582 7.03197 6.53259 7.21271 6.52941C7.39346 6.52622 7.56838 6.59331 7.70064 6.71655L13.282 12.2979C13.3795 12.3955 13.4458 12.5198 13.4727 12.655C13.4996 12.7903 13.4858 12.9305 13.433 13.0579C13.3802 13.1853 13.2909 13.2942 13.1763 13.3709C13.0617 13.4475 12.9269 13.4885 12.789 13.4886Z" fill="white" />
                                                                </svg>
                                                            </>
                                                        )}

                                                    </td>
                                                    <td className="px-4 py-3.5 text-green font-semibold text-base text-center">
                                                        {pricingplanFeatures['basic_listing'] != 0 ? (

                                                            pricingplanFeatures['feature_type'] == 'radio' ? (<>
                                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="mx-auto">
                                                                    <path d="M10 0C4.5 0 0 4.5 0 10C0 15.5 4.5 20 10 20C15.5 20 20 15.5 20 10C20 4.5 15.5 0 10 0ZM14.3 7.6L9.7 13.6C9.5 13.8 9.2 14 8.9 14C8.6 14 8.3 13.9 8.1 13.6L5.7 10.5C5.4 10.1 5.4 9.4 5.9 9.1C6.4 8.8 7 8.8 7.3 9.3L8.9 11.4L12.7 6.4C13 6 13.7 5.9 14.1 6.2C14.6 6.5 14.6 7.1 14.3 7.6Z" fill="#00A167" />
                                                                </svg>
                                                            </>) : pricingplanFeatures['basic_listing']

                                                        ) : (
                                                            <>
                                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="mx-auto">
                                                                    <path d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z" fill="#FF6174" />
                                                                    <path d="M7.20878 13.4886C7.07088 13.4885 6.93611 13.4475 6.82149 13.3709C6.70688 13.2942 6.61755 13.1853 6.56479 13.0579C6.51204 12.9305 6.49822 12.7903 6.52509 12.655C6.55196 12.5198 6.61831 12.3955 6.71576 12.2979L12.2972 6.71655C12.4294 6.59331 12.6043 6.52622 12.7851 6.52941C12.9658 6.53259 13.1383 6.60582 13.2661 6.73364C13.3939 6.86147 13.4672 7.03392 13.4703 7.21466C13.4735 7.39541 13.4064 7.57034 13.2832 7.70259L7.7018 13.284C7.63726 13.349 7.56045 13.4006 7.47582 13.4358C7.39118 13.4709 7.30042 13.4889 7.20878 13.4886Z" fill="white" />
                                                                    <path d="M12.789 13.4886C12.6974 13.4889 12.6066 13.4709 12.522 13.4358C12.4373 13.4006 12.3605 13.349 12.296 13.284L6.71459 7.70259C6.59136 7.57034 6.52426 7.39541 6.52745 7.21466C6.53064 7.03392 6.60386 6.86147 6.73169 6.73364C6.85951 6.60582 7.03197 6.53259 7.21271 6.52941C7.39346 6.52622 7.56838 6.59331 7.70064 6.71655L13.282 12.2979C13.3795 12.3955 13.4458 12.5198 13.4727 12.655C13.4996 12.7903 13.4858 12.9305 13.433 13.0579C13.3802 13.1853 13.2909 13.2942 13.1763 13.3709C13.0617 13.4475 12.9269 13.4885 12.789 13.4886Z" fill="white" />
                                                                </svg>
                                                            </>
                                                        )}

                                                    </td>
                                                    <td className="px-4 py-3.5 text-green font-semibold text-base text-center">
                                                        {pricingplanFeatures['premium_listing'] != 0 ? (

                                                            pricingplanFeatures['feature_type'] == 'radio' ? (<>
                                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="mx-auto">
                                                                    <path d="M10 0C4.5 0 0 4.5 0 10C0 15.5 4.5 20 10 20C15.5 20 20 15.5 20 10C20 4.5 15.5 0 10 0ZM14.3 7.6L9.7 13.6C9.5 13.8 9.2 14 8.9 14C8.6 14 8.3 13.9 8.1 13.6L5.7 10.5C5.4 10.1 5.4 9.4 5.9 9.1C6.4 8.8 7 8.8 7.3 9.3L8.9 11.4L12.7 6.4C13 6 13.7 5.9 14.1 6.2C14.6 6.5 14.6 7.1 14.3 7.6Z" fill="#00A167" />
                                                                </svg>
                                                            </>) : pricingplanFeatures['premium_listing']

                                                        ) : (
                                                            <>
                                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="mx-auto">
                                                                    <path d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z" fill="#FF6174" />
                                                                    <path d="M7.20878 13.4886C7.07088 13.4885 6.93611 13.4475 6.82149 13.3709C6.70688 13.2942 6.61755 13.1853 6.56479 13.0579C6.51204 12.9305 6.49822 12.7903 6.52509 12.655C6.55196 12.5198 6.61831 12.3955 6.71576 12.2979L12.2972 6.71655C12.4294 6.59331 12.6043 6.52622 12.7851 6.52941C12.9658 6.53259 13.1383 6.60582 13.2661 6.73364C13.3939 6.86147 13.4672 7.03392 13.4703 7.21466C13.4735 7.39541 13.4064 7.57034 13.2832 7.70259L7.7018 13.284C7.63726 13.349 7.56045 13.4006 7.47582 13.4358C7.39118 13.4709 7.30042 13.4889 7.20878 13.4886Z" fill="white" />
                                                                    <path d="M12.789 13.4886C12.6974 13.4889 12.6066 13.4709 12.522 13.4358C12.4373 13.4006 12.3605 13.349 12.296 13.284L6.71459 7.70259C6.59136 7.57034 6.52426 7.39541 6.52745 7.21466C6.53064 7.03392 6.60386 6.86147 6.73169 6.73364C6.85951 6.60582 7.03197 6.53259 7.21271 6.52941C7.39346 6.52622 7.56838 6.59331 7.70064 6.71655L13.282 12.2979C13.3795 12.3955 13.4458 12.5198 13.4727 12.655C13.4996 12.7903 13.4858 12.9305 13.433 13.0579C13.3802 13.1853 13.2909 13.2942 13.1763 13.3709C13.0617 13.4475 12.9269 13.4885 12.789 13.4886Z" fill="white" />
                                                                </svg>
                                                            </>
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}

                                        </tbody>
                                    </table>
                                }
                            </div>
                        </div>

                    </div>
                </section>
            </FrontendLayout>
        </>)
}

export default PricingPage