import React, { useContext, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";
import api from "../../../api/api";
import { toast } from "react-toastify";

const UserProfile = ({ }) => {

    const { user, setUser } = useContext(AuthContext);

    const [formData, setFormData] = useState({
        fk_users_id: user?.users_id,
        firstname: user?.firstname,
        lastname: user?.lastname,
        email: user?.email,
        phone: user?.phone,
        practice_name: user?.practice_name,
        profile_image: user?.profile_image
    });

    const [selectedImage, setSelectedImage] = useState(null);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleImageChange = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];

            setSelectedImage(URL.createObjectURL(file));

            setFormData((prevState) => ({
                ...prevState,
                profile_image: file,
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const payload = new FormData();
            payload.append("fk_users_id", formData.fk_users_id);
            payload.append("firstname", formData.firstname);
            payload.append("lastname", formData.lastname);
            payload.append("email", formData.email);
            payload.append("phone", formData.phone);
            payload.append("practice_name", formData.practice_name);
            if (formData.profile_image instanceof File) {
                payload.append("profile_image", formData.profile_image);
            }

            const response = await api.post("/update-users", payload, {
                headers: { "Content-Type": "multipart/form-data" },
            });

            if (response?.data?.status === true) {
                toast.success(response?.data?.message)
                setUser(response?.data?.data)
                setSelectedImage(null)
            } else {
                toast.error(response?.data?.message)
            }
        } catch (error) {
            toast.error("An error occurred. Please try again.");
        } finally {
        }

    };

    return (
        <form onSubmit={handleSubmit} className="grid grid-cols-2 gap-y-4 gap-x-[20px] lg:gap-x-[30px]">
            <div className="col-span-2">
                <label htmlFor="imageinput" className="mb-2 inline-block text-dark font-semibold text-base sm:text-xl">Profile Photo</label>
                <label htmlFor="imageinput" className="flex flex-col items-center justify-center w-36 h-36 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-gray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500">
                    {selectedImage ? (
                        <img src={selectedImage} alt="Profile Preview" className="w-full h-full object-cover rounded-lg" />
                    ) : (
                        <span className="flex flex-col items-center justify-center pt-5 pb-6">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M12 12.5858L16.2426 16.8284L14.8284 18.2426L13 16.415V22H11V16.413L9.17157 18.2426L7.75736 16.8284L12 12.5858ZM12 2C15.5934 2 18.5544 4.70761 18.9541 8.19395C21.2858 8.83154 23 10.9656 23 13.5C23 16.3688 20.8036 18.7246 18.0006 18.9776L18.0009 16.9644C19.6966 16.7214 21 15.2629 21 13.5C21 11.567 19.433 10 17.5 10C17.2912 10 17.0867 10.0183 16.8887 10.054C16.9616 9.7142 17 9.36158 17 9C17 6.23858 14.7614 4 12 4C9.23858 4 7 6.23858 7 9C7 9.36158 7.03838 9.7142 7.11205 10.0533C6.91331 10.0183 6.70879 10 6.5 10C4.567 10 3 11.567 3 13.5C3 15.2003 4.21241 16.6174 5.81986 16.934L6.00005 16.9646L6.00039 18.9776C3.19696 18.7252 1 16.3692 1 13.5C1 10.9656 2.71424 8.83154 5.04648 8.19411C5.44561 4.70761 8.40661 2 12 2Z" fill="#00A167" />
                            </svg>
                            <span className="mb-2 text-sm font-semibold text-green">Browse Image</span>
                        </span>
                    )}
                    <input id="imageinput" type="file" className="hidden" onChange={handleImageChange} />
                </label>
            </div>
            <div className="col-span-2">
                <label htmlFor="practice_name" className="mb-2 inline-block text-dark font-semibold text-base sm:text-xl">Practice name</label>
                <input type="text"
                    value={formData.practice_name}
                    onChange={handleChange}
                    required
                    name="practice_name" id="practice_name" className="border-[1.2px] border-dark w-full h-12 sm:h-14 rounded-xl px-4 sm:px-7 focus:border-blue outline-none text-lg sm:text-xl" placeholder="Enter your practice name" />
            </div>
            <div className="col-span-2 sm:col-span-1">
                <label htmlFor="firstname" className="mb-2 inline-block text-dark font-semibold text-base sm:text-xl">First name</label>
                <input type="text"
                    value={formData.firstname}
                    onChange={handleChange}
                    required
                    name="firstname" id="firstname" className="border-[1.2px] border-dark w-full h-12 sm:h-14 rounded-xl px-4 sm:px-7 focus:border-blue outline-none text-lg sm:text-xl" placeholder="Enter first name" />
            </div>
            <div className="col-span-2 sm:col-span-1">
                <label htmlFor="lastname" className="mb-2 inline-block text-dark font-semibold text-base sm:text-xl">Last name</label>
                <input type="text"
                    value={formData.lastname}
                    onChange={handleChange}
                    required
                    name="lastname" id="lastname" className="border-[1.2px] border-dark w-full h-12 sm:h-14 rounded-xl px-4 sm:px-7 focus:border-blue outline-none text-lg sm:text-xl" placeholder="Enter last name" />
            </div>
            <div className="col-span-2 sm:col-span-1">
                <label htmlFor="email" className="mb-2 inline-block text-dark font-semibold text-base sm:text-xl">Email</label>
                <input type="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                     name="email" id="email" className="border-[1.2px] border-dark w-full h-12 sm:h-14 rounded-xl px-4 sm:px-7 focus:border-blue outline-none text-lg sm:text-xl" placeholder="Enter your email address" />
            </div>
            <div className="col-span-2 sm:col-span-1">
                <label htmlFor="phonenumber" className="mb-2 inline-block text-dark font-semibold text-base sm:text-xl">Phone number</label>
                <input type="tel" 
                    value={formData.phone}
                    onChange={handleChange}
                    required
                    maxLength={10}
                    name="phone" id="phone" className="border-[1.2px] border-dark w-full h-12 sm:h-14 rounded-xl px-4 sm:px-7 focus:border-blue outline-none text-lg sm:text-xl" placeholder="Enter phone number" />
            </div>
            <div className="col-span-2">
                <button type="submit" className="border-none w-full max-w-[512px] h-12 sm:h-14 rounded-xl p-4  bg-blue outline-none text-lg sm:text-xl text-white mt-8 flex items-center justify-center font-semibold" >Save & Submit Details</button>
            </div>
        </form>
    )
};

export default UserProfile;
